import { defineMessages } from "react-intl";

export default defineMessages({
    geometricalPanel: {
        id: "components__editor__geometrical-objects__panel",
        defaultMessage: "Geometrical objects",
    },
    dataPanel: {
        id: "components__editor__data-objects__panel",
        defaultMessage: "Data collectors",
    },
    localizationPanel: {
        id: "components__editor__localization-objects__panel",
        defaultMessage: "Localization points",
    },
    assemblyPanel: {
        id: "components__editor__assembly-objects__panel",
        defaultMessage: "Assemblies",
    },
});
