import { ActionTypes } from "./action";
import { AnyAction } from "redux";
import { ProjectFileVersion, SensorModelStock } from "../../graphql/generated/graphql";

export interface IModelModuleState {
    modelState: IModelState;
}

export interface IModelState {
    sensorToAdd?: SensorModelStock;
    geometryToAdd?: ProjectFileVersion;
    usedItemsInAssembly?: SensorModelStock[];
}

export const initialState: Readonly<IModelState> = {
    // sensorToAdd: null,
};

export function reducer(state: IModelState = initialState, action: AnyAction): IModelState {
    switch (action.type) {
        case ActionTypes.OnSelectSensorToAdd: {
            const { sensor } = action.payload;
            return {
                ...state,
                sensorToAdd: sensor,
            };
        }
        case ActionTypes.OnSelectGeometryToAdd: {
            const { geometry } = action.payload;
            return {
                ...state,
                geometryToAdd: geometry,
            };
        }
        case ActionTypes.OnAssemblyItemsUsed: {
            const { sensors } = action.payload;
            return {
                ...state,
                usedItemsInAssembly: sensors,
            };
        }

        default:
            return state;
    }
}
