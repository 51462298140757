import { Box, createStyles, Theme } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import { ApolloError } from "apollo-client";
import React from "react";
import GeometricalCore from "./GeometricalCore";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            paddingBottom: theme.spacing(1),
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: theme.spacing(1.5),
            [theme.breakpoints.up("sm")]: {
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
            },
            overflowY: "auto",
        },
        skeletonBox: {
            display: "flex",
            justifyContent: "center",
        },
        skeletonItem: {
            borderRadius: theme.shape.borderRadius,
        },
    })
);

interface IGeometricalListProps {
    data?: any | undefined;
    loading?: boolean;
    error?: ApolloError | undefined;
}

const GeometricalList: React.FC<IGeometricalListProps> = (props: IGeometricalListProps) => {
    const { data, loading, error } = props;
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            {(!loading || !error) &&
                data &&
                data?.projectFiles?.map((projectFile, index) => (
                    <GeometricalCore key={index} projectFile={projectFile} />
                ))}

            {(loading || error) &&
                [1, 2].map(item => (
                    <Box className={classes.skeletonBox} key={item}>
                        <Skeleton variant="rect" width={102} height={102} className={classes.skeletonItem} />
                    </Box>
                ))}
        </Box>
    );
};

export default GeometricalList;
