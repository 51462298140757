import {
    ProjectFileVersionSensor,
    SensorGraph,
    SensorModel,
    SensorModelStock,
    SensorType,
} from "../../graphql/generated/graphql";

export const getProjectFileVersionSensorName = (projectFileVersionSensor: ProjectFileVersionSensor) => {
    return getSensorModelStockName(projectFileVersionSensor?.sensorModelStocks!);
};

export const getSensorModelStockName = (sensorModelStock: SensorModelStock) =>
    sensorModelStock?.name ?? getSensorModelName(sensorModelStock?.sensorModel!);

export const getSensorModelName = (sensorModel: SensorModel) => sensorModel?.name;

export const getFormattedSensorValue = (sensorGraph: SensorGraph, sensorModel: SensorModel) => {
    if (sensorModel.isLocalizationSensor) {
        return `[${sensorGraph.doubleValue1}, ${sensorGraph.doubleValue2}, ${sensorGraph.doubleValue3}]`;
    } else {
        const valDouble: number = sensorGraph?.doubleValue1 as number;
        const val: string = (Math.round(valDouble * 100) / 100).toString();
        // todo display format, localization
        return `${val}${sensorModel.sensorType?.measureUnits}`;
    }
};
