import { Box, createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import AssemblyCore from "./AssemblyCore";
import { ApolloError } from "apollo-client";
import GeometricalCore from "../Geometrical/GeometricalCore";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            marginTop: theme.spacing(1.5),
            display: "grid",
            gridTemplateColumns: "1fr",
            gridGap: theme.spacing(1.5),
            [theme.breakpoints.up("sm")]: {
                gridTemplateColumns: "1fr 1fr",
            },
            overflowY: "auto",
        },
        skeletonBox: {
            display: "flex",
            justifyContent: "center",
        },
        skeletonItem: {
            borderRadius: theme.shape.borderRadius,
        },
    })
);

interface IAssemblyListProps {
    data: any | undefined;
    loading: boolean;
    error: ApolloError | undefined;
}

const AssemblyList: React.FC<IAssemblyListProps> = (props: IAssemblyListProps) => {
    const { data, loading, error } = props;
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            {(!loading || !error) &&
                data &&
                data?.sensorModelStocks?.map((sensorModelStock, index) => (
                    <AssemblyCore index={index} key={index} sensorModelStock={sensorModelStock} />
                ))}
            {(loading || error) &&
                [1, 2].map(item => (
                    <Box className={classes.skeletonBox} key={item}>
                        <Skeleton variant="rect" width={102} height={102} className={classes.skeletonItem} />
                    </Box>
                ))}
        </Box>
    );
};

export default AssemblyList;
