import { DispatchAction } from "@iolabs/redux-utils";
import { Box, createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { isEqual } from "lodash";
import {
    CreateProjectFileVersionSensorMutationVariables,
    ProjectFileVersionSensor,
    SensorGraph,
    SensorGraphColor,
    SensorModelStock,
    UpdateProjectFileVersionSensorMutationVariables,
    useCreateProjectFileVersionSensorMutation,
    useDeleteProjectFileVersionSensorMutation,
    useGetLastGraphsLazyQuery,
    useGetProjectFileVersionSensorsQuery,
    useGetSensorGraphDefaultColorQuery,
    useGetSensorGraphsLazyQuery,
    useUpdateProjectFileVersionSensorMutation,
} from "../../graphql/generated/graphql";
import { onSelectSensorToAdd, useSensorToAdd } from "../../redux/model";
import PushpinWrapper from "../Pushpin/PushpinWrapper";
import { ApolloError, OperationVariables } from "apollo-client";
import { loadProjectFileVersion, transformModel } from "./utils/assembly";
import { useKeycloak } from "react-keycloak";
import { DocumentNode } from "graphql";
import { useApolloClient } from "react-apollo-hooks";
import { addNotification, INotification } from "@iolabs/notifier";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sensorBox: {
            zIndex: 100,
            position: "absolute",
            overflow: "visible",
            transform: "translate(-50%, -50%)",
            lineHeight: "0",
        },
        root: {
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: 10,
            pointerEvents: "none",
            "&.is-dragging": {
                pointerEvents: "all",
            },
        },
        draggable: {
            cursor: "move",
        },
    })
);

export interface ISensorBoxInfo {
    sensor: ProjectFileVersionSensor;
    worldCoords: any /*THREE.Vector3*/;
    lastSensorValue?: SensorGraph;
}
export interface ISensorBoxInfoExtended extends ISensorBoxInfo {
    clientCoords: any /*THREE.Vector3*/;
    depth: number;
}

export interface ISensorListBoxInfo {
    sensorBoxInfo: ISensorBoxInfoExtended[];
    minDepth: number;
    maxDepth: number;
}

export interface ISensorInViewerProps {
    // projectFileVersionID: string;
    viewer: Autodesk.Viewing.Viewer3D;
    viewable: any;
    loading?: boolean;
    error?: ApolloError | undefined;
    sensorBoxes: ISensorBoxInfo[];
    allowDraggingAll?: boolean;
    onPushpinCreated?: (sensorModel: SensorModelStock, position: any) => void;
    onPushpinModified?: (
        projectFileVersionSensor: ProjectFileVersionSensor,
        position: any,
        displayGraph?: boolean | undefined,
        displayName?: undefined
    ) => void;
    onPushpinRemove?: (projectFileVersionSensor: ProjectFileVersionSensor) => void;
}

const getVectorFormGraphData = (sensorGraph: SensorGraph): THREE.Vector3 => {
    return new THREE.Vector3(
        sensorGraph?.doubleValue1 as number,
        sensorGraph?.doubleValue2 as number,
        sensorGraph?.doubleValue3 as number
    );
};

const SensorsInViewer: React.FC<ISensorInViewerProps> = (props: ISensorInViewerProps) => {
    const {
        viewer,
        viewable,
        loading,
        error,
        sensorBoxes,
        onPushpinCreated,
        onPushpinModified,
        onPushpinRemove,
        allowDraggingAll,
    } = props;
    const classes = useStyles();

    const dispatch = useDispatch<DispatchAction>();
    const sensorToAdd = useSensorToAdd();

    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    const [sharedPropertyDbPath, setSharedPropertyDbPath] = useState<string>(); // for caching
    const [isDragging, setIsDragging] = useState<ProjectFileVersionSensor>();
    const [modelsMap, setModelsMap] = useState<{ [key: number]: { [key: number]: Autodesk.Viewing.Model } }>({});
    const [sensorsBoxesMap, setSensorsBoxesMap] = useState<{ [key: string /*mqttcode*/]: ISensorBoxInfo }>({});
    const [draggable, setIsDraggable] = useState<ProjectFileVersionSensor>();
    const [lastPositions, setLastPositions] = useState<{ [key: string]: THREE.Vector3 }>();

    const [sensorBoxesInfo, setSensorBoxesInfo] = useState<ISensorListBoxInfo>({
        sensorBoxInfo: [],
        minDepth: 0,
        maxDepth: 1,
    });

    const assemblySensorsPositions: MutableRefObject<{ [key: number /*sensorModelStockID*/]: THREE.Vector3 }> = useRef(
        {}
    );
    const commonSensorsPositions: MutableRefObject<{ [key: string /*mqttcode*/]: THREE.Vector3 }> = useRef({});
    const sensorToAssemblyMap: MutableRefObject<{
        [key: number /*sensorModelStockID*/]: ProjectFileVersionSensor;
    }> = useRef({});

    const {
        data: defaultColorData,
        loading: defaultColorLoading,
        error: defaultColorError,
    } = useGetSensorGraphDefaultColorQuery({
        variables: {},
    });

    // add reference to access latest state
    const sensorBoxesRef = useRef(sensorBoxes);
    const modelsMapRef = useRef(modelsMap);
    const sensorsBoxesMapRef = useRef(sensorsBoxesMap);

    function useDeepEffect(fn, deps) {
        const isFirst = useRef(true);
        const prevDeps = useRef(deps);

        useEffect(() => {
            const isSame = prevDeps.current.every((obj, index) => isEqual(obj, deps[index]));

            if (isFirst.current || !isSame) {
                fn();
            }

            isFirst.current = false;
            prevDeps.current = deps;
        }, deps);
    }

    useEffect(() => {
        // cache sharedPropertyDbPath
        const path: string = viewable.doc.getFullPath(viewable.findPropertyDbPath());
        setSharedPropertyDbPath(path);
    }, [viewable]);

    // add extension listeners
    useEffect(() => {
        if (viewer) {
            viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, renderSensors);
        }
    }, [viewer]);

    useEffect(() => {
        if (viewer) {
            // merge normal sensors aand assemblies
            placeAssemblies(sensorBoxes);
            sensorBoxesRef.current = sensorBoxes;
            renderSensors();
        }
    }, [viewer, sensorBoxes]);

    useDeepEffect(() => {
        renderSensors();
        viewer.impl.invalidate(true, false, false);
    }, [lastPositions]);

    const placeAssemblies = (sensorBoxesInput: ISensorBoxInfo[]): void => {
        sensorBoxesInput.forEach(sensorBoxInfo => {
            if (sensorBoxInfo.sensor?.sensorModelStocks?.sensorModel?.sensorType?.isAssembly) {
                // try position assembly
                placeAssemly(sensorBoxInfo, sensorBoxesInput);
            }
            // else if (sensorBoxInfo?.sensor?.sensorModelStocks?.sensorModel?.isLocalizationSensor) {
            //     // is location
            //     const mqttCode = sensorBoxInfo?.sensor?.sensorModelStocks?.mqqtCode as string
            //     sensorsBoxesMapRef.current = {...sensorsBoxesMapRef.current, [mqttCode]: sensorBoxInfo};
            // }
        });
    };

    const placeAssemly = (sensorBox: ISensorBoxInfo, sensorBoxesInput: ISensorBoxInfo[]): void => {
        console.info("Placing assembly", sensorBox);

        const localizationSensor = sensorBox.sensor?.sensorModelStocks?.sensorModel?.sensorAssembly?.sensorAssemblyItems?.find(
            item => item?.sensorModelStock?.sensorModel?.isLocalizationSensor
        );
        let localizationPoint;

        let localizationSensorBox;
        let worldCoords;

        if (localizationSensor) {
            const mqttCode = localizationSensor?.sensorModelStock?.mqqtCode as string;

            localizationSensorBox = sensorBoxesInput.find(sbi => sbi?.sensor?.sensorModelStocks?.mqqtCode === mqttCode);

            sensorsBoxesMapRef.current = { ...sensorsBoxesMapRef.current, [mqttCode]: sensorBox };

            const localizationMatrix = JSON.parse(
                localizationSensor.sensorAssemblyItemExternals?.find(e => e?.externalSystem?.code == "forge")
                    ?.transformationMatrix?.matrix as string
            );
            localizationPoint = new THREE.Vector3(localizationMatrix.x, localizationMatrix.y, localizationMatrix.z);
        } else {
            localizationPoint = new THREE.Vector3(0, 0, 0);
        }

        if (localizationSensorBox?.lastSensorValue) {
            const sensorCoords = new THREE.Vector3(
                localizationSensorBox.lastSensorValue.doubleValue1 as number,
                localizationSensorBox.lastSensorValue.doubleValue2 as number,
                localizationSensorBox.lastSensorValue.doubleValue3 as number
            );

            transformLocationSensorCoordinates(sensorCoords);

            worldCoords = sensorCoords;
        } else {
            worldCoords = new THREE.Vector3(sensorBox.worldCoords.x, sensorBox.worldCoords.y, sensorBox.worldCoords.z);
        }

        const scale = new THREE.Vector3(0.01, 0.01, 0.01); // TODO!

        // geometries
        sensorBox.sensor?.sensorModelStocks?.sensorModel?.sensorAssembly?.sensorAssemblyGeometries?.forEach(
            geometry => {
                //  processing geometry
                const matrix = JSON.parse(
                    geometry?.sensorAssemblyGeometryExternals?.find(e => e?.externalSystem?.code == "forge")
                        ?.transformationMatrix?.matrix as string
                );
                const diffVector = new THREE.Vector3(matrix.position.x, matrix.position.y, matrix.position.z);

                const scaledDiff = diffVector.sub(localizationPoint).multiply(scale);
                const transformedPosition = worldCoords.clone().add(scaledDiff);

                const transformOptions: any = {
                    position: transformedPosition,
                    quaternion: new THREE.Quaternion(0, 0, 0, 1),
                };

                const model =
                    modelsMapRef.current?.[sensorBox.sensor?.projectFileVersionSensorID as number]?.[
                        geometry?.sensorAssemblyGeometryID as number
                    ];

                if (!model) {
                    // is new -> place
                    loadProjectFileVersion(
                        viewer,
                        sharedPropertyDbPath,
                        keycloak.token,
                        geometry?.projectFileVersions,
                        transformOptions,
                        geometry?.sensorAssemblyGeometryID as number
                    ).then(model => {
                        // store model references
                        const sensorKey = sensorBox.sensor?.projectFileVersionSensorID as number;
                        let sensorBoxModels = modelsMapRef.current[sensorKey];

                        if (!sensorBoxModels) {
                            sensorBoxModels = {};
                        }

                        // todo better
                        // geometries are not visible when not aligning
                        viewer.showAll();

                        sensorBoxModels[geometry?.sensorAssemblyGeometryID as number] = model;
                        modelsMapRef.current = { ...modelsMapRef.current, [sensorKey]: { ...sensorBoxModels } };
                    });
                } else {
                    // already exists -> transform only
                    transformOptions.scale = new THREE.Vector3(0.001, 0.001, 0.001);
                    transformModel(viewer, model, transformOptions);
                    viewer.showAll();
                }

                return;
            }
        );

        // sensors
        sensorBox.sensor?.sensorModelStocks?.sensorModel?.sensorAssembly?.sensorAssemblyItems?.forEach(item => {
            //  processing sensors
            const matrix = JSON.parse(
                item?.sensorAssemblyItemExternals?.find(e => e?.externalSystem?.code == "forge")?.transformationMatrix
                    ?.matrix as string
            );
            const diffVector = new THREE.Vector3(matrix.x, matrix.y, matrix.z);
            const scaledDiff = diffVector.sub(localizationPoint).multiply(scale);
            const transformedPosition = worldCoords.clone().add(scaledDiff);
            sensorToAssemblyMap.current[item?.sensorModelStock?.sensorModelStockID as number] = sensorBox.sensor;
            assemblySensorsPositions.current[
                item?.sensorModelStock?.sensorModelStockID as number
            ] = transformedPosition;
        });

        return;
    };

    const transformLocationSensorCoordinates = (coordinates: THREE.Vector3) => {
        // intentionally in this format to allow fast change from ml

        const m11 = 0.997197;
        const m12 = 0.074794;
        const m13 = -0.001818;
        const m14 = 0;
        const m21 = -0.074795;
        const m22 = 0.997199;
        const m23 = -0.000782;
        const m24 = 0;
        const m31 = 0.001755;
        const m32 = 0.000916;
        const m33 = 0.999998;
        const m34 = 0;
        const offsetX = -43.166257;
        const offsetY = -83.942721;
        const offsetZ = -5.501766;
        const m44 = 1;

        const m = new THREE.Matrix4();

        m.set(m11, m21, m31, offsetX, m12, m22, m32, offsetY, m13, m23, m33, offsetZ, m14, m24, m34, m44);

        coordinates.applyMatrix4(m);
    };

    const getSensorCoordinates = (sensorBoxInfo: ISensorBoxInfo): THREE.Vector3 => {
        const stockId = sensorBoxInfo.sensor?.sensorModelStocks?.sensorModelStockID as number;
        const assemblyCoordinates = assemblySensorsPositions.current[stockId];

        // assembly
        if (assemblyCoordinates) {
            return assemblyCoordinates;
        }

        // last sensor data
        const commonCoordinates =
            commonSensorsPositions.current[sensorBoxInfo.sensor?.sensorModelStocks?.mqqtCode as string];
        if (commonCoordinates) {
            return commonCoordinates;
        }

        // last data
        return sensorBoxInfo.worldCoords;
    };

    const renderSensors = () => {
        const dims = viewer.getDimensions();
        const sensorBoxesList: ISensorListBoxInfo = {
            maxDepth: 0,
            minDepth: 10000,
            sensorBoxInfo: [],
        };

        sensorBoxesRef.current.forEach(sensorBoxInfo => {
            try {
                const sensorBoxInfoLocal = { ...sensorBoxInfo };
                if (
                    !sensorBoxInfoLocal.worldCoords ||
                    sensorBoxInfoLocal.sensor?.sensorModelStocks?.sensorModel?.isLocalizationSensor
                ) {
                    // sensor is probably from assembly... do not render
                    // try to calculate
                    sensorBoxInfoLocal.worldCoords = getSensorCoordinates(sensorBoxInfoLocal);
                }

                if (sensorBoxInfoLocal.sensor?.sensorModelStocks?.sensorModel?.sensorType?.isAssembly) {
                    // assembly pointer, do not render
                    return;
                }

                const positionVector = new THREE.Vector3(
                    sensorBoxInfoLocal.worldCoords.x,
                    sensorBoxInfoLocal.worldCoords.y,
                    sensorBoxInfoLocal.worldCoords.z
                );
                const clientCoords = viewer.worldToClient(positionVector);
                if (isInViewport(clientCoords, dims)) {
                    const distance = viewer.getCamera().position.distanceTo(positionVector);

                    // max/min depths
                    sensorBoxesList.maxDepth = Math.max(sensorBoxesList.maxDepth, distance);
                    sensorBoxesList.minDepth = Math.min(sensorBoxesList.minDepth, distance);

                    sensorBoxesList.sensorBoxInfo.push({
                        ...sensorBoxInfoLocal,
                        clientCoords: clientCoords,
                        depth: distance,
                    });
                }
            } catch (e) {
                console.error("Error detecting sensor vector", e);
            }
        });
        setSensorBoxesInfo(sensorBoxesList);
    };

    const isInViewport = (point: any /*THREE.Vector3*/, dim: any) => {
        return 0 <= point.x && point.x <= 0 + dim.width && 0 <= point.y && point.y <= 0 + dim.height;
    };

    const getScale = (sensorBox: ISensorBoxInfoExtended) => {
        // scale limits
        // min as a scale for the closest sensor
        // max as a scale for the furthest sensor
        const [min, max] = [1, 1];
        return (
            max -
            ((max - min) * (sensorBox.depth - sensorBoxesInfo.minDepth)) /
                (sensorBoxesInfo.maxDepth - sensorBoxesInfo.minDepth)
        );
    };

    const pusphinRemove = (sensorBox: ISensorBoxInfo, projectFileVersionSensor: ProjectFileVersionSensor) => {
        if (onPushpinRemove) {
            const assemblySensor =
                sensorToAssemblyMap.current[sensorBox.sensor?.sensorModelStocks?.sensorModelStockID as number];
            if (assemblySensor) {
                onPushpinRemove(assemblySensor);

                // remove geometries
                const models = modelsMapRef.current?.[assemblySensor?.projectFileVersionSensorID as number] as {
                    [key: number]: Autodesk.Viewing.Model;
                };
                Object.keys(models).forEach(key => {
                    // console.log(key,models[key] )
                    viewer.unloadModel(models[key]);
                });
            } else {
                onPushpinRemove(projectFileVersionSensor);
            }
        }
    };

    return (
        <Box
            className={clsx({ [classes.root]: true, "is-dragging": isDragging!! || sensorToAdd!! })}
            onDragOver={e => {
                e.preventDefault();
            }}
            onDrop={async e => {
                e.preventDefault();
                const clientRect = e.currentTarget.getBoundingClientRect();
                const viewerCoords = {
                    x: e.clientX - clientRect.left,
                    y: e.clientY - clientRect.top,
                };
                const worldPosition = viewer.clientToWorld(viewerCoords.x, viewerCoords.y);

                if (!worldPosition) {
                    console.warn("World position not found!");

                    const notification: INotification = {
                        key: 1,
                        variant: "error",
                        // todo localization
                        message: "World position not found! Did you drop the sensor over geometry?",
                    };
                    dispatch(addNotification({ notification }));
                    setIsDragging(undefined);
                    dispatch(onSelectSensorToAdd({}));
                    return;
                }

                if (sensorToAdd) {
                    if (onPushpinCreated) {
                        await onPushpinCreated(sensorToAdd, worldPosition.point);
                    }
                    dispatch(onSelectSensorToAdd({}));
                } else if (isDragging) {
                    if (onPushpinModified) {
                        await onPushpinModified(isDragging as ProjectFileVersionSensor, worldPosition.point);
                    }
                    setIsDragging(undefined);
                } else {
                    console.log("Nothing to drop");
                }
            }}
        >
            {sensorBoxesInfo.sensorBoxInfo.map(sensorBox => {
                return (
                    <Box
                        draggable={draggable === sensorBox.sensor}
                        onDragStart={e => {
                            if (draggable === sensorBox.sensor) {
                                setIsDragging(sensorBox.sensor);
                            }
                        }}
                        key={`sensor-box-${sensorBox.sensor.sensorModelStocks?.sensorModelStockID}`}
                        className={clsx({
                            [classes.sensorBox]: true,
                            [classes.draggable]: draggable === sensorBox.sensor,
                        })}
                        style={{
                            left: `${sensorBox.clientCoords.x}px`,
                            top: `${sensorBox.clientCoords.y}px`,
                            transform: `scale(${getScale(sensorBox)})`,
                            zIndex: Math.round((100 - sensorBox.depth) * 100),
                        }}
                    >
                        <PushpinWrapper
                            sensor={sensorBox.sensor}
                            handleDelete={projectFileVersionSensor =>
                                pusphinRemove(sensorBox, projectFileVersionSensor)
                            }
                            handleDrag={setIsDraggable}
                            loading={loading}
                            error={error}
                            dragDisabled={
                                !allowDraggingAll &&
                                (!!sensorBox.sensor?.sensorModelStocks?.sensorModel?.isLocalizationSensor ||
                                    !!assemblySensorsPositions.current[
                                        sensorBox.sensor?.sensorModelStocks?.sensorModelStockID as number
                                    ])
                            }
                            deleteDisabled={
                                !!assemblySensorsPositions.current[
                                    sensorBox.sensor?.sensorModelStocks?.sensorModelStockID as number
                                ] && !sensorBox.sensor?.sensorModelStocks?.sensorModel?.isLocalizationSensor
                            }
                            defaultColor={
                                (sensorBox.sensor?.sensorModelStocks?.sensorModel?.isLocalizationSensor
                                    ? defaultColorData?.sensorGraphColors?.find(c => c?.color === "#04a7f7")
                                    : defaultColorData?.sensorGraphColors?.[0]) as SensorGraphColor
                            }
                        />
                    </Box>
                );
            })}
        </Box>
    );
};

export function useLazyQuery<TData = any, TVariables = OperationVariables>(query: DocumentNode) {
    const client = useApolloClient();
    return React.useCallback(
        (variables: TVariables) =>
            client.query<TData, TVariables>({
                query: query,
                variables: variables,
            }),
        [client]
    );
}
export default SensorsInViewer;
