import { createAction, DispatchAction } from "@iolabs/redux-utils";
import { ProjectFileVersion, ProjectFileVersionSensor, SensorModelStock } from "../../graphql/generated/graphql";

export enum ActionTypes {
    OnSelectSensorToAdd = "model/ON_SELECT_SENSOR_TO_ADD",
    OnSelectGeometryToAdd = "model/ON_SELECT_GEOMETRY_TO_ADD",
    OnAssemblyLoadedToModel = "model/ON_ASSEMBLY_LOADED_TO_MODEL",
    OnAssemblyItemsUsed = "model/ON_ASSEMBLY_ITEMS_USED",
}

export type SelectSensorToAddOptions = {
    sensor?: SensorModelStock;
};
export type SelectGeometryToAddOptions = {
    geometry?: ProjectFileVersion;
};
export type AssemlyLoadedToModelOptions = {
    assembly?: ProjectFileVersionSensor;
    scale: THREE.Vector3;
};
export type AssemblyItemsUsedOptions = {
    sensors?: SensorModelStock[];
};

const Actions = {
    onSelectSensorToAdd: (options: SelectSensorToAddOptions) => createAction(ActionTypes.OnSelectSensorToAdd, options),
    onSelectGeometryToAdd: (options: SelectGeometryToAddOptions) =>
        createAction(ActionTypes.OnSelectGeometryToAdd, options),
    onAssemblyLoadedToModel: (options: AssemlyLoadedToModelOptions) =>
        createAction(ActionTypes.OnAssemblyLoadedToModel, options),
    onAssemblyItemsUsed: (options: AssemblyItemsUsedOptions) => createAction(ActionTypes.OnAssemblyItemsUsed, options),
};

export function onSelectSensorToAdd(options: SelectSensorToAddOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.onSelectSensorToAdd(options));
    };
}

export function onSelectGeometryToAdd(options: SelectGeometryToAddOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.onSelectGeometryToAdd(options));
    };
}
export function onAsseblyLoadedToModel(options: AssemlyLoadedToModelOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.onAssemblyLoadedToModel(options));
    };
}
export function onAsseblyItemsUsedModel(options: AssemblyItemsUsedOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.onAssemblyItemsUsed(options));
    };
}
