import { Box, createStyles, Tab, Tabs, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Path } from "../../pages/PageRouter/PageRouter";
import Editor from "../Editor/Editor";
import InventoryWrapper from "../Inventory/InventoryWrapper";
import SensorWrapper from "../Sensor/SensorWrapper";
import TabPanel from "./TabPanel";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            width: "100%",
            padding: theme.spacing(2),
        },
        tabs: {
            paddingBottom: theme.spacing(2),
            borderBottom: `1px solid ${theme.palette.grey["300"]}`,
        },
        tab: {
            textTransform: "none",
            padding: theme.spacing(0),
            marginRight: theme.spacing(2),
            minWidth: "auto",
            minHeight: "30px",
        },
    })
);

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}

const handleActiveTab = (pathname: string): number => {
    switch (pathname) {
        case Path.ROOT:
            return 0;

        case Path.EDITOR:
            return 1;

        default:
            // todo better (PS)
            if (pathname.startsWith(Path.EDITOR)) {
                return 1;
            }
            return 0;
    }
};

const ControlPanel: React.FC = () => {
    const classes = useStyles();
    const location = useLocation();
    const [tab, setTab] = useState(handleActiveTab(location.pathname));
    const [inventory, setInventory] = useState<string[]>([]);

    const handleChangeTab = (event: React.ChangeEvent<{}>, newTab: number) => {
        setTab(newTab);
    };

    const handleChangeInventory = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInventory(
            event.target.checked
                ? [...inventory, event.target.name]
                : inventory.filter(name => name !== event.target.name)
        );
    };

    const handleInitInventory = (initInventory: string[]) => {
        setInventory(initInventory);
    };

    return (
        <Box className={classes.root}>
            <Tabs
                value={tab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                className={classes.tabs}
            >
                <Tab label="Model" component={RouterLink} to={Path.ROOT} {...a11yProps(0)} className={classes.tab} />
                <Tab
                    label="Assembly editor"
                    component={RouterLink}
                    to={Path.EDITOR}
                    {...a11yProps(1)}
                    className={classes.tab}
                />
            </Tabs>
            <TabPanel value={tab} index={0}>
                <InventoryWrapper
                    inventory={inventory}
                    handleInitInventory={handleInitInventory}
                    handleChangeInventory={handleChangeInventory}
                />
                <SensorWrapper inventory={inventory} />
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <Editor />
            </TabPanel>
        </Box>
    );
};

export default ControlPanel;
