import { Box, createStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import ForgeViewer from "iolabs-react-forge-viewer";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useKeycloak } from "react-keycloak";
import { viewerProxy } from "../../api/viewer/client";
import { IViewerProxyResponse } from "../../api/viewer/types";
import config from "../../config/config";
import SensorsInViewer from "./SensorsInViewer";
import { Emea } from "./types/type";

import AssemblyEditor, { IAssemblyEditorProps } from "./AssemblyEditor";
import ProjectFileVersionSensors from "./ProjectFileVersionSensors";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            position: "relative",
            zIndex: 0,
            height: "100%",
            "& .adsk-viewing-viewer .adsk-toolbar #toolbar-translate .transform-translate": {
                "&::before": {
                    content: "'T'",
                    width: "30px",
                    height: "30px",
                },
            },
        },
        box: {
            position: "relative",
            height: "100%",
            maxHeight: "100%",
            overflowX: "hidden",
            "& .viewcube": {
                transform: "scale(0.6)",
            },
        },
        skeleton: {
            height: "100%",
        },
        hiddenControls: {
            "& .adsk-viewing-viewer .adsk-toolbar": {
                display: "none !important",
            },
        },
    })
);

type ViewerState = {
    api: string;
    urn: string;
    isEmea: boolean;
};

interface IViewerProps {
    urn: string;
    projectId: string;
    selectionIds?: string[];
    projectFileVersionID: number;
    disableFetchLatest?: boolean;
    assemblyEditor?: boolean | number;
    enableSensors?: boolean;
}

const Viewer: React.FC<IViewerProps> = (props: IViewerProps) => {
    const {
        urn,
        projectId,
        selectionIds,
        projectFileVersionID,
        disableFetchLatest,
        enableSensors,
        assemblyEditor,
    } = props;

    const classes = useStyles();
    const [viewer, setViewer] = useState<any>();
    const [extensions, setExtensions] = useState<any[]>([]);
    const [viewSwitcherExtensionID, setViewSwitcherExtensionID] = useState<string>();
    const [model, setModel] = useState<any>();
    const [unmounted, setUnmounted] = useState<boolean>(false);
    const [viewable, setViewable] = useState<any>();
    const { keycloak, initialized: keycloakInitialized } = useKeycloak();
    const [viewerState, setViewerState] = useState<ViewerState | null>(null);
    const [viewSwitcherExtension, setViewSwitcherExtension] = useState<any>();

    useEffect(() => {
        viewerProxy(keycloak.token, urn, projectId, !disableFetchLatest)
            .then((response: IViewerProxyResponse) => {
                setViewerState({
                    api: response?.api,
                    urn: response?.urn,
                    isEmea: response?.isEmea,
                });
            })
            .catch(error => {
                console.log(error);
            });
    }, [urn, projectId, keycloakInitialized]);

    useEffect(() => {
        if (viewer) {
            if (selectionIds) {
                const ids = selectionIds.map(id => parseInt(id));
                renderSelection(ids);
                renderIsolation(ids);
            }
        }
    }, [viewer, selectionIds]);

    useEffect(() => {
        if (viewSwitcherExtension && viewable) {
            const finalView = viewSwitcherExtension.getViewByName(viewable, "final");
            if (finalView) {
                // apply view
                viewSwitcherExtension.applyView(finalView);
            }
        }
    }, [viewSwitcherExtension, viewable]);

    const handleDocumentLoaded = (doc: any, viewables: any[]) => {
        if (!unmounted) {
            if (viewables.length === 0) {
                console.error("Document contains no viewables.");
            } else {
                setViewable(viewables[0]);
            }
        }
    };

    const handleViewerLoaded = (viewer: Autodesk.Viewing.Viewer3D) => {
        if (!unmounted) {
            setViewer(viewer);
        }
    };

    const handleDocumentError = (viewer: any, error: any) => {
        console.error("Error loading a document.");
    };

    const handleModelLoaded = (viewer: Autodesk.Viewing.Viewer3D, model: Autodesk.Viewing.Model) => {
        viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, () => {
            // viewer settings - disabled ground shadow
            viewer.setGroundShadow(false);
        });
        setModel(model);

        viewer.loadExtension(viewSwitcherExtensionID as string).then(setViewSwitcherExtension);
    };

    const handleModelError = (viewer: any, model: any) => {
        console.error("Error loading the model.");
    };

    const handleTokenRequested = (onAccessToken: any) => {
        onAccessToken(keycloak.token);
    };

    const handleViewerError = () => {
        console.error("Error loading viewer.");
    };

    const handleForgeScriptLoaded = () => {
        import("@iolabs/forge-view-switcher-extension").then(module => {
            setViewSwitcherExtensionID(module.ExtensionID);
        });
    };

    const renderSelection = (selectionIds: number[]) => {
        if (!isEmpty(selectionIds)) {
            viewer.select(selectionIds);
        } else {
            viewer.clearSelection();
        }
    };

    const renderIsolation = (isolationIds: number[]) => {
        if (!isEmpty(isolationIds)) {
            viewer.isolate(isolationIds);
        } else {
            if (viewer.model) {
                viewer.showAll();
            }
        }
    };

    const getAssembleEditorProps = (): IAssemblyEditorProps => {
        const assemblyEditorProps: IAssemblyEditorProps = {
            viewer: viewer,
            viewable: viewable,
        };
        if (Number.isInteger(assemblyEditor)) {
            assemblyEditorProps.assemblyID = assemblyEditor as number;
        }
        return assemblyEditorProps;
    };

    return (
        <Box className={classes.root}>
            {viewerState ? (
                <Box className={classes.box}>
                    {model && !!enableSensors && (
                        <ProjectFileVersionSensors
                            projectFileVersionID={projectFileVersionID}
                            viewer={viewer}
                            viewable={viewable}
                        />
                    )}
                    {model && !!assemblyEditor && <AssemblyEditor {...getAssembleEditorProps()} />}
                    <ForgeViewer
                        version="7.27"
                        urn={viewerState.urn}
                        api={viewerState.isEmea ? Emea.eu : Emea.default}
                        view={viewable}
                        query={{ type: "geometry" }}
                        headless={false}
                        proxy={config.api.forgeProxyUrl}
                        onViewerError={handleViewerError}
                        onTokenRequest={handleTokenRequested}
                        onDocumentLoad={handleDocumentLoaded}
                        onDocumentError={handleDocumentError}
                        onModelLoad={handleModelLoaded}
                        onModelError={handleModelError}
                        onViewerLoad={handleViewerLoaded}
                        onScriptLoaded={handleForgeScriptLoaded}
                    />
                </Box>
            ) : (
                <Box display="flex" flexDirection="column" alignItems="center" height="100%">
                    <Skeleton variant="rect" width="100%" className={classes.skeleton} />
                </Box>
            )}
        </Box>
    );
};

export default Viewer;
