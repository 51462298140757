import React, { useEffect, useState } from "react";
import { useGlobalDialog } from "@iolabs/wip-dialog";
import { addNotification, INotification } from "@iolabs/notifier";
import { useDispatch } from "react-redux";
import { DispatchAction } from "@iolabs/redux-utils";
import { Box, Button, createStyles, TextField, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { v4 as uuidv4 } from "uuid";
import {
    CreateAssemblyMutationVariables,
    CreateProjectFileVersionSensorMutationVariables,
    CreateSensorModelMutationVariables,
    CreateSensorModelStockMutationVariables,
    SensorAssembly,
    SensorAssemblyGeometryCreate,
    SensorAssemblyItemCreate,
    SensorModelStock,
    UpdateAssemblyMutation,
    UpdateAssemblyMutationVariables,
    useCreateAssemblyMutation,
    useCreateProjectFileVersionSensorMutation,
    useCreateSensorModelMutation,
    useCreateSensorModelStockMutation,
    useUpdateAssemblyMutation,
} from "../../../graphql/generated/graphql";
import { getSensorTypeId, SensorType } from "../../Sensor/SensorWrapper";
import Loading from "../../Loading/Loading";
import { useHistory } from "react-router";
import { ISensorBoxInfo } from "../../Viewer/SensorsInViewer";

interface IAssemblyFormProps {
    selectGeometries: () => SensorAssemblyGeometryCreate[];
    selectSensors: () => SensorAssemblyItemCreate[];
    selectPositionSensors: () => ISensorBoxInfo[];
    assemblyData?: SensorAssembly;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        form: {
            position: "relative",
            pointerEvents: "all",
            color: "white",
            "& label": {
                color: "white",
            },
            "& .MuiInput-underline:after": {
                borderBottomColor: theme.palette.primary,
            },
            "& .MuiInput-root": {
                "& fieldset": {
                    borderColor: "white",
                },
                "& input": {
                    color: "white",
                },
                "&:hover fieldset": {
                    borderColor: "white",
                },
                "&.Mui-focused fieldset": {
                    borderColor: "white",
                },
            },
        },
        head: {
            fontWeight: 400,
            marginBottom: "3rem",
        },
        buttonBox: {
            display: "flex",
            justifyContent: "space-between",
            marginTop: "5rem",
        },
        button: {
            width: "48%",
            textTransform: "none",
        },
    })
);

const logSuccess = (name: string, data: any) => {
    console.log(name, data);
};
const logError = (name: string, data: any) => {
    console.error(name, data);
};

const AssemblyForm: React.FC<IAssemblyFormProps> = (props: IAssemblyFormProps) => {
    const classes = useStyles();
    const { selectGeometries, selectSensors, assemblyData, selectPositionSensors } = props;
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [manufacturer, setManufacturer] = useState<string>("");
    const [price, setPrice] = useState<string>("1");
    const [loading, setLoading] = useState<boolean>(false);
    const { setGlobalDialogOpen } = useGlobalDialog();
    const dispatch = useDispatch<DispatchAction>();

    const history = useHistory();

    const [createAssemblyMutation] = useCreateAssemblyMutation({
        refetchQueries: [], // asembly list
    });

    const [createSensorModelMutation] = useCreateSensorModelMutation({
        refetchQueries: [], // sensor model list
    });

    const [createSensorModelStockMutation] = useCreateSensorModelStockMutation({
        refetchQueries: ["getSensorModelStocksByType"], // sensor model stock list -- IMPORTANT
    });

    const [updateAssemblyMutation] = useUpdateAssemblyMutation({
        refetchQueries: ["getSensorModelStocksByType"], // asembly list
    });

    useEffect(() => {
        if (assemblyData) {
            setName(assemblyData?.name as string);
            setManufacturer(assemblyData?.manufacturer as string);
            // setPrice(assemblyData?.price?.toString() as string);
            setDescription(assemblyData?.description as string);
        }
    }, [assemblyData]);

    async function submitAssembly(projectID: number, data) {
        if (assemblyData?.sensorAssemblyID) {
            // update
            const updateAssemblyData: UpdateAssemblyMutationVariables = {
                ...data,
                sensorAssemblyID: assemblyData?.sensorAssemblyID,
            } as UpdateAssemblyMutationVariables;
            await updateAssembly(projectID, updateAssemblyData);
        } else {
            // insert
            await createAssembly(projectID, data as CreateAssemblyMutationVariables);
        }
    }

    const updateAssembly = async (projectID: number, data: UpdateAssemblyMutationVariables) => {
        await updateAssemblyMutation({
            variables: data as UpdateAssemblyMutationVariables,
        })
            .then(async result => {
                logSuccess("updateAssemblyMutation", result);
            })
            .catch(result => {
                setLoading(false);
                logError("updateAssemblyMutation", result);
            });
    };

    const createAssembly = async (projectID: number, data: CreateAssemblyMutationVariables) => {
        await createAssemblyMutation({
            variables: data as CreateAssemblyMutationVariables,
        })
            .then(async result => {
                logSuccess("createProjectFileVersionSensorMutation", result);

                await createSensorModel(projectID, {
                    sensorModel: {
                        name: result.data?.createSensorAssembly?.name,
                        description: result.data?.createSensorAssembly?.description,
                        manufacturer: result.data?.createSensorAssembly?.manufacturer,
                        sensorAssemblyID: result.data?.createSensorAssembly?.sensorAssemblyID,
                        sensorTypeID: getSensorTypeId(SensorType.ASSEMBLY),
                        thumbnailImage: "urn:adsk.wipemea:dm.lineage:RYcCEsGPQoeCrzbgAf3bFw", // todo
                        price: parseFloat(price as string), // hardcoded
                    },
                } as CreateSensorModelMutationVariables);
            })
            .catch(result => {
                setLoading(false);
                logError("createProjectFileVersionSensorMutation", result);
            });
    };

    async function createSensorModel(
        projectID: number,
        createSensorModelMutationVariables: CreateSensorModelMutationVariables
    ) {
        await createSensorModelMutation({
            variables: createSensorModelMutationVariables,
        })
            .then(async result => {
                logSuccess("createSensorModelMutation", result);

                await createSensorModelStock({
                    sensorModelStock: {
                        name: result.data?.createSensorModel?.name,
                        projectID: projectID,
                        sensorModelID: result.data?.createSensorModel?.sensorModelID,
                        inventaryCode: uuidv4(), // todo
                        mqqtCode: uuidv4(), // todo
                    },
                } as CreateSensorModelStockMutationVariables);
            })
            .catch(result => {
                setLoading(false);
                logError("createSensorModelMutation", result);
            });
    }

    async function createSensorModelStock(
        createSensorModelStockMutationVariables: CreateSensorModelStockMutationVariables
    ) {
        await createSensorModelStockMutation({
            variables: createSensorModelStockMutationVariables,
            // refetchQueries: [{query:"getSensorModelStocksByType"]
        })
            .then(result => {
                logSuccess("createSensorModelStockMutation", result);
                setLoading(false);
                // navigate to model
                history.push("");
            })
            .catch(result => {
                setLoading(false);
                logError("createSensorModelStockMutation", result);
            });
    }

    const validate = (data: any): string[] => {
        const errors: string[] = [];
        // todo localization
        [
            { key: "name", name: "Assembly name" },
            { key: "description", name: "Description" },
            { key: "manufacturer", name: "Producer" },
        ].forEach(item => {
            if (!data[item.key]) {
                // todo localization
                errors.push(`Required field "${item.name}" is not filled`);
            }
        });
        if (!data?.geometries?.length) {
            // todo localization
            errors.push(`No geometries added to assembly`);
        }
        if (!data?.assemblyItems?.length) {
            // todo localization
            errors.push(`No sensor added to assembly`);
        } else {
            if (!selectPositionSensors()?.length) {
                errors.push(`No localization point added to assembly`);
            }
            if (selectPositionSensors()?.length > 1) {
                errors.push(`Multiple localization point added to assembly, only supports one`);
            }
        }

        return errors;
    };

    const handleSubmit = async event => {
        event.preventDefault();

        if (!!assemblyData?.sensorAssemblyID) {
            // edit disabled
            setGlobalDialogOpen(true);
            return;
        }

        const dataToSend = {
            name: name,
            description: description,
            manufacturer: manufacturer,
            geometries: selectGeometries(),
            assemblyItems: selectSensors(),
        };

        const errors = validate(dataToSend);

        if (errors.length > 0) {
            console.log("ERRORS", errors);
            errors.forEach((error, index) => {
                const notification: INotification = {
                    key: 1,
                    variant: "error",
                    message: error,
                };
                dispatch(addNotification({ notification }));
            });
        } else {
            // no validation errors, submit
            setLoading(true);

            await submitAssembly(1, dataToSend);
        }
    };

    // todo: PS localization
    return (
        <>
            {loading ? <Loading localOnly={true} /> : null}
            <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Typography className={classes.head} variant={"h3"}>
                    Assembly Information
                </Typography>
                <TextField
                    fullWidth={true}
                    id="assembly-name"
                    label="Assembly name"
                    value={name}
                    onInput={e => setName((e.target as HTMLTextAreaElement).value)}
                />
                <TextField
                    fullWidth={true}
                    id="producer"
                    label="Producer"
                    value={manufacturer}
                    onInput={e => setManufacturer((e.target as HTMLTextAreaElement).value)}
                />
                {/*<TextField*/}
                {/*    type={"number"}*/}
                {/*    fullWidth={true}*/}
                {/*    id="price"*/}
                {/*    label="Price"*/}
                {/*    value={price}*/}
                {/*    onInput={e => setPrice((e.target as HTMLTextAreaElement).value)}*/}
                {/*/>*/}
                <TextField
                    fullWidth={true}
                    id="description"
                    label="Description"
                    value={description}
                    onInput={e => setDescription((e.target as HTMLTextAreaElement).value)}
                />
                <Box className={classes.buttonBox}>
                    <Button
                        size={"large"}
                        color={"primary"}
                        className={classes.button}
                        type={"submit"}
                        variant={"contained"}
                    >
                        {/*todo localization*/}
                        {assemblyData?.sensorAssemblyID ? "Update assembly" : "Create Assembly"}
                    </Button>
                    <Button size={"large"} className={classes.button} type={"reset"} variant={"contained"}>
                        Discard
                    </Button>
                </Box>
            </form>
        </>
    );
};
export default AssemblyForm;
