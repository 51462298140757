import { getViewable } from "./document";

/**
 * Load additional geometry into assembly
 * @param projectFileVersion
 * @param loadOptions
 * @param transformOptions
 */
export const loadProjectFileVersion = async (
    viewer: Autodesk.Viewing.Viewer3D,
    sharedPropertyDbPath: any,
    token: string,
    projectFileVersion: any, //ProjectFileVersion,
    transformOptions?: any,
    sensorAssemblyGeometryID?: number,
    onModelLoaded?: (model: Autodesk.Viewing.Model) => void
) => {
    return new Promise<Autodesk.Viewing.Model>(async (resolve, reject) => {
        const urn: string = projectFileVersion?.projectFileVersionExternals?.find(
            pfve => pfve?.externalSystem?.code === "forge"
        )?.externalID as string;

        const { document, viewable } = await getViewable(urn, token);

        const options: any = {
            preserveView: true,
            keepCurrentModels: true,
            globalOffset: { x: 0, y: 0, z: 0 }, // to align the models
        };

        viewer
            .loadDocumentNode(document, viewable, options)
            .then(model => {
                if (onModelLoaded) {
                    onModelLoaded(model);
                }
                if (transformOptions) {
                    if (!transformOptions.scale) {
                        // if scale is not passed, try to detect actual scale
                        const scale = model.getUnitScale() / viewer.model.getUnitScale();
                        transformOptions.scale = new THREE.Vector3(scale, scale, scale);
                        console.log("Scaling", scale);
                    }
                    transformModel(viewer, model, transformOptions);
                }
                resolve(model);
            })
            .catch(error => {
                reject(error);
            });
    });
};

/**
 * Animation transform
 * @param viewer
 * @param model
 * @param transform
 * @returns {Promise<unknown>}
 */
export const transformModel = (viewer, model, transform) => {
    function _transformFragProxy(fragId) {
        const fragProxy = viewer.impl.getFragmentProxy(model, fragId);

        fragProxy.getAnimTransform();
        fragProxy.position = transform.position;
        fragProxy.scale = transform.scale;

        // not a standard three.js quaternion
        fragProxy.quaternion._x = transform.quaternion.x ?? transform.quaternion._x;
        fragProxy.quaternion._y = transform.quaternion.y ?? transform.quaternion._y;
        fragProxy.quaternion._z = transform.quaternion.z ?? transform.quaternion._z;
        fragProxy.quaternion._w = transform.quaternion.w ?? transform.quaternion._w;

        fragProxy.updateAnimTransform();
    }

    return new Promise(async (resolve, reject) => {
        const fragCount = model.getFragmentList().fragments.fragId2dbId.length;

        // fragIds range from 0 to fragCount - 1
        for (let fragId = 0; fragId < fragCount; ++fragId) {
            _transformFragProxy(fragId);
        }

        return resolve();
    });
};
