import { SecurityProvider } from "@iolabs/keycloak";
import { LanguageProvider } from "@iolabs/language-provider";
import { GlobalDialogProvider } from "@iolabs/wip-dialog";
import { ThemeProvider } from "@material-ui/styles";
import React from "react";
import GraphqlProvider from "../../graphql/GraphqlProvider";
import PageRouter from "../../pages/PageRouter/PageRouter";
import { StoreProvider } from "../../redux";
import theme, { GlobalCss } from "../../utils/Theming";
import de from "./../../translations/de.json";
import en from "./../../translations/en.json";
import App from "./App";

interface IProps {}

class AppWrapper extends React.Component<IProps> {
    public render() {
        return (
            <StoreProvider>
                <SecurityProvider initConfig={{ onLoad: "login-required", checkLoginIframe: false }}>
                    <ThemeProvider theme={theme}>
                        <GraphqlProvider>
                            <LanguageProvider
                                defaultLocale="en"
                                messages={{
                                    de,
                                    en,
                                }}
                            >
                                <GlobalDialogProvider>
                                    <App globalCss={<GlobalCss />}>
                                        <PageRouter />
                                    </App>
                                </GlobalDialogProvider>
                            </LanguageProvider>
                        </GraphqlProvider>
                    </ThemeProvider>
                </SecurityProvider>
            </StoreProvider>
        );
    }
}

export default AppWrapper;
