import {
    Box,
    createStyles,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Theme,
    Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useIntl } from "react-intl";
import { useGetSensorModelStocksByTypeQuery } from "../../../graphql/generated/graphql";
import SensorList from "../../Sensor/SensorList";
import { SensorType } from "../../Sensor/SensorWrapper";
import messages from "../messages";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        panel: {
            borderBottom: `1px solid ${theme.palette.grey["300"]}`,
        },
        summary: {
            paddingLeft: 0,
            paddingRight: theme.spacing(1),
        },
        expandedSummary: {
            minHeight: 0,
            marginTop: 0,
            marginBottom: 0,
        },
        details: {
            padding: `0 0 ${theme.spacing(2)} 0`,
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
    })
);

const LocalizationWrapper: React.FC = () => {
    const classes = useStyles();

    const {
        data: sensorModelsData,
        loading: sensorModelsLoading,
        error: sensorModelsError,
    } = useGetSensorModelStocksByTypeQuery({
        variables: {
            typeIds: [SensorType.LOCALIZATION],
        },
    });

    // translations
    const intl = useIntl();
    const transLocalization = intl.formatMessage({ ...messages.localizationPanel });

    return (
        <Box className={classes.root}>
            <ExpansionPanel square elevation={0} className={classes.panel}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="localization-points-panel"
                    id="localization-points-header"
                    classes={{ root: classes.summary, expanded: classes.expandedSummary }}
                >
                    <Typography className={classes.heading}>{transLocalization}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails classes={{ root: classes.details }}>
                    <SensorList data={sensorModelsData} loading={sensorModelsLoading} error={sensorModelsError} />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </Box>
    );
};

export default LocalizationWrapper;
