import { createStyles, Grid, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { ReactNode } from "react";

const defaultControlPanelHeight: number = 45;

type StylesProps = {
    controlPanelHeight?: number;
    controlPanelBackground?: string;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        controlPanel: (props: StylesProps | undefined) => ({
            height: `${props?.controlPanelHeight ? props?.controlPanelHeight : defaultControlPanelHeight}px`,
            minHeight: `${props?.controlPanelHeight ? props?.controlPanelHeight : defaultControlPanelHeight}px`,
            background: props?.controlPanelBackground ? props?.controlPanelBackground : theme.palette.grey["500"],
        }),
        fullscreenContainer: {
            height: "100%",
        },
        fullScreenPrimary: {
            height: "100%",
            [theme.breakpoints.up("sm")]: {
                minHeight: "auto",
            },
        },
        horizontalContainer: {
            height: "100%",
        },
        horizontalPrimary: {
            minHeight: "50vh",
            [theme.breakpoints.up("md")]: {
                minHeight: "auto",
                height: "100%",
            },
        },
        horizontalSecondary: {
            minHeight: "50vh",
            overflowY: "auto",
            borderTop: `1px solid ${theme.palette.grey["300"]}`,
            [theme.breakpoints.up("md")]: {
                minHeight: "auto",
                height: "100%",
                borderTop: "none",
                borderLeft: `1px solid ${theme.palette.grey["300"]}`,
            },
        },
        verticalContainer: {
            height: "100%",
        },
        verticalPrimary: {
            height: "30vh",
            minHeight: "50vh",
            [theme.breakpoints.up("sm")]: {
                minHeight: "auto",
                height: "50%",
            },
        },
        verticalSecondary: {
            height: "auto",
            minHeight: "50vh",
            overflowY: "auto",
            [theme.breakpoints.up("sm")]: {
                minHeight: "auto",
                height: "50%",
            },
        },
    })
);

export enum LayoutMode {
    Fullscreen = 1,
    Horizontal = 2,
    Vertical = 3,
}

interface ILayoutManagerProps extends React.HTMLAttributes<HTMLElement> {
    mode: LayoutMode;
    componentPanel?: ReactNode;
    componentPrimary: ReactNode;
    componentSecondary?: ReactNode;
    stylesProps?: StylesProps;
}

const LayoutManager: React.FC<ILayoutManagerProps> = (props: ILayoutManagerProps) => {
    const { mode, componentPanel, componentPrimary, componentSecondary, stylesProps } = props;

    const classes = useStyles(stylesProps);
    let layout;

    const fullscreenLayout = (
        <Grid container spacing={0} className={classes.fullscreenContainer}>
            {componentPanel && (
                <Grid item xs={12} sm={12} className={classes.controlPanel}>
                    {componentPanel}
                </Grid>
            )}
            <Grid item xs={12} sm={12} className={classes.fullScreenPrimary}>
                {componentPrimary}
            </Grid>
        </Grid>
    );

    switch (mode) {
        case LayoutMode.Fullscreen: {
            layout = fullscreenLayout;
            break;
        }

        case LayoutMode.Horizontal: {
            layout = (
                <Grid container spacing={0} className={classes.horizontalContainer}>
                    {componentPanel && (
                        <Grid item xs={12} sm={12} className={classes.controlPanel}>
                            {componentPanel}
                        </Grid>
                    )}
                    <Grid item xs={12} md={6} lg={7} xl={8} className={classes.horizontalPrimary}>
                        {componentPrimary}
                    </Grid>
                    <Grid item xs={12} md={6} lg={5} xl={4} className={classes.horizontalSecondary}>
                        {componentSecondary}
                    </Grid>
                </Grid>
            );
            break;
        }

        case LayoutMode.Vertical: {
            layout = (
                <Grid container spacing={0} className={classes.verticalContainer}>
                    {componentPanel && (
                        <Grid item xs={12} sm={12} className={classes.controlPanel}>
                            {componentPanel}
                        </Grid>
                    )}
                    <Grid item xs={12} sm={12} className={classes.verticalPrimary}>
                        {componentPrimary}
                    </Grid>
                    <Grid item xs={12} sm={12} className={classes.verticalSecondary}>
                        {componentSecondary}
                    </Grid>
                </Grid>
            );
            break;
        }

        default: {
            layout = fullscreenLayout;
            break;
        }
    }

    return <>{layout}</>;
};

export default LayoutManager;
