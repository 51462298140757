import React, { useEffect, useRef, useState } from "react";
import {
    CreateProjectFileVersionSensorMutationVariables,
    ProjectFileVersionSensor,
    SensorGraph,
    SensorModelStock,
    UpdateProjectFileVersionSensorMutationVariables,
    useCreateProjectFileVersionSensorMutation,
    useDeleteProjectFileVersionSensorMutation,
    useGetProjectFileVersionSensorsLazyQuery,
    useGetProjectFileVersionSensorsQuery,
    useUpdateProjectFileVersionSensorMutation,
} from "../../graphql/generated/graphql";
import SensorsInViewer, { ISensorBoxInfo, ISensorListBoxInfo } from "./SensorsInViewer";

interface IProjectFileVersionSensors {
    projectFileVersionID: number;
    viewer: Autodesk.Viewing.Viewer3D;
    viewable: any;
}

const ProjectFileVersionSensors: React.FC<IProjectFileVersionSensors> = (props: IProjectFileVersionSensors) => {
    const { projectFileVersionID, viewer, viewable } = props;

    const [sensorBoxesInfo, setSensorBoxesInfo] = useState<ISensorBoxInfo[]>([]);

    const [
        getProjectFileVersionSensorsLazyQuery,
        {
            data: projectFileVersionSensorsData,
            loading: projectFileVersionSensorsLoading,
            error: projectFileVersionSensorsError,
        },
    ] = useGetProjectFileVersionSensorsLazyQuery({
        fetchPolicy: "network-only",
        variables: {
            projectFileVersionID: projectFileVersionID,
        },
    });

    const [createProjectFileVersionSensorMutation] = useCreateProjectFileVersionSensorMutation({
        refetchQueries: ["getProjectFileVersionSensors", "getSensorModelStocksByType"],
    });
    const [updateProjectFileVersionSensorMutation] = useUpdateProjectFileVersionSensorMutation({
        refetchQueries: ["getProjectFileVersionSensors", "getSensorModelStocksByType"],
    });

    const [deleteProjectFileVersionSensorMutation] = useDeleteProjectFileVersionSensorMutation({
        refetchQueries: ["getProjectFileVersionSensors", "getSensorModelStocksByType"],
    });

    useEffect(() => {
        fetchSensors();
        let postionsFetchTimer = setInterval(fetchSensors, 4000);
        return () => {
            clearInterval(postionsFetchTimer);
        };
    }, []);

    const fetchSensors = () => {
        getProjectFileVersionSensorsLazyQuery();
    };

    // render sensors
    useEffect(() => {
        if (viewer) {
            if (projectFileVersionSensorsData) {
                const sensorBoxesList: ISensorBoxInfo[] = [];

                projectFileVersionSensorsData?.projectFileVersionSensorsWithLastGraphsType?.projectFileVersionSensors?.forEach(
                    sensor => {
                        const pos = sensor?.sensorLocationExternals?.find(l => l?.externalSystem?.code === "forge")
                            ?.vector;
                        sensorBoxesList.push({
                            sensor: sensor as ProjectFileVersionSensor,
                            worldCoords: pos,
                            lastSensorValue: projectFileVersionSensorsData?.projectFileVersionSensorsWithLastGraphsType?.lastSensorGraphs?.find(
                                sg => sg?.mqqtCode === sensor?.sensorModelStocks?.mqqtCode
                            ) as SensorGraph,
                        });
                    }
                );

                setSensorBoxesInfo(sensorBoxesList);
            }
        }
    }, [projectFileVersionSensorsData, viewer]);

    const onPushpinCreated = async (sensorModel: SensorModelStock, position: any /*Vector3*/) => {
        const dataToSend: CreateProjectFileVersionSensorMutationVariables = {
            projectFileVersionID: 1, // todo
            sensorModelStockID: sensorModel.sensorModelStockID,
            sensorGraphColorID: 12, // todo
            x: position.x,
            y: position.y,
            z: position.z,
        } as CreateProjectFileVersionSensorMutationVariables;

        await createProjectFileVersionSensorMutation({
            variables: dataToSend,
        })
            .then(sensorData => {
                console.log("sensorData");
                console.log(sensorData);
            })
            .catch(sensorData => {
                console.log("sensorData");
                console.log(sensorData);
            });
    };

    const onPushpinModified = async (
        projectFileVersionSensor: ProjectFileVersionSensor,
        position: any /*Vector3*/,
        displayGraph: boolean | undefined = undefined,
        displayName: undefined = undefined
    ) => {
        const dataToSend: UpdateProjectFileVersionSensorMutationVariables = {
            projectFileVersionSensorID: projectFileVersionSensor?.projectFileVersionSensorID,
            projectFileVersionID: projectFileVersionSensor?.projectFileVersion?.projectFileVersionID,
            sensorModelStockID: projectFileVersionSensor?.sensorModelStocks?.sensorModelStockID,
            sensorGraphColorID: projectFileVersionSensor?.sensorGraphColors?.sensorGraphColorID,
            displayGraph: displayGraph !== undefined ? displayGraph : projectFileVersionSensor?.displayGraph,
            displayName: displayName !== undefined ? displayName : projectFileVersionSensor?.displayName,
            vector: position,
        } as UpdateProjectFileVersionSensorMutationVariables;

        await updateProjectFileVersionSensorMutation({
            variables: dataToSend,
        })
            .then(sensorData => {
                console.log("sensorData");
                console.log(sensorData);
            })
            .catch(sensorData => {
                console.log("sensorData");
                console.log(sensorData);
            });
    };

    const onPushpinRemove = async (projectFileVersionSensor: ProjectFileVersionSensor) => {
        await deleteProjectFileVersionSensorMutation({
            variables: {
                projectFileVersionSensorID: `${projectFileVersionSensor.projectFileVersionSensorID}`,
            },
        })
            .then(sensorData => {
                console.log("sensorData");
                console.log(sensorData);
            })
            .catch(sensorData => {
                console.log("sensorData");
                console.log(sensorData);
            });
    };

    return (
        <SensorsInViewer
            sensorBoxes={sensorBoxesInfo}
            viewer={viewer}
            viewable={viewable}
            loading={projectFileVersionSensorsLoading}
            error={projectFileVersionSensorsError}
            onPushpinCreated={onPushpinCreated}
            onPushpinModified={onPushpinModified}
            onPushpinRemove={onPushpinRemove}
        />
    );
};
export default ProjectFileVersionSensors;
