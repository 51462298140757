import { Box, createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            background: "rgba(0, 0, 0, 0.4)",
            color: theme.palette.common.white,
            padding: theme.spacing(0.5),
            fontSize: "0.7em",
            textAlign: "center",
            position: "absolute",
            transform: "translate(-50%, 50%)",
            "& p": {
                margin: 0,
                padding: 0,
                lineHeight: "1.4em",
            },
        },
    })
);

interface IPushpinNameProps {
    name: string;
}

const PushpinName: React.FC<IPushpinNameProps> = (props: IPushpinNameProps) => {
    const { name } = props;
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <p>{name}</p>
        </Box>
    );
};

export default PushpinName;
