import { Box, createStyles, IconButton, Theme, Typography } from "@material-ui/core";
import { addNotification, INotification } from "@iolabs/notifier";
import { DispatchAction } from "@iolabs/redux-utils";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useDispatch } from "react-redux";
import { SensorModelStock, useDeleteSensorModelMutation } from "../../../graphql/generated/graphql";
import { getSensorModelStockName } from "../../Sensor/utils";
import { Path } from "../../../pages/PageRouter/PageRouter";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            background: theme.palette.grey["50"],
            borderRadius: theme.shape.borderRadius,
            flex: "100%",
            [theme.breakpoints.up("sm")]: {
                flex: "50%",
            },
        },
        imageBox: {
            padding: theme.spacing(0.6),
            paddingBottom: 0,
            "& div": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "80px",
                height: "80px",
                "& span": {
                    fontSize: theme.typography.pxToRem(50),
                    color: theme.palette.grey["300"],
                },
            },

            "& img": {
                border: `1px solid ${theme.palette.grey["500"]}`,
                borderRadius: theme.shape.borderRadius,
                maxWidth: "80px",
                height: "auto",
            },
        },
        sensorBox: {
            flex: 1,
            padding: theme.spacing(0.6),
        },
        buttonBox: {
            textAlign: "right",
            marginTop: "-15px",
        },
        iconButton: {
            "& svg": {
                fontSize: "1.5rem",
            },
        },
    })
);

interface IAssemblyCoreProps {
    index: number;
    sensorModelStock: SensorModelStock;
}

const AssemblyCore: React.FC<IAssemblyCoreProps> = (props: IAssemblyCoreProps) => {
    const { index, sensorModelStock } = props;
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();

    const [deleteAssemblyMutation] = useDeleteSensorModelMutation({
        refetchQueries: ["getProjectFileVersionSensors", "getSensorModelStocksByType"],
    });

    const deleteAssembly = async () => {
        await deleteAssemblyMutation({
            variables: {
                sensorModelID: `${sensorModelStock?.sensorModel?.sensorModelID}`,
            },
        })
            .then(result => {
                const notification: INotification = {
                    key: 1,
                    variant: "success",
                    // todo localization
                    message: "Assembly deleted successfully",
                };
                dispatch(addNotification({ notification }));
            })
            .catch(result => {
                let message;
                if (result.graphQLErrors.find(e => e.extensions.code === "PROJECT_FILE_VERSION_SENSORS_FOUND")) {
                    // todo localization
                    message = "This assembly is already attached to model, please delete the assignment first.";
                } else {
                    message = result.graphQLErrors.map(e => e.message).join("\n");
                }
                const notification: INotification = {
                    key: 1,
                    variant: "error",
                    message: message,
                };
                dispatch(addNotification({ notification }));
            });
    };

    return (
        <Box className={classes.root} key={index}>
            <Box className={classes.imageBox}>
                <div>
                    <span>A{index + 1}</span>
                </div>
                {/*<img*/}
                {/*    src={`${config.api.baseUrl}/forge/remotefiles/data:${encodeBase64(*/}
                {/*        sensorModelStock?.sensorModel?.thumbnailImage as string*/}
                {/*    )}.jpg`}*/}
                {/*    alt={sensorModelStock?.name as string}*/}
                {/*/>*/}
            </Box>
            <Box className={classes.sensorBox}>
                <Typography variant="body1">
                    <strong>{getSensorModelStockName(sensorModelStock) as string}</strong>
                </Typography>
                <Typography variant="body1">{sensorModelStock?.sensorModel?.description}</Typography>
                {/*<Typography variant="body1">- Sensor 1</Typography>*/}
                {/*<Typography variant="body1">- Sensor 2</Typography>*/}
                {/*<Typography variant="body1">- 2 Objects</Typography>*/}

                <Box className={classes.buttonBox}>
                    <IconButton
                        aria-label="delete"
                        size="small"
                        color="primary"
                        onClick={deleteAssembly}
                        className={classes.iconButton}
                    >
                        <DeleteIcon fontSize="inherit" />
                    </IconButton>
                    <Link to={`${Path.EDITOR}/${sensorModelStock?.sensorModel?.sensorAssembly?.sensorAssemblyID}`}>
                        <IconButton
                            aria-label="edit"
                            size="small"
                            color="primary"
                            className={classes.iconButton}
                            // onClick={() => setGlobalDialogOpen(true)}
                        >
                            <EditIcon fontSize="inherit" />
                        </IconButton>
                    </Link>
                </Box>
            </Box>
        </Box>
    );
};

export default AssemblyCore;
