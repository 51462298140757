import { ActionTypes } from "./action";
import { AnyAction } from "redux";

export interface ILayoutModuleState {
    layoutState: ILayoutState;
}

export interface ILayoutState {
    drawer: boolean;
}

export const initialState: Readonly<ILayoutState> = {
    drawer: false,
};

export function reducer(state: ILayoutState = initialState, action: AnyAction): ILayoutState {
    switch (action.type) {
        case ActionTypes.OnDrawer: {
            const { drawer } = action.payload;
            return {
                ...state,
                drawer,
            };
        }

        default:
            return state;
    }
}
