import { Box, createStyles, Link, Theme, Toolbar, Tooltip } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";
import { useIntl } from "react-intl";
import defaultLogo from "../../assets/images/iolabs-logo.png";
import config from "../../config/config";
import messages from "./messages";

const defaultFooterHeight: string = "20px";

type StylesProps = {
    footerHeight?: string;
    footerBarColor?: string;
    footerBarBackground?: string;
    footerContentBackground?: string;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: (props: StylesProps | undefined) => ({
            color: props?.footerBarColor ? props?.footerBarColor : theme.palette.text.primary,
            background: props?.footerBarBackground ? props?.footerBarBackground : theme.palette.common.white,
            minHeight: 0,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            [theme.breakpoints.up("sm")]: {},
            [theme.breakpoints.up("md")]: {
                height: props?.footerHeight ? props?.footerHeight : defaultFooterHeight,
            },
        }),
        content: (props: StylesProps | undefined) => ({
            background: props?.footerContentBackground ? props?.footerContentBackground : theme.palette.common.white,
            flexGrow: 1,
            flexDirection: "column",
            alignItems: "center",
            padding: "0 .4rem",
            [theme.breakpoints.up("md")]: {
                flexDirection: "row",
                flexWrap: "wrap",
                height: props?.footerHeight ? props?.footerHeight : defaultFooterHeight,
            },
            [theme.breakpoints.up("lg")]: {
                alignItems: "flex-start",
                height: props?.footerHeight ? props?.footerHeight : defaultFooterHeight,
            },
        }),
        paragraph: (props: StylesProps | undefined) => ({
            fontSize: "0.7rem",
            lineHeight: ".8rem",
            [theme.breakpoints.up("md")]: {
                height: props?.footerHeight ? props?.footerHeight : defaultFooterHeight,
            },
        }),
        logo: {
            display: "inline-block",
            "& img": {
                marginTop: ".2rem",
                height: ".8rem",
            },
        },
        copyLogo: {
            marginTop: ".2rem",
            [theme.breakpoints.up("md")]: {
                order: 2,
                alignSelf: "center",
                marginTop: 0,
            },
        },
        copyUpdate: {
            textAlign: "center",
            paddingTop: "2px",
            paddingBottom: "4px",
            [theme.breakpoints.up("md")]: {
                order: 3,
                alignSelf: "flex-end",
                textAlign: "right",
                width: "43%",
                paddingTop: "4px",
                paddingBottom: 0,
            },
        },
        copyIolabs: {
            textAlign: "center",
            paddingTop: "2px",
            paddingBottom: "4px",
            [theme.breakpoints.up("md")]: {
                order: 1,
                alignSelf: "flex-start",
                textAlign: "left",
                width: "43%",
                paddingTop: "4px",
                paddingBottom: 0,
            },
        },
        lastUpdate: {
            cursor: "help",
        },
    })
);

interface IFooterProps {
    stylesProps?: StylesProps;
}

const Footer: React.FC<IFooterProps> = (props: IFooterProps) => {
    const { stylesProps } = props;

    const classes = useStyles(stylesProps);

    const getYear = () => {
        return new Date().getFullYear();
    };

    // translations
    const intl = useIntl();
    const transWebsite = intl.formatMessage({ ...messages.website });
    const transUpdate = intl.formatMessage({ ...messages.update });
    const transCopyright = intl.formatMessage(
        { ...messages.copyright },
        {
            year: getYear(),
        }
    );

    const dateLastUpdate = config.build.lastUpdate ? new Date(config.build.lastUpdate) : new Date();

    return (
        <Toolbar className={classes.root}>
            <Box display="flex" justifyContent="space-between" pl={1} pr={1} className={classes.content}>
                <Typography component="div" className={classes.copyLogo}>
                    <Link href={config.content.authorWebUrl} target="_blank" color="inherit" className={classes.logo}>
                        <img alt="Logo" src={defaultLogo} />
                    </Link>
                </Typography>
                <Typography component="p" className={clsx(classes.paragraph, classes.copyUpdate)}>
                    {config.build.lastUpdate && (
                        <>
                            {transUpdate} {` `}
                            <Tooltip title={config.build.lastVersion} placement="top">
                                <Typography
                                    component="span"
                                    variant="inherit"
                                    className={clsx(classes.paragraph, classes.lastUpdate)}
                                >
                                    {`${dateLastUpdate.toLocaleDateString("de-DE")} ${dateLastUpdate.toLocaleTimeString(
                                        "de-DE"
                                    )}`}
                                </Typography>
                            </Tooltip>
                        </>
                    )}
                </Typography>
                <Typography component="p" className={clsx(classes.paragraph, classes.copyIolabs)}>
                    <Link href={config.content.authorWebUrl} target="_blank" color="inherit">
                        {transCopyright}
                    </Link>
                    {` | `}
                    <Link href={config.content.authorWebUrl} target="_blank" color="inherit">
                        {transWebsite}
                    </Link>
                </Typography>
            </Box>
        </Toolbar>
    );
};

export default Footer;
