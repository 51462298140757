import { IModule } from "redux-dynamic-modules";
import { IModelModuleState, reducer } from "./reducer";
import middlewares from "./middleware";

export const ModelModule: IModule<IModelModuleState> = {
    id: "model-module",
    reducerMap: {
        modelState: reducer,
    },
    middlewares: middlewares,
};
