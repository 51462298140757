import { AppModule } from "@iolabs/app";
import { KeycloakModule } from "@iolabs/keycloak";
import { NotifierModule } from "@iolabs/notifier";
import React, { ReactNode } from "react";
import { Provider } from "react-redux";
import { compose } from "redux";
import { createStore, IModuleStore } from "redux-dynamic-modules";
import { getThunkExtension } from "redux-dynamic-modules-thunk";
import config from "../config/config";
import { LayoutModule } from "./layout/module";
import { ModelModule } from "./model/module";

// add Redux debugging tool as Chrome extension when development environment is active
const composeEnhancers =
    config.envType === "development" ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

export interface IState {
    // appModule: IAppModuleState;
}

const store: IModuleStore<any> = createStore(
    {
        initialState: {
            // appModule: {
            //     appState: {}
            // },
        },
        advancedComposeEnhancers: composeEnhancers,
        extensions: [getThunkExtension()],
    },
    AppModule,
    NotifierModule,
    KeycloakModule,
    LayoutModule,
    ModelModule
);

type Props = {
    children: ReactNode;
};

export function StoreProvider(props: Props): JSX.Element {
    const { children } = props;
    return <Provider store={store}>{children}</Provider>;
}
