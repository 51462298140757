import { Notifier } from "@iolabs/notifier";
import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { SnackbarProvider } from "notistack";
import React from "react";
import { useKeycloak } from "react-keycloak";
import Loading from "../Loading/Loading";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        success: {
            backgroundColor: theme.palette.secondary.main,
        },
        error: {
            backgroundColor: theme.palette.error.main,
        },
        warning: {
            backgroundColor: theme.palette.error.main,
        },
        info: {
            backgroundColor: theme.palette.primary.main,
        },
    })
);

interface IProps {
    children?: any;
    globalCss: React.ReactNode;
}

const App: React.FC<IProps> = (props: IProps) => {
    const { children, globalCss } = props;

    const classes = useStyles();
    const { initialized } = useKeycloak();

    return (
        <>
            {!initialized ? (
                <Loading />
            ) : (
                <SnackbarProvider
                    maxSnack={7}
                    classes={{
                        variantSuccess: classes.success,
                        variantError: classes.error,
                        variantWarning: classes.warning,
                        variantInfo: classes.info,
                    }}
                >
                    {globalCss}
                    {children}
                    <Notifier autoHideDuration={7000} />
                </SnackbarProvider>
            )}
        </>
    );
};

export default App;
