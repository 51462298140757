import { createAction, DispatchAction } from "@iolabs/redux-utils";

export enum ActionTypes {
    OnDrawer = "layout/ON_DRAWER",
}

export type DrawerOptions = {
    drawer: boolean;
};

const Actions = {
    onDrawer: (options: DrawerOptions) => createAction(ActionTypes.OnDrawer, options),
};

export function onDrawer(options: DrawerOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.onDrawer(options));
    };
}
