import { defineMessages } from "react-intl";

export default defineMessages({
    optionsMove: {
        id: "components__pushpin-popover__options--move",
        defaultMessage: "Move",
    },
    optionsRemove: {
        id: "components__pushpin-popover__options--remove",
        defaultMessage: "Remove",
    },
    optionsName: {
        id: "components__pushpin-popover__options--name",
        defaultMessage: "Show name",
    },
    optionsLabel: {
        id: "components__pushpin-popover__options--label",
        defaultMessage: "Options",
    },
    optionsGraph: {
        id: "components__pushpin-popover__options--graph",
        defaultMessage: "Show graph",
    },
    optionsColor: {
        id: "components__pushpin-popover__options--color",
        defaultMessage: "Set color",
    },
});
