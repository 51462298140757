import React from "react";
import { useGetSensorTypesQuery } from "../../graphql/generated/graphql";
import Inventory from "./Inventory";

interface IInventoryWrapperProps {
    inventory: string[];
    handleInitInventory: (initInventory: string[]) => void;
    handleChangeInventory: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InventoryWrapper: React.FC<IInventoryWrapperProps> = (props: IInventoryWrapperProps) => {
    const { inventory, handleInitInventory, handleChangeInventory } = props;

    const { data: sensorTypesData, loading: sensorTypesLoading, error: sensorTypesError } = useGetSensorTypesQuery({
        variables: {},
    });

    return (
        <Inventory
            inventory={inventory}
            handleInitInventory={handleInitInventory}
            handleChangeInventory={handleChangeInventory}
            data={sensorTypesData}
            loading={sensorTypesLoading}
            error={sensorTypesError}
        />
    );
};

export default InventoryWrapper;
