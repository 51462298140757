import { Box, createStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useGetSensorModelStocksByTypeQuery } from "../../graphql/generated/graphql";
import SensorList from "./SensorList";

const useStyles = makeStyles(() =>
    createStyles({
        root: {},
    })
);

export enum SensorType {
    TEMPERATURE = "1",
    HUMIDITY = "2",
    CO2 = "3",
    LOCALIZATION = "4",
    ASSEMBLY = "5",
}

export const getSensorTypeId = (sensorType: SensorType) => parseInt(sensorType);

interface ISensorWrapperProps {
    inventory: string[];
}

const SensorWrapper: React.FC<ISensorWrapperProps> = (props: ISensorWrapperProps) => {
    const { inventory } = props;
    const classes = useStyles();

    const {
        data: sensorModelsData,
        loading: sensorModelsLoading,
        error: sensorModelsError,
    } = useGetSensorModelStocksByTypeQuery({
        variables: {
            typeIds: inventory,
        },
        fetchPolicy: "cache-and-network",
    });

    return (
        <Box className={classes.root}>
            {inventory.length !== 0 && (
                <SensorList
                    data={sensorModelsData}
                    loading={sensorModelsLoading}
                    error={sensorModelsError}
                    showActualValue
                />
            )}
        </Box>
    );
};

export default SensorWrapper;
