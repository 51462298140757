import { DispatchAction } from "@iolabs/redux-utils";
import { Box, createStyles, IconButton, Theme, Typography } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import config from "../../config/config";
import {
    SensorGraph,
    SensorModel,
    SensorModelStock,
    useGetSensorGraphsLazyQuery,
} from "../../graphql/generated/graphql";
import { onSelectSensorToAdd, useUsedItemsInAssembly } from "../../redux/model";
import { encodeBase64 } from "../../utils/Coding";
import { SensorType } from "./SensorWrapper";
import { getFormattedSensorValue } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "relative",
            display: "flex",
            background: theme.palette.grey["50"],
            borderRadius: theme.shape.borderRadius,
            flex: "100%",
            [theme.breakpoints.up("sm")]: {
                flex: "50%",
            },
        },
        imageBox: {
            padding: theme.spacing(0.6),
            paddingBottom: 0,
            "& img": {
                border: `1px solid ${theme.palette.grey["500"]}`,
                borderRadius: theme.shape.borderRadius,
                maxWidth: "80px",
                height: "auto",
            },
            "& div": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "80px",
                height: "80px",
                "& span": {
                    fontSize: theme.typography.pxToRem(50),
                    color: theme.palette.grey["300"],
                },
            },
        },
        sensorBox: {
            flex: 1,
            padding: theme.spacing(0.6),
        },
        buttonBox: {
            position: "absolute",
            right: theme.spacing(0.25),
            bottom: theme.spacing(0.25),
        },
        iconButton: {
            "& svg": {
                fontSize: "1.5rem",
                zIndex: 10,
            },
        },
    })
);

interface ISensorCoreProps {
    sensorModelStock?: SensorModelStock;
    showActualValue?: boolean;
    typeIndex?: number;
}

const SensorCore: React.ComponentType<ISensorCoreProps & React.HTMLProps<HTMLButtonElement>> = (
    props: ISensorCoreProps
) => {
    const { sensorModelStock, showActualValue, typeIndex } = props;
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();
    const usedItemsInAssembly = useUsedItemsInAssembly();

    const [
        getSensorGraphsQuery,
        { data: sensorGraphsData, loading: sensorGraphsLoading, error: sensorGraphsError },
    ] = useGetSensorGraphsLazyQuery();

    const handleSensorAdd = sensor => {
        dispatch(onSelectSensorToAdd({ sensor: sensor }));
    };

    useEffect(() => {
        if (showActualValue) {
            getSensorGraphsQuery({
                variables: {
                    mqqtCode: sensorModelStock?.mqqtCode as string,
                    take: 1,
                    descending: true,
                },
            });
        }
    }, [showActualValue, getSensorGraphsQuery]);

    const isSensorAvailable = () => {
        if (sensorModelStock?.projectFileVersionSensors?.length) {
            // is in model
            return false;
        }
        if (sensorModelStock?.sensorAssemblyItems?.length) {
            // is in assembly
            return false;
        }
        if (
            usedItemsInAssembly &&
            usedItemsInAssembly?.find(item => item.sensorModelStockID === sensorModelStock?.sensorModelStockID)
        ) {
            // is in currently edited assembly
            return false;
        }
        if (
            sensorModelStock?.sensorModel?.isLocalizationSensor &&
            usedItemsInAssembly &&
            usedItemsInAssembly?.find(item => item?.sensorModel?.isLocalizationSensor)
        ) {
            // currently edited assembly has other localization point
            return false;
        }
        return true;
    };

    return (
        <Box className={clsx(classes.root)}>
            <Box className={classes.imageBox}>
                {sensorModelStock?.sensorModel?.sensorAssembly ? (
                    <div>
                        <span>A{typeIndex}</span>
                    </div>
                ) : (
                    <img
                        src={`${config.api.baseUrl}/forge/remotefiles/data:${encodeBase64(
                            sensorModelStock?.sensorModel?.thumbnailImage as string
                        )}.jpg`}
                        alt={sensorModelStock?.name as string}
                    />
                )}
            </Box>
            <Box className={classes.sensorBox}>
                <Typography variant="body1">
                    <strong>{sensorModelStock?.name}</strong>
                </Typography>
                {sensorGraphsData &&
                    sensorModelStock?.sensorModel?.sensorType?.sensorTypeID !== parseInt(SensorType.LOCALIZATION) && (
                        <Typography variant="body1">
                            {getFormattedSensorValue(
                                sensorGraphsData?.sensorGraphs?.[0] as SensorGraph,
                                sensorModelStock?.sensorModel as SensorModel
                            )}
                        </Typography>
                    )}
                <Typography variant="body1">{sensorModelStock?.sensorModel?.manufacturer}</Typography>
                <Typography variant="body1">{sensorModelStock?.sensorModel?.price} CHF</Typography>

                <Box className={classes.buttonBox}>
                    {isSensorAvailable() ? (
                        <IconButton
                            aria-label="add"
                            size="small"
                            color={sensorModelStock?.sensorModel?.isLocalizationSensor ? "secondary" : "primary"}
                            draggable={true}
                            onDragStart={() => handleSensorAdd(sensorModelStock)}
                            className={classes.iconButton}
                        >
                            <AddCircleIcon fontSize="inherit" />
                        </IconButton>
                    ) : (
                        <IconButton aria-label="add" size="small" disabled={true} className={classes.iconButton}>
                            <AddCircleIcon fontSize="inherit" />
                        </IconButton>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default SensorCore;
