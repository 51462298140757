const getAllModelIdsInternal = (instanceTree: Autodesk.Viewing.InstanceTree): number[] => {
    let allDbIdsStr: string[] = Object.keys(instanceTree.nodeAccess.dbIdToIndex);
    return allDbIdsStr.map(function(id) {
        return parseInt(id);
    });
};

export const getAllModelIds = (
    viewer: Autodesk.Viewing.Viewer3D,
    model?: Autodesk.Viewing.Model
): Promise<number[]> => {
    if (!model) {
        model = viewer.model;
    }

    return new Promise<number[]>(async resolve => {
        let instanceTree = model?.getInstanceTree();
        if (instanceTree) {
            resolve(getAllModelIdsInternal(instanceTree));
        } else {
            const instanceTreeCreatedListener = async () => {
                instanceTree = viewer.model.getInstanceTree();
                resolve(getAllModelIdsInternal(instanceTree));
                viewer.removeEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, instanceTreeCreatedListener);
            };
            viewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, instanceTreeCreatedListener);
        }
    });
};
