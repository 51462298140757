import { DispatchAction } from "@iolabs/redux-utils";
import { Box, Button, createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import imgGeom1 from "../../../assets/images/geom-1.png";
import imgGeom2 from "../../../assets/images/geom-2.png";
import imgGeom3 from "../../../assets/images/geom-3.png";
import imgGeom4 from "../../../assets/images/box2.jpg";
import chairGeom from "../../../assets/images/chair.png";
import { ProjectFile, ProjectFileVersion } from "../../../graphql/generated/graphql";
import { onSelectGeometryToAdd, onSelectSensorToAdd } from "../../../redux/model";
import { useDispatch } from "react-redux";

export const defaultObjectSize: number = 90;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            textAlign: "center",
        },
        button: {
            padding: theme.typography.pxToRem(6),
        },
        imageBox: {
            padding: 0,
            textAlign: "center",
            height: defaultObjectSize,
            width: defaultObjectSize,
            "& img": {
                border: `1px solid ${theme.palette.grey["500"]}`,
                borderRadius: theme.shape.borderRadius,
                maxWidth: "100%",
                height: defaultObjectSize,
            },
        },
    })
);

interface IGeometricalCoreProps {
    projectFile: ProjectFile;
}

const GeometricalCore: React.FC<IGeometricalCoreProps> = (props: IGeometricalCoreProps) => {
    const { projectFile } = props;
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();

    // TODO: Geometrical images for files missing (Jakub Jirous 22. 7. 2020)

    const onDragStart = e => {
        const projectFileVersions: ProjectFileVersion[] = projectFile?.projectFileVersions as ProjectFileVersion[];
        const lastVersion = projectFileVersions[0];
        dispatch(onSelectGeometryToAdd({ geometry: lastVersion }));
    };

    return (
        <Box className={classes.root}>
            <Button variant="contained" className={classes.button} draggable={true} onDragStart={onDragStart}>
                <Box className={classes.imageBox}>
                    {/*{projectFile?.name as string}*/}

                    <img
                        src={
                            (projectFile?.name as string) === "Pyramid"
                                ? imgGeom1
                                : (projectFile?.name as string) === "Cylinder"
                                ? imgGeom2
                                : (projectFile?.name as string) === "Armchair"
                                ? chairGeom
                                : (projectFile?.name as string) === "Box 2"
                                ? imgGeom3
                                : imgGeom4
                        }
                        alt={projectFile?.name as string}
                    />
                </Box>
            </Button>
        </Box>
    );
};

export default GeometricalCore;
