import {
    Box,
    createStyles,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Theme,
    Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useIntl } from "react-intl";
import { useGetProjectFilesQuery } from "../../../graphql/generated/graphql";
import { FileType } from "../Editor";
import messages from "../messages";
import GeometricalList from "./GeometricalList";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        panel: {
            borderBottom: `1px solid ${theme.palette.grey["300"]}`,
        },
        summary: {
            paddingLeft: 0,
            paddingRight: theme.spacing(1),
        },
        expandedSummary: {
            minHeight: 0,
            marginTop: 0,
            marginBottom: 0,
        },
        details: {
            padding: `0 0 ${theme.spacing(2)} 0`,
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
    })
);

interface IGeometricalWrapperProps {
    fileType: FileType;
}

const GeometricalWrapper: React.FC<IGeometricalWrapperProps> = (props: IGeometricalWrapperProps) => {
    const classes = useStyles();
    const { fileType } = props;

    const { data: geometricalData, loading: geometricalLoading, error: geometricalError } = useGetProjectFilesQuery({
        variables: {
            fileTypeID: fileType,
        },
    });

    // translations
    const intl = useIntl();
    const transGeometrical = intl.formatMessage({ ...messages.geometricalPanel });

    return (
        <Box className={classes.root}>
            <ExpansionPanel square elevation={0} className={classes.panel}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="geometrical-objects-panel"
                    id="geometrical-objects-header"
                    classes={{ root: classes.summary, expanded: classes.expandedSummary }}
                >
                    <Typography className={classes.heading}>{transGeometrical}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails classes={{ root: classes.details }}>
                    <GeometricalList data={geometricalData} loading={geometricalLoading} error={geometricalError} />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </Box>
    );
};

export default GeometricalWrapper;
