import { Box, createStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import { ApolloError } from "apollo-client";
import React, { useEffect } from "react";
import {
    ProjectFileVersionSensor,
    SensorGraphColor,
    UpdateProjectFileVersionSensorMutationVariables,
    useUpdateProjectFileVersionSensorMutation,
} from "../../graphql/generated/graphql";
import PushpinGraph from "./PushpinGraph";
import PushpinName from "./PushpinName";
import PushpinPopover from "./PushpinPopover";
import { getProjectFileVersionSensorName } from "../Sensor/utils";

export const pushpinSize: number = 20;

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            position: "absolute",
            pointerEvents: "all",
            transform: "translate(-50%, -50%)",
            zIndex: 20,
            width: pushpinSize,
            height: pushpinSize,
            borderRadius: "100%",
            cursor: "pointer",
            borderWidth: "3px",
            borderStyle: "solid",
        },
        skeletonBox: {},
        skeletonItem: {},
    })
);

export type PushpinOptions = {
    showName: boolean;
    showGraph: boolean;
};

interface IPushpinWrapperProps {
    sensor: ProjectFileVersionSensor;
    handleDrag?: (projectFileVersionSensor: ProjectFileVersionSensor) => void;
    handleDelete?: (projectFileVersionSensor: ProjectFileVersionSensor) => void;
    dragDisabled?: boolean;
    deleteDisabled?: boolean;
    loading?: boolean;
    error?: ApolloError | undefined;
    defaultColor: SensorGraphColor;
}

const PushpinWrapper: React.FC<IPushpinWrapperProps> = (props: IPushpinWrapperProps) => {
    const { sensor, handleDrag, handleDelete, loading, error, defaultColor, dragDisabled, deleteDisabled } = props;
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [options, setOptions] = React.useState<PushpinOptions>({
        showName: sensor?.displayName as boolean,
        showGraph: sensor?.displayGraph as boolean,
    });
    const [pushpinColor, setPushpinColor] = React.useState<SensorGraphColor>(
        sensor?.sensorGraphColors !== null ? sensor?.sensorGraphColors : defaultColor
    );

    const [updateProjectFileVersionSensorMutation] = useUpdateProjectFileVersionSensorMutation({
        refetchQueries: ["getProjectFileVersionSensors", "getSensorModelStocksByType"],
    });

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newOptions = { ...options, [event.target.name]: event.target.checked };
        setOptions(newOptions);
        updateProject(newOptions, null);
    };

    const handleChangeColor = (color: SensorGraphColor) => {
        setPushpinColor(color);
        updateProject(null, color);
    };

    const updateProject = async (options, pushpinColor) => {
        const variables: UpdateProjectFileVersionSensorMutationVariables = {
            projectFileVersionSensorID: sensor?.projectFileVersionSensorID,
            projectFileVersionID: sensor?.projectFileVersion?.projectFileVersionID,
            sensorModelStockID: sensor?.sensorModelStocks?.sensorModelStockID,
            displayGraph: options?.showGraph ?? sensor.displayGraph,
            displayName: options?.showName ?? sensor.displayName,
            sensorGraphColorID: pushpinColor?.sensorGraphColorID ?? sensor?.sensorGraphColors?.sensorGraphColorID,
            vector: sensor?.sensorLocationExternals?.[0]?.vector,
        } as UpdateProjectFileVersionSensorMutationVariables;

        await updateProjectFileVersionSensorMutation({
            variables: variables,
        })
            .then(() => {
                // console.log("sensorData");
                // console.log(sensorData);
            })
            .catch(() => {
                // console.log("sensorData");
                // console.log(sensorData);
            });
    };

    // useEffect(() => {
    //     async function updateProject() {
    //         const variables: UpdateProjectFileVersionSensorMutationVariables = {
    //             projectFileVersionSensorID: sensor?.projectFileVersionSensorID,
    //             projectFileVersionID: sensor?.projectFileVersion?.projectFileVersionID,
    //             sensorModelStockID: sensor?.sensorModelStocks?.sensorModelStockID,
    //             displayGraph: options?.showGraph,
    //             displayName: options?.showName,
    //             sensorGraphColorID: pushpinColor?.sensorGraphColorID,
    //             vector: sensor?.sensorLocationExternals?.[0]?.vector,
    //         } as UpdateProjectFileVersionSensorMutationVariables;
    //
    //         debugger
    //         await updateProjectFileVersionSensorMutation({
    //             variables: variables,
    //         })
    //             .then(() => {
    //                 // console.log("sensorData");
    //                 // console.log(sensorData);
    //             })
    //             .catch(() => {
    //                 // console.log("sensorData");
    //                 // console.log(sensorData);
    //             });
    //     }
    //
    //     updateProject();
    // }, [options, /*sensor, */pushpinColor]);

    const open = Boolean(anchorEl);
    const id = open ? "pushpin-popover" : undefined;

    return (
        <>
            {(!loading || !error) && (
                <Box>
                    <Box
                        className={classes.root}
                        aria-describedby={id}
                        onClick={handleClick}
                        style={{
                            backgroundColor: `${pushpinColor?.color as string}50`,
                            borderColor: pushpinColor?.color as string,
                        }}
                    />
                    {options.showGraph && <PushpinGraph pushpinColor={pushpinColor} sensor={sensor} />}
                    {options.showName && <PushpinName name={getProjectFileVersionSensorName(sensor) as string} />}
                    <PushpinPopover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        options={options}
                        pushpinColor={pushpinColor}
                        handleClose={handleClose}
                        handleChangeOptions={handleChangeOptions}
                        handleChangeColor={handleChangeColor}
                        sensor={sensor}
                        handleDrag={handleDrag}
                        handleDelete={handleDelete}
                        deleteDisabled={deleteDisabled}
                        dragDisabled={dragDisabled}
                    />
                </Box>
            )}

            {(loading || error) && (
                <Box className={classes.skeletonBox}>
                    <Skeleton width={pushpinSize} height={pushpinSize} className={classes.skeletonItem} />
                </Box>
            )}
        </>
    );
};

export default PushpinWrapper;
