import { Box, createStyles, FormControlLabel, Paper, Theme, useTheme } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useIntl } from "react-intl";
import { SensorGraphColor, useGetSensorGraphColorsQuery } from "../../graphql/generated/graphql";
import messages from "./messages";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: "4px",
            "& > span": {
                padding: "5px",
            },
        },
        box: {
            width: "1.1em",
            height: "1.1em",
            borderRadius: "2px",
            marginLeft: "4px",
            marginRight: "7px",
        },
        pickerBox: {
            position: "fixed",
            marginLeft: "-12px",
            marginTop: "10px",
        },
        pickerPaper: {
            display: "flex",
            flexFlow: "row wrap",
            padding: "4px 4px 4px 5px",
            width: "162px",
            borderRadius: "3px",
        },
        pickerItem: {
            width: "21px",
            height: "21px",
            margin: "2px",
            "&:hover": {
                transform: "scale(1.1)",
            },
        },
        skeletonPaper: {
            borderRadius: "3px",
        },
        skeletonBox: {
            display: "flex",
            flexFlow: "row wrap",
            padding: "4px 4px 4px 5px",
            width: "162px",
            borderRadius: "3px",
        },
        skeletonItem: {
            width: "21px",
            height: "21px",
            margin: "2px",
        },
    })
);

interface IPushpinColorPickerProps {
    pushpinColor: SensorGraphColor;
    handleChangeColor: (color: any) => void;
}

const PushpinColorPicker: React.FC<IPushpinColorPickerProps> = (props: IPushpinColorPickerProps) => {
    const { pushpinColor, handleChangeColor } = props;
    const classes = useStyles();
    const theme = useTheme();

    const [showColorPicker, setShowColorPicker] = React.useState<boolean>(false);

    const { data, loading, error } = useGetSensorGraphColorsQuery({
        variables: {},
    });

    const handleToggleColorPicker = () => {
        setShowColorPicker(!showColorPicker);
    };

    const handleCloseColorPicker = () => {
        setShowColorPicker(false);
    };

    // translations
    const intl = useIntl();
    const transOptionsColor = intl.formatMessage({ ...messages.optionsColor });

    return (
        <FormControlLabel
            key="color-picker"
            control={
                <Box className={classes.root}>
                    <Box className={classes.box} style={{ backgroundColor: pushpinColor?.color as string }} />

                    {(!loading || !error) && data && showColorPicker && (
                        <Box onClick={handleCloseColorPicker} className={classes.pickerBox}>
                            <Paper className={classes.pickerPaper}>
                                {data?.sensorGraphColors?.map(sensorGraphColor => (
                                    <Box
                                        key={sensorGraphColor?.sensorGraphColorID as number}
                                        style={{ background: sensorGraphColor?.color as string }}
                                        onClick={() => handleChangeColor(sensorGraphColor)}
                                        className={classes.pickerItem}
                                    />
                                ))}
                            </Paper>

                            {(loading || error) && (
                                <Paper className={classes.skeletonPaper}>
                                    <Box className={classes.skeletonBox}>
                                        {[1, 2, 3, 4, 5, 6].map(item => (
                                            <Skeleton key={item} variant="rect" className={classes.skeletonItem} />
                                        ))}
                                    </Box>
                                </Paper>
                            )}
                        </Box>
                    )}
                </Box>
            }
            label={transOptionsColor}
            onClick={handleToggleColorPicker}
            className={classes.root}
        />
    );
};

export default PushpinColorPicker;
