import { DispatchAction } from "@iolabs/redux-utils";
import { Box, createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    ProjectFileVersion,
    ProjectFileVersionSensor,
    SensorAssembly,
    SensorAssemblyGeometryCreate,
    SensorAssemblyItemCreate,
    SensorAssemblyItemExternal,
    SensorModelStock,
    useGetAssemblyDetailsLazyQuery,
} from "../../graphql/generated/graphql";
import { onAsseblyItemsUsedModel, onSelectGeometryToAdd, useGeometryToAdd } from "../../redux/model";
import { useKeycloak } from "react-keycloak";
import { getAllModelIds } from "./utils/model";
import { loadProjectFileVersion as loadProjectFileVersionHelper } from "./utils/assembly";
import AssemblyForm from "../Editor/Assembly/AssemblyForm";
import Loading from "../Loading/Loading";
import { ExternalSystem } from "./types/type";
import SensorsInViewer, { ISensorBoxInfo } from "./SensorsInViewer";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: 10,
            pointerEvents: "none",
            "&.is-dragging": {
                pointerEvents: "all",
            },
        },
        formHolder: {
            position: "absolute",
            left: "1.2rem",
            top: "1.2rem",
            backgroundColor: "grey",
            borderRadius: "5px",
            width: "30rem",
            height: "25rem",
            color: "white",
            padding: "1.2rem",
        },
        draggable: {
            cursor: "move",
        },
    })
);

export interface IAssemblyEditorProps {
    viewer: Autodesk.Viewing.Viewer3D;
    viewable: any;
    assemblyID?: number;
}

const AssemblyEditor: React.FC<IAssemblyEditorProps> = (props: IAssemblyEditorProps) => {
    const { viewer, viewable, assemblyID } = props;
    const classes = useStyles();

    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    const [isDragging, setIsDragging] = useState<ProjectFileVersion>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [sharedPropertyDbPath, setSharedPropertyDbPath] = useState<string>(); // for caching
    const [sensorAssemblyGeometries, setSensorAssemblyGeometries] = useState<{ [key: number]: any }>([]);
    const [editingSensorAssembly, setEditingSensorAssembly] = useState<SensorAssembly>();
    const [sensorBoxesInfo, setSensorBoxesInfo] = useState<{ [key: number]: ISensorBoxInfo }>({});

    const dispatch = useDispatch<DispatchAction>();
    const geometryToAdd = useGeometryToAdd();

    // lazy query for loading assemblies
    const [
        getAssemblyDetailsQuery,
        { data: assemblyDetailsData, loading: assemblyDetailsLoading, error: assemblyDetailsError },
    ] = useGetAssemblyDetailsLazyQuery();

    useEffect(() => {
        // cache sharedPropertyDbPath
        const path: string = viewable.doc.getFullPath(viewable.findPropertyDbPath());
        setSharedPropertyDbPath(path);
    }, [viewable]);

    useEffect(() => {
        // lock selection of background model
        getAllModelIds(viewer, viewer.model).then(ids => {
            // @ts-ignore
            viewer.lockSelection(ids, true, viewer.model);
        });

        // load transform extension
        import("@iolabs/forge-viewer-transform-extension").then(module => {
            viewer.loadExtension(module.ExtensionID, {
                rotate: false,
            }) /*.then((extension: any) => {
                extension.translateTool.on("transform.translate", onTransformDone)
                setTransformExtension(extension);
            })*/;
        });
    }, []);

    // detect changes of assemblyID
    useEffect(() => {
        if (assemblyID) {
            setIsLoading(true);
            // load assembly info
            getAssemblyDetailsQuery({
                variables: {
                    assemblyID: assemblyID.toString(),
                },
            });
        } else {
            // clear loaded assembly
        }
    }, [assemblyID]);

    // detect changes of loaded assembly details
    useEffect(() => {
        if (assemblyDetailsData) {
            const sensorAssembly: SensorAssembly = (assemblyDetailsData.sensorAssemblies as SensorAssembly[])[0];

            setEditingSensorAssembly(sensorAssembly);

            // geometries
            const tasks: Promise<Autodesk.Viewing.Model>[] = sensorAssembly.sensorAssemblyGeometries?.map(
                sensorAssemblyGeometry => {
                    const storedMatrixString: string = sensorAssemblyGeometry?.sensorAssemblyGeometryExternals?.find(
                        sage => sage?.externalSystem?.code === ExternalSystem.Forge
                    )?.transformationMatrix?.matrix as string;
                    const storedMatrix = JSON.parse(storedMatrixString);

                    // do not await, load parallel
                    return loadProjectFileVersion(
                        sensorAssemblyGeometry?.projectFileVersions as ProjectFileVersion,
                        storedMatrix,
                        sensorAssemblyGeometry?.sensorAssemblyGeometryID as number
                    );
                }
            ) as Promise<Autodesk.Viewing.Model>[];

            // assembly items
            const sensors: { [key: number]: ISensorBoxInfo } = {};
            sensorAssembly.sensorAssemblyItems?.forEach((sensorAssmblyItem, index) => {
                // const stockId = sensorAssmblyItem?.sensorModel?.sensorMo\delID as number;
                const stockId = index;

                // debugger
                // const stock = (sensorAssmblyItem?.sensorModel?.sensorModelStocks as SensorModelStock[])[0];

                sensors[stockId] = {
                    worldCoords: JSON.parse(
                        (sensorAssmblyItem?.sensorAssemblyItemExternals?.find(
                            e => e?.externalSystem?.code == "forge"
                        ) as SensorAssemblyItemExternal).transformationMatrix?.matrix as string
                    ),
                    sensor: {
                        sensorModelStocks: {},
                        displayName: false,
                        displayGraph: false,
                        sensorGraphColors: {
                            // TODO
                            color: sensorAssmblyItem?.sensorModelStock?.sensorModel?.isLocalizationSensor
                                ? "#04a7f7"
                                : "#ed125e",
                        },
                    } as ProjectFileVersionSensor,
                };
            });

            setSensorBoxesInfo(sensors);

            // wait for completition of all
            Promise.all(tasks).then(() => {
                // hide loading
                setIsLoading(false);
            });
        } else {
            // clear loaded assembly
        }
    }, [assemblyDetailsData]);

    /**
     * Load additional geometry into assembly
     * @param projectFileVersion
     * @param loadOptions
     * @param transformOptions
     */
    const loadProjectFileVersion = async (
        projectFileVersion: ProjectFileVersion,
        transformOptions?: any,
        sensorAssemblyGeometryID?: number
    ) => {
        return loadProjectFileVersionHelper(
            viewer,
            sharedPropertyDbPath,
            keycloak.token,
            projectFileVersion,
            transformOptions,
            sensorAssemblyGeometryID,
            model => {
                // @ts-ignore
                const modelId: number = model.getModelId() as number;

                const modelData = {
                    projectFileVersionID: projectFileVersion.projectFileVersionID,
                    externalSystemCode: "forge",
                    sensorAssemblyGeometryID,
                };

                if (sensorAssemblyGeometryID) {
                    modelData.sensorAssemblyGeometryID = sensorAssemblyGeometryID;
                }

                // store geometry reference
                setSensorAssemblyGeometries({
                    ...sensorAssemblyGeometries,
                    [modelId]: modelData,
                });
            }
        );
    };

    const getModelTransformation = model => {
        const frag = 0;

        // @ts-ignore
        let fragProxy = viewer.impl.getFragmentProxy(model, frag);
        fragProxy.getAnimTransform();

        return {
            position: fragProxy.position,
            scale: fragProxy.scale,
            quaternion: fragProxy.quaternion,
        };
    };

    const onPushpinCreated = async (sensorModelStock: SensorModelStock, position: any /*Vector3*/) => {
        const sensorBox = {
            worldCoords: position,
            sensor: {
                sensorModelStocks: sensorModelStock,
                displayName: false,
                displayGraph: false,
                sensorGraphColors: {
                    // TODO
                    color:
                        sensorModelStock?.sensorModel?.sensorType?.name == "Position trackers" ? "#04a7f7" : "#ed125e",
                },
            } as ProjectFileVersionSensor,
        } as ISensorBoxInfo;

        console.log("sensor box", sensorBox);

        const stockId: number = sensorModelStock?.sensorModelStockID as number;
        const sensors = { ...sensorBoxesInfo, [stockId]: sensorBox };

        const stocks = Object.values(sensors).map(sb => {
            return sb.sensor.sensorModelStocks;
        });

        dispatch(onAsseblyItemsUsedModel({ sensors: stocks as SensorModelStock[] }));

        setSensorBoxesInfo(sensors);
    };

    const onPushpinModified = async (
        projectFileVersionSensor: ProjectFileVersionSensor,
        position: any /*Vector3*/,
        displayGraph: boolean | undefined = undefined,
        displayName: undefined = undefined
    ) => {
        const stockId: number = projectFileVersionSensor.sensorModelStocks?.sensorModelStockID as number;

        setSensorBoxesInfo({
            ...sensorBoxesInfo,
            [stockId]: {
                ...sensorBoxesInfo[stockId],
                worldCoords: position,
            },
        });
    };

    const onPushpinRemove = async (projectFileVersionSensor: ProjectFileVersionSensor) => {
        // await deleteProjectFileVersionSensorMutation({
        //     variables: {
        //         projectFileVersionSensorID: `${projectFileVersionSensor.projectFileVersionSensorID}`,
        //     },
        // })
        //     .then(sensorData => {
        //         console.log("sensorData");
        //         console.log(sensorData);
        //     })
        //     .catch(sensorData => {
        //         console.log("sensorData");
        //         console.log(sensorData);
        //     });
    };

    const getGeometriesInAssembly = (): SensorAssemblyGeometryCreate[] => {
        const models = {};
        // @ts-ignore
        viewer.getVisibleModels().forEach((model: any) => {
            models[model.getModelId()] = model;
        });
        return Object.keys(sensorAssemblyGeometries).map(modelId => {
            const sensorAssemblyGeometry = sensorAssemblyGeometries[modelId];
            sensorAssemblyGeometry.transformationMatrix = {
                matrix: JSON.stringify(getModelTransformation(models[modelId])),
            };
            return sensorAssemblyGeometry;
        });
    };

    const getItemsInAssembly = (): SensorAssemblyItemCreate[] => {
        return Object.values(sensorBoxesInfo).map(sensorBoxInfo => {
            return {
                externalSystemCode: "forge",
                sensorModelStockID: sensorBoxInfo.sensor?.sensorModelStocks?.sensorModelStockID,
                transformationMatrix: {
                    matrix: JSON.stringify(sensorBoxInfo.worldCoords),
                },
            } as SensorAssemblyItemCreate;
        });
    };

    const getPositionSensors = (): ISensorBoxInfo[] => {
        return Object.values(sensorBoxesInfo).filter(
            sensorBoxInfo => sensorBoxInfo?.sensor?.sensorModelStocks?.sensorModel?.isLocalizationSensor
        );
    };

    const onDrop = async e => {
        e.preventDefault();

        if (geometryToAdd) {
            setIsLoading(true);
            const clientRect = e.currentTarget.getBoundingClientRect();
            const viewerCoords = {
                x: e.clientX - clientRect.left,
                y: e.clientY - clientRect.top,
            };

            let worldPosition = viewer.clientToWorld(viewerCoords.x, viewerCoords.y);

            if (worldPosition) {
                worldPosition = worldPosition.point;
            } else {
                // drop position is not intersecting any object
                // get ground intersection
                worldPosition = viewer.impl.intersectGround(viewerCoords.x, viewerCoords.y);
            }

            console.log("originalModel scale:", viewer.model.getUnitScale());

            const transformOptions = {
                position: worldPosition,
                quaternion: new THREE.Quaternion(0, 0, 0, 1),
            };

            dispatch(onSelectGeometryToAdd({}));

            await loadProjectFileVersion(geometryToAdd, transformOptions);
            setIsLoading(false);
        } else if (isDragging) {
            setIsDragging(undefined);
        } else {
            // probably dropping sensor
            console.log("Nothing to drop");
            dispatch(onSelectGeometryToAdd({}));
        }
    };

    return (
        <Box
            className={clsx({ [classes.root]: true, "is-dragging": isDragging!! || geometryToAdd!! })}
            onDragOver={e => {
                e.preventDefault();
            }}
            onDrop={onDrop}
        >
            {isLoading && <Loading localOnly={true} opaqueBackdrop={true} />}
            <SensorsInViewer
                sensorBoxes={Object.values(sensorBoxesInfo)}
                viewer={viewer}
                viewable={viewable}
                allowDraggingAll={true}
                onPushpinCreated={onPushpinCreated}
                onPushpinRemove={onPushpinRemove}
                onPushpinModified={onPushpinModified}
            />
            <Box className={classes.formHolder}>
                <AssemblyForm
                    selectGeometries={getGeometriesInAssembly}
                    selectSensors={getItemsInAssembly}
                    assemblyData={editingSensorAssembly}
                    selectPositionSensors={getPositionSensors}
                />
            </Box>
        </Box>
    );
};

export default AssemblyEditor;
