import { viewerProxy } from "../../../api/viewer/client";

export const getViewablePath = (urn: string, token: string): Promise<string> => {
    return new Promise(async (resolve, reject) => {
        try {
            const viewerFileInfo = await viewerProxy(token, urn, "b.87b7fbeb-e93d-48f4-8061-ff873aace81f", false);

            Autodesk.Viewing.Document.load(
                "urn:" + viewerFileInfo.urn,
                document => {
                    const views = document.getRoot().search({
                        type: "geometry",
                        role: "3d",
                    });

                    const svfUrl = document.getViewablePath(views[0]);
                    resolve(svfUrl);
                },
                err => {
                    console.log("Error loading document... ");
                    switch (
                        err
                        // removed for clarity, see full sample
                    ) {
                    }
                }
            );
        } catch (ex) {
            return reject(ex);
        }
    });
};

export const getViewable = (urn: string, token: string): Promise<{ [key: string]: any }> => {
    return new Promise(async (resolve, reject) => {
        try {
            const viewerFileInfo = await viewerProxy(token, urn, "b.87b7fbeb-e93d-48f4-8061-ff873aace81f", false);

            Autodesk.Viewing.Document.load(
                "urn:" + viewerFileInfo.urn,
                document => {
                    resolve({ document, viewable: document.getRoot().getDefaultGeometry() });
                },
                err => {
                    console.log("Error loading document... ");
                    switch (
                        err
                        // removed for clarity, see full sample
                    ) {
                    }
                }
            );
        } catch (ex) {
            return reject(ex);
        }
    });
};
