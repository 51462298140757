import { Box, createStyles, Theme } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import { ApolloError } from "apollo-client";
import React from "react";
import SensorCore from "./SensorCore";
import { SensorModelStock } from "../../graphql/generated/graphql";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            marginTop: theme.spacing(1.5),
            display: "grid",
            gridTemplateColumns: "1fr",
            gridGap: theme.spacing(1.5),
            [theme.breakpoints.up("sm")]: {
                gridTemplateColumns: "1fr 1fr",
            },
            overflowY: "auto",
        },
        skeletonBox: {},
        skeletonItem: {
            borderRadius: theme.shape.borderRadius,
        },
    })
);

interface ISensorListProps {
    data?: any | undefined;
    loading?: boolean;
    error?: ApolloError | undefined;
    showActualValue?: boolean;
}

const SensorList: React.FC<ISensorListProps> = (props: ISensorListProps) => {
    const { data, loading, error, showActualValue } = props;
    const classes = useStyles();
    const typeIndexes: { [key: string]: number } = {};

    const getTypeIndex = (sensorModelStock: SensorModelStock, index: number): number => {
        const typeId = sensorModelStock?.sensorModel?.sensorType?.sensorTypeID as number;
        if (!typeIndexes[typeId]) {
            typeIndexes[typeId] = 0;
        }
        return ++typeIndexes[typeId];
    };
    return (
        <Box className={classes.root}>
            {(!loading || !error) &&
                data &&
                data?.sensorModelStocks?.map((sensorModelStock, index) => (
                    <SensorCore
                        key={index}
                        sensorModelStock={sensorModelStock}
                        showActualValue={showActualValue}
                        typeIndex={getTypeIndex(sensorModelStock, index)}
                    />
                ))}

            {(loading || error) &&
                [1, 2].map(item => (
                    <Box className={classes.skeletonBox} key={item}>
                        <Skeleton variant="rect" width="100%" height={82} className={classes.skeletonItem} />
                    </Box>
                ))}
        </Box>
    );
};

export default SensorList;
