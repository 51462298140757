import { defineMessages } from "react-intl";

export default defineMessages({
    website: {
        id: "components__footer__website",
        defaultMessage: "www.iolabs.ch",
    },
    copyright: {
        id: "components__footer__copyright",
        defaultMessage: `Copyright © {year} - ioLabs Swiss GmbH`,
    },
    update: {
        id: "components__footer__update",
        defaultMessage: "Latest update",
    },
});
