import { IMenuItem, IUserMenuItem } from "@iolabs/layout";
import React, { ReactNode } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import PrivateLayout from "../../components/Layout/PrivateLayout";
import theme from "../../utils/Theming";
import ErrorPage from "../ErrorPage/ErrorPage";
import EditorPage from "../private/EditorPage/EditorPage";
import ModelPage from "../private/ModelPage/ModelPage";
import logo from "../../assets/images/bh-logo.svg";

export type PageRoute = {
    label: string; // label showing in menu
    isHidden?: boolean; // is hidden in menu
    path: string;
    component?: ReactNode;
    showMenu?: boolean;
    exact?: boolean;
    private: boolean; // authorization needed for access
    routes?: PageRoute;
};

export enum Path {
    // general paths
    ROOT = "/",
    LOGOUT = "/logout",

    // private paths
    MODEL = "/model",
    EDITOR = "/assembly-editor",
    EDITOR_FULL = "/assembly-editor/:assemblyID?",
}

export const menuItems: IMenuItem[] = [];

export const userMenuItems: IUserMenuItem[] = [
    // {
    //     title: "Settings",
    //     path: "/settings",
    //     icon: <SettingsIcon fontSize="small" />,
    // },
];

export const routes: PageRoute[] = [
    {
        label: "Model",
        private: true,
        path: Path.ROOT,
        exact: true,
        component: ModelPage,
        showMenu: false,
    },
    {
        label: "Assembly editor",
        private: true,
        path: Path.EDITOR_FULL,
        exact: true,
        component: EditorPage,
        showMenu: false,
    },
];

// export const publicRoutes = routes.filter(route => !route.private);
// export const privateRoutes = routes.filter(route => route.private);

export const RouteWithSubRoutes = route => (
    <Route
        path={route.path}
        exact={route.exact}
        component={props => (
            <PrivateLayout
                route={route}
                logo={logo}
                menuItems={menuItems}
                userMenuItems={userMenuItems}
                stylesProps={{
                    avatarBackgroundColor: theme.palette.grey["200"],
                    avatarColor: theme.palette.common.white,
                }}
            >
                <route.component {...props} routes={route.routes} />
            </PrivateLayout>
        )}
    />
);

const PageRouter: React.FC = () => {
    return (
        <Router>
            <LastLocationProvider>
                <Switch>
                    {routes.map((route: PageRoute, index: number) => (
                        <RouteWithSubRoutes key={index} {...route} />
                    ))}
                    <Route component={ErrorPage} />
                </Switch>
            </LastLocationProvider>
        </Router>
    );
};

export default PageRouter;
