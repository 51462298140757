import React from "react";
import ControlPanel from "../../../components/ControlPanel/ControlPanel";
import LayoutManager, { LayoutMode } from "../../../components/Layout/LayoutManager";
import Viewer from "../../../components/Viewer/Viewer";
import { useParams } from "react-router";

const EditorPage: React.FC = () => {
    const urn: string = "urn:adsk.wipemea:dm.lineage:K_rCpD23Tdm8vDxqKk6LlQ";
    // const urn: string = "urn:adsk.wipemea:dm.lineage:4M7BbIc1Sq2qDdM7sIB5lg";
    const projectId: string = "b.87b7fbeb-e93d-48f4-8061-ff873aace81f";

    const { assemblyID } = useParams();

    return (
        <LayoutManager
            mode={LayoutMode.Horizontal}
            componentPrimary={
                <Viewer
                    urn={urn}
                    projectId={projectId}
                    projectFileVersionID={2}
                    assemblyEditor={assemblyID ? parseInt(assemblyID) : true}
                />
            }
            componentSecondary={<ControlPanel />}
        />
    );
};

export default EditorPage;
