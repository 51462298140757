import {
    AppBar,
    Container,
    createStyles,
    CssBaseline,
    Drawer,
    Theme,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { ReactNode, useEffect } from "react";
import { PageRoute } from "../../pages/PageRouter/PageRouter";
import Footer from "../Footer/Footer";
import Header, { IUserMenuItem } from "../Header/Header";
import PrivateMenu, { IMenuItem } from "../Menu/PrivateMenu";

export const defaultDrawerWidth: number = 240;
export const defaultHeaderHeight: number = 70;
export const defaultFooterHeight: number = 20;
export const defaultFooterHeightXs: number = 60;

type StylesProps = {
    // layout
    drawerWidth?: number;
    headerBarColor?: string;
    headerBarBackground?: string;
    drawerColor?: string;
    drawerBackground?: string;
    drawerHeaderColor?: string;
    drawerHeaderBackground?: string;
    drawerPaperColor?: string;
    drawerPaperBackground?: string;
    footerHeight?: string;
    footerBarColor?: string;
    footerBarBackground?: string;

    // header
    headerHeight?: string;
    logoWidth?: string;
    logoHeight?: string;
    burgerButtonColor?: string;
    backButtonColor?: string;
    headingColor?: string;
    usernameColor?: string;
    companyColor?: string;
    projectColor?: string;
    avatarColor?: string;
    avatarBackgroundColor?: string;
    avatarSize?: string;
    menuIconColor?: string;
    menuBackgroundColor?: string;
    privateHeader?: string;
    dividerBackgroundColor?: string;

    // menu
    menuColor?: string;
    menuActiveColor?: string;
    dividerColor?: string;

    // footer
    showFooter?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: { display: "flex", paddingLeft: 0, paddingRight: 0 },
        appBar: (props: StylesProps | undefined) => ({
            zIndex: theme.zIndex.drawer + 1,
            color: props?.headerBarColor ? props?.headerBarColor : theme.palette.common.white,
            background: props?.headerBarBackground ? props?.headerBarBackground : theme.palette.common.white,
            flexGrow: 1,
            transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        }),
        appBarShift: (props: StylesProps | undefined) => ({
            width: `calc(100% - ${props?.drawerWidth ? props?.drawerWidth : defaultDrawerWidth}px)`,
            marginLeft: props?.drawerWidth ? props?.drawerWidth : defaultDrawerWidth,
            transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
        drawer: (props: StylesProps | undefined) => ({
            background: props?.drawerBackground ? props?.drawerBackground : theme.palette.grey["100"],
            flexShrink: 0,
            whiteSpace: "nowrap",
            "& .MuiDrawer-paperAnchorDockedLeft": {
                borderRight: 0,
            },
            minWidth: "60px",
        }),
        drawerOpen: (props: StylesProps | undefined) => ({
            width: props?.drawerWidth ? props?.drawerWidth : defaultDrawerWidth,
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
        drawerClose: {
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: "hidden",
            width: theme.spacing(6),
        },
        toolbar: theme.mixins.toolbar,
        drawerPaper: (props: StylesProps | undefined) => ({
            zIndex: 0,
            marginTop: props?.headerHeight ? props?.headerHeight : defaultHeaderHeight,
            color: props?.drawerPaperColor ? props?.drawerPaperColor : theme.palette.common.white,
            background: props?.drawerPaperBackground ? props?.drawerPaperBackground : theme.palette.grey["100"],
            overflowX: "hidden",
        }),
        drawerMenu: {
            fontSize: "1rem",
            marginLeft: theme.spacing(2),
            fontWeight: "bold",
            cursor: "pointer",
        },
        drawerMenuClose: {
            marginRight: theme.spacing(1),
        },
        main: {
            zIndex: 100,
            background: theme.palette.common.white,
            flexGrow: 1,
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: `calc(100vw - 58px)`,
            overflowX: "hidden",
        },
        mainShift: (props: StylesProps | undefined) => ({
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
            width: `calc(100vw - ${props?.drawerWidth ? props?.drawerWidth : defaultDrawerWidth}px)`,
        }),
        footerBar: (props: StylesProps | undefined) => ({
            background: props?.footerBarBackground ? props?.footerBarBackground : theme.palette.grey[500],
            zIndex: theme.zIndex.drawer + 10,
            top: "auto",
            bottom: 0,
            flexGrow: 1,
            transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        }),
        footerBarShift: {
            transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        content: {
            marginTop: defaultHeaderHeight,
            height: `calc(100vh - ${defaultHeaderHeight + defaultFooterHeightXs}px)`,
            flexGrow: 1,
            [theme.breakpoints.up("md")]: {
                height: `calc(100vh - ${defaultHeaderHeight + defaultFooterHeight}px)`,
            },
        },
    })
);

interface ILayoutProps extends React.HTMLAttributes<HTMLElement> {
    route: PageRoute;
    menuItems: IMenuItem[];
    userMenuItems: IUserMenuItem[];
    logo?: string;
    header?: {
        rightMenu?: ReactNode;
    };
    stylesProps?: StylesProps;
}

const PrivateLayout: React.FC<ILayoutProps> = (props: ILayoutProps) => {
    const { children, route, header, menuItems, userMenuItems, stylesProps } = props;

    const classes = useStyles(stylesProps);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));

    const [drawerOpen, setDrawerOpen] = React.useState(true);

    useEffect(() => {
        if (matches) {
            setDrawerOpen(true);
        } else {
            setDrawerOpen(false);
        }
    }, [matches]);

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    // translations
    // const intl = useIntl();
    // const transMenu = intl.formatMessage({ ...messages.menu });

    return (
        <Container className={classes.root} maxWidth={false}>
            <CssBaseline />

            <AppBar
                elevation={3}
                position="fixed"
                className={clsx(classes.appBar, {
                    // [classes.appBarShift]: !drawer?.disabled && drawerOpen,
                })}
            >
                <Header
                    userMenuItems={userMenuItems}
                    rightMenu={header?.rightMenu}
                    stylesProps={{
                        headerHeight: stylesProps?.headerHeight,
                        logoWidth: stylesProps?.logoWidth,
                        logoHeight: stylesProps?.logoHeight,
                        burgerButtonColor: stylesProps?.burgerButtonColor,
                        backButtonColor: stylesProps?.backButtonColor,
                        headingColor: stylesProps?.headingColor,
                        usernameColor: stylesProps?.usernameColor,
                        companyColor: stylesProps?.companyColor,
                        projectColor: stylesProps?.projectColor,
                        avatarColor: stylesProps?.avatarColor,
                        avatarBackgroundColor: stylesProps?.avatarBackgroundColor,
                        avatarSize: stylesProps?.avatarSize,
                        menuColor: stylesProps?.menuColor,
                        menuIconColor: stylesProps?.menuIconColor,
                        menuBackgroundColor: stylesProps?.menuBackgroundColor,
                        privateHeader: stylesProps?.privateHeader,
                        dividerBackgroundColor: stylesProps?.dividerBackgroundColor,
                    }}
                />
            </AppBar>

            {!route.showMenu ? null : (
                <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: drawerOpen,
                        [classes.drawerClose]: !drawerOpen,
                    })}
                    classes={{
                        paper: clsx(classes.drawerPaper, {
                            [classes.drawerOpen]: drawerOpen,
                            [classes.drawerClose]: !drawerOpen,
                        }),
                    }}
                >
                    <PrivateMenu
                        drawerOpen={drawerOpen}
                        handleDrawerOpen={handleDrawerOpen}
                        handleDrawerClose={handleDrawerClose}
                        menuItems={menuItems}
                    />
                </Drawer>
            )}

            <main
                className={clsx(classes.main, {
                    [classes.mainShift]: drawerOpen,
                })}
            >
                <div className={classes.content}>{children}</div>
            </main>

            <AppBar
                className={clsx(classes.footerBar, {
                    [classes.footerBarShift]: drawerOpen,
                })}
            >
                <Footer
                    stylesProps={{
                        footerHeight: stylesProps?.footerHeight,
                    }}
                />
            </AppBar>
        </Container>
    );
};

export default PrivateLayout;
