import { Box } from "@material-ui/core";
import React from "react";
import AssemblyWrapper from "./Assembly/AssemblyWrapper";
import DataWrapper from "./Data/DataWrapper";
import GeometricalWrapper from "./Geometrical/GeometricalWrapper";
import LocalizationWrapper from "./Localization/LocalizationWrapper";

export enum FileType {
    GEOMETRICAL_OBJECTS = "1",
}

const Editor: React.FC = () => {
    return (
        <Box>
            <GeometricalWrapper fileType={FileType.GEOMETRICAL_OBJECTS} />
            <DataWrapper />
            <LocalizationWrapper />
            <AssemblyWrapper />
        </Box>
    );
};

export default Editor;
