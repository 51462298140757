import { defineMessages } from "react-intl";

export default defineMessages({
    company: {
        id: "components__header__company",
        defaultMessage: "POC Sensor Visualization Tool",
    },
    companyXs: {
        id: "components__header__company--xs",
        defaultMessage: "POC",
    },
    signOut: {
        id: "components__header__sign-out",
        defaultMessage: "Sign out",
    },
});
