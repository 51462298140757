import {
    Box,
    Checkbox,
    createStyles,
    FormControl,
    FormControlLabel,
    FormGroup,
    Theme,
    Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import { ApolloError } from "apollo-client";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { getRandomInt } from "../../utils/Random";
import messages from "./messages";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexFlow: "row wrap",
            justifyContent: "flex-start",
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            borderBottom: `1px solid ${theme.palette.grey["300"]}`,
        },
        box: {
            flexBasis: "100%",
            [theme.breakpoints.up("sm")]: {
                flexBasis: "50%",
            },
        },
        formControl: {
            marginTop: theme.spacing(1),
            [theme.breakpoints.up("sm")]: {
                margin: 0,
            },
        },
        formControlLabel: {
            marginLeft: 0,
            "& .MuiTypography-body1": {
                fontSize: "0.9rem",
            },
            "& .MuiButtonBase-root": {
                padding: "5px",
            },
        },
        skeletonBox: {
            display: "flex",
            padding: "7px",
        },
        skeletonItem: {
            borderRadius: "2px",
            marginRight: theme.spacing(1),
        },
    })
);

interface IInventoryProps {
    inventory: string[];
    handleInitInventory: (initInventory: string[]) => void;
    handleChangeInventory: (event: React.ChangeEvent<HTMLInputElement>) => void;
    data: any | undefined;
    loading: boolean;
    error: ApolloError | undefined;
}

const Inventory: React.FC<IInventoryProps> = (props: IInventoryProps) => {
    const { inventory, handleInitInventory, handleChangeInventory, data, loading, error } = props;
    const classes = useStyles();

    // translations
    const intl = useIntl();
    const transInventory = intl.formatMessage({ ...messages.inventory });

    useEffect(() => {
        let init: string[] = [];
        data?.sensorTypes?.map(sensorType => {
            init.push(`${sensorType?.sensorTypeID}`);
        });
        handleInitInventory(init);
    }, [data]);

    return (
        <Box className={classes.root}>
            <Box className={classes.box}>
                <Typography variant="h4" component="h3">
                    {transInventory}
                </Typography>
            </Box>

            <Box className={classes.box}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormGroup>
                        {(loading || error) &&
                            [1, 2, 3, 4, 5].map(item => (
                                <Box className={classes.skeletonBox} key={item}>
                                    <Skeleton variant="rect" width={15} height={15} className={classes.skeletonItem} />
                                    <Skeleton
                                        variant="rect"
                                        width={getRandomInt(150, 200)}
                                        height={15}
                                        className={classes.skeletonItem}
                                    />
                                </Box>
                            ))}

                        {(!loading || !error) &&
                            data &&
                            data?.sensorTypes?.map((sensorType, index) => (
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox
                                            checked={inventory.includes(`${sensorType?.sensorTypeID}`)}
                                            onChange={handleChangeInventory}
                                            name={`${sensorType?.sensorTypeID}`}
                                            color="primary"
                                            size="small"
                                        />
                                    }
                                    label={sensorType?.name.replace("CO2", "CO₂")}
                                    className={classes.formControlLabel}
                                />
                            ))}
                    </FormGroup>
                </FormControl>
            </Box>
        </Box>
    );
};

export default Inventory;
