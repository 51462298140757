import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.grey["100"],
            pointerEvents: "all",
        },
        localOnly: {
            position: "absolute",
        },
        opaque: {
            opacity: "0.5!important",
        },
    })
);

interface ILoadingProps {
    localOnly?: boolean;
    opaqueBackdrop?: boolean;
}

const Loading: React.FC<ILoadingProps> = (props: ILoadingProps) => {
    const classes = useStyles();
    const { localOnly, opaqueBackdrop } = props;
    return (
        <div>
            <Backdrop
                className={clsx({
                    [classes.backdrop]: true,
                    [classes.localOnly]: localOnly,
                    [classes.opaque]: opaqueBackdrop,
                })}
                open={true}
            >
                <CircularProgress color="primary" size={80} />
            </Backdrop>
        </div>
    );
};

export default Loading;
