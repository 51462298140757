import {
    Box,
    Checkbox,
    createStyles,
    FormControlLabel,
    FormGroup,
    List,
    ListItem,
    ListItemText,
    Paper,
    Popover,
    Theme,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useIntl } from "react-intl";
import { ProjectFileVersionSensor, SensorGraphColor } from "../../graphql/generated/graphql";
import messages from "./messages";
import PushpinColorPicker from "./PushpinColorPicker";
import { PushpinOptions } from "./PushpinWrapper";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginLeft: theme.spacing(1),
            overflow: "hidden",
            pointerEvents: "all",
        },
        paper: {
            minWidth: "160px",
            width: "160px",
            overflow: "hidden",
        },
        paperBox: {
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(1.5),
            paddingBottom: theme.spacing(1),
        },
        paperList: {},
        paperListItem: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(1.5),
        },
        paperListItemText: {},
        optionsLabel: {
            fontWeight: "bold",
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
            borderBottom: `1px solid ${theme.palette.grey["300"]}`,
        },
        formControlLabel: {
            paddingLeft: "4px",
            "& > span": {
                padding: "5px",
            },
        },
    })
);

interface IPushpinPopoverProps {
    id: string | undefined;
    open: boolean;
    dragDisabled?: boolean;
    deleteDisabled?: boolean;
    anchorEl: HTMLButtonElement | null;
    options: PushpinOptions;
    pushpinColor: SensorGraphColor;
    handleClose: () => void;
    handleChangeOptions: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeColor: (color: any) => void;
    sensor: ProjectFileVersionSensor;
    handleDrag?: (projectFileVersionSensor: ProjectFileVersionSensor) => void;
    handleDelete?: (projectFileVersionSensor: ProjectFileVersionSensor) => void;
}

const PushpinPopover: React.FC<IPushpinPopoverProps> = (props: IPushpinPopoverProps) => {
    const {
        id,
        open,
        anchorEl,
        handleClose,
        options,
        handleChangeOptions,
        pushpinColor,
        handleChangeColor,
        sensor,
        handleDrag,
        handleDelete,
        dragDisabled,
        deleteDisabled,
    } = props;
    const classes = useStyles();

    // translations
    const intl = useIntl();
    const transOptionsMove = intl.formatMessage({ ...messages.optionsMove });
    const transOptionsRemove = intl.formatMessage({ ...messages.optionsRemove });
    const transOptionsLabel = intl.formatMessage({ ...messages.optionsLabel });
    const transOptionsName = intl.formatMessage({ ...messages.optionsName });
    const transOptionsGraph = intl.formatMessage({ ...messages.optionsGraph });

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            className={classes.root}
        >
            <Paper className={classes.paper}>
                <List component="nav" aria-label="pushpin settings" className={classes.paperList}>
                    <ListItem button className={classes.paperListItem} disabled={!!dragDisabled}>
                        <ListItemText
                            primary={transOptionsMove}
                            className={classes.paperListItemText}
                            onClick={() => {
                                if (handleDrag) {
                                    handleDrag(sensor);
                                }
                                handleClose();
                            }}
                        />
                    </ListItem>
                    <ListItem button className={classes.paperListItem} disabled={!!deleteDisabled}>
                        <ListItemText
                            primary={transOptionsRemove}
                            className={classes.paperListItemText}
                            onClick={() => {
                                if (handleDelete) {
                                    handleDelete(sensor);
                                }
                                handleClose();
                            }}
                        />
                    </ListItem>
                </List>

                <Box className={classes.paperBox}>
                    <Typography className={classes.optionsLabel}>{transOptionsLabel}</Typography>

                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={options.showGraph}
                                    onChange={handleChangeOptions}
                                    name="showGraph"
                                    color="primary"
                                    size="small"
                                />
                            }
                            label={transOptionsGraph}
                            className={classes.formControlLabel}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={options.showName}
                                    onChange={handleChangeOptions}
                                    name="showName"
                                    color="primary"
                                    size="small"
                                />
                            }
                            label={transOptionsName}
                            className={classes.formControlLabel}
                        />
                        <PushpinColorPicker pushpinColor={pushpinColor} handleChangeColor={handleChangeColor} />
                    </FormGroup>
                </Box>
            </Paper>
        </Popover>
    );
};

export default PushpinPopover;
