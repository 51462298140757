import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /** 
 * The `Date` scalar type represents a year, month and day in accordance with the
   * [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard.
 */
  Date: any,
  /** The `Seconds` scalar type represents a period of time represented as the total number of seconds. */
  Seconds: any,
  /** 
 * The `DateTime` scalar type represents a date and time. `DateTime` expects
   * timestamps to be formatted in accordance with the
   * [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard.
 */
  DateTime: any,
  /** 
 * The `DateTimeOffset` scalar type represents a date, time and offset from UTC.
   * `DateTimeOffset` expects timestamps to be formatted in accordance with the
   * [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard.
 */
  DateTimeOffset: any,
  Decimal: any,
  /** The `Milliseconds` scalar type represents a period of time represented as the total number of milliseconds. */
  Milliseconds: any,
};




export enum ComparisonGraph {
  contains = 'contains',
  endsWith = 'endsWith',
  equal = 'equal',
  greaterThan = 'greaterThan',
  greaterThanOrEqual = 'greaterThanOrEqual',
  notIn = 'notIn',
  in = 'in',
  lessThan = 'lessThan',
  lessThanOrEqual = 'lessThanOrEqual',
  like = 'like',
  startsWith = 'startsWith'
}

export enum Connector {
  AND = 'AND',
  OR = 'OR',
  and = 'and',
  or = 'or'
}





export type ExternalSystem = {
  code: Maybe<Scalars['String']>,
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy: Maybe<Scalars['String']>,
  description: Maybe<Scalars['String']>,
  externalSystemID: Maybe<Scalars['Int']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory: Maybe<Scalars['String']>,
  name: Maybe<Scalars['String']>,
  projectExternals: Maybe<Array<Maybe<ProjectExternal>>>,
  projectFileExternals: Maybe<Array<Maybe<ProjectFileExternal>>>,
  projectFileVersionExternals: Maybe<Array<Maybe<ProjectFileVersionExternal>>>,
  sensorAssemblyGeometryExternals: Maybe<Array<Maybe<SensorAssemblyGeometryExternal>>>,
  sensorAssemblyItemExternals: Maybe<Array<Maybe<SensorAssemblyItemExternal>>>,
  sensorLocationExternals: Maybe<Array<Maybe<SensorLocationExternal>>>,
  url: Maybe<Scalars['String']>,
};


export type ExternalSystemProjectExternalsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type ExternalSystemProjectFileExternalsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type ExternalSystemProjectFileVersionExternalsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type ExternalSystemSensorAssemblyGeometryExternalsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type ExternalSystemSensorAssemblyItemExternalsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type ExternalSystemSensorLocationExternalsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type FileType = {
  code: Maybe<Scalars['String']>,
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy: Maybe<Scalars['String']>,
  description: Maybe<Scalars['String']>,
  fileTypeID: Maybe<Scalars['Int']>,
  isModel: Maybe<Scalars['Boolean']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory: Maybe<Scalars['String']>,
  name: Maybe<Scalars['String']>,
  projectFiles: Maybe<Array<Maybe<ProjectFile>>>,
};


export type FileTypeProjectFilesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type Mutation = {
  /** Create sensor instance */
  createProjectFileVersionSensor: Maybe<ProjectFileVersionSensor>,
  /** Create sensor assembly */
  createSensorAssembly: Maybe<SensorAssembly>,
  /** Create sensor assembly geometry */
  createSensorAssemblyGeometry: Maybe<SensorAssemblyGeometry>,
  /** Create sensor assembly item */
  createSensorAssemblyItem: Maybe<SensorAssemblyItem>,
  /** Create sensor graph color */
  createSensorGraphColor: Maybe<SensorGraphColor>,
  /** Create sensor model */
  createSensorModel: Maybe<SensorModel>,
  /** Create sensor model stock availability for project */
  createSensorModelStock: Maybe<SensorModelStock>,
  /** Create transformation matrix */
  createTransformationMatrix: Maybe<TransformationMatrix>,
  /** Delete sensor instance in given version of project file */
  deleteProjectFileVersionSensor: Maybe<Scalars['Int']>,
  /** Delete sensor assembly */
  deleteSensorAssembly: Maybe<Scalars['Int']>,
  /** Delete sensor assembly geometry */
  deleteSensorAssemblyGeometry: Maybe<Scalars['Int']>,
  /** Delete sensor assembly item */
  deleteSensorAssemblyItem: Maybe<Scalars['Int']>,
  /** Delete sensor graph color */
  deleteSensorGraphColor: Maybe<Scalars['Int']>,
  /** Delete sensor model */
  deleteSensorModel: Maybe<Scalars['Int']>,
  /** Delete sensor model stock availability for project */
  deleteSensorModelStock: Maybe<Scalars['Int']>,
  /** Delete transformation matrix */
  deleteTransformationMatrix: Maybe<Scalars['Int']>,
  /** Update sensor instance */
  updateProjectFileVersionSensor: Maybe<ProjectFileVersionSensor>,
  /** Update sensor assembly */
  updateSensorAssembly: Maybe<SensorAssembly>,
  /** Update sensor assembly geometry */
  updateSensorAssemblyGeometry: Maybe<SensorAssemblyGeometry>,
  /** Update sensor assembly item */
  updateSensorAssemblyItem: Maybe<SensorAssemblyItem>,
  /** Update sensor graph color */
  updateSensorGraphColor: Maybe<SensorGraphColor>,
  /** Update sensor model */
  updateSensorModel: Maybe<SensorModel>,
  /** Update sensor model stock availability for project */
  updateSensorModelStock: Maybe<SensorModelStock>,
  /** Update transformation matrix */
  updateTransformationMatrix: Maybe<TransformationMatrix>,
};


export type MutationCreateProjectFileVersionSensorArgs = {
  projectFileVersionSensor: ProjectFileVersionSensorCreate
};


export type MutationCreateSensorAssemblyArgs = {
  sensorAssembly: SensorAssemblyCreateType
};


export type MutationCreateSensorAssemblyGeometryArgs = {
  sensorAssemblyGeometry: SensorAssemblyGeometryFullCreate
};


export type MutationCreateSensorAssemblyItemArgs = {
  sensorAssemblyItem: SensorAssemblyItemFullCreate
};


export type MutationCreateSensorGraphColorArgs = {
  sensorGraphColor: SensorGraphColorCreate
};


export type MutationCreateSensorModelArgs = {
  sensorModel: SensorModelCreate
};


export type MutationCreateSensorModelStockArgs = {
  sensorModelStock: SensorModelStockCreate
};


export type MutationCreateTransformationMatrixArgs = {
  transformationMatrix: TransformationMatrixCreate
};


export type MutationDeleteProjectFileVersionSensorArgs = {
  projectFileVersionSensor: Scalars['ID']
};


export type MutationDeleteSensorAssemblyArgs = {
  sensorAssembly: Scalars['ID']
};


export type MutationDeleteSensorAssemblyGeometryArgs = {
  sensorAssemblyGeometry: Scalars['ID']
};


export type MutationDeleteSensorAssemblyItemArgs = {
  sensorAssemblyItem: Scalars['ID']
};


export type MutationDeleteSensorGraphColorArgs = {
  sensorGraphColor: Scalars['ID']
};


export type MutationDeleteSensorModelArgs = {
  sensorModel: Scalars['ID']
};


export type MutationDeleteSensorModelStockArgs = {
  sensorModelStock: Scalars['ID']
};


export type MutationDeleteTransformationMatrixArgs = {
  transformationMatrix: Scalars['ID']
};


export type MutationUpdateProjectFileVersionSensorArgs = {
  projectFileVersionSensor: ProjectFileVersionSensorUpdate
};


export type MutationUpdateSensorAssemblyArgs = {
  sensorAssembly: SensorAssemblyUpdateType
};


export type MutationUpdateSensorAssemblyGeometryArgs = {
  sensorAssemblyGeometry: SensorAssemblyGeometryFullUpdate
};


export type MutationUpdateSensorAssemblyItemArgs = {
  sensorAssemblyItem: SensorAssemblyItemFullUpdate
};


export type MutationUpdateSensorGraphColorArgs = {
  sensorGraphColor: SensorGraphColorUpdate
};


export type MutationUpdateSensorModelArgs = {
  sensorModel: SensorModelUpdate
};


export type MutationUpdateSensorModelStockArgs = {
  sensorModelStock: SensorModelStockUpdate
};


export type MutationUpdateTransformationMatrixArgs = {
  transformationMatrix: TransformationMatrixUpdate
};

export type OrderByGraph = {
  path: Scalars['String'],
  descending: Maybe<Scalars['Boolean']>,
};

export type Project = {
  active: Maybe<Scalars['Boolean']>,
  additionalData: Maybe<Scalars['String']>,
  code: Maybe<Scalars['String']>,
  createdAt: Maybe<Scalars['Date']>,
  createdBy: Maybe<Scalars['String']>,
  description: Maybe<Scalars['String']>,
  lastlyEditedAt: Maybe<Scalars['Date']>,
  lastlyEditedBy: Maybe<Scalars['String']>,
  lastlyEditedHistory: Maybe<Scalars['String']>,
  lastUpdate: Maybe<Scalars['Date']>,
  name: Maybe<Scalars['String']>,
  notificationReceiver: Maybe<Scalars['String']>,
  projectExternals: Maybe<Array<Maybe<ProjectExternal>>>,
  projectFile: Maybe<ProjectFile>,
  projectFiles: Maybe<Array<Maybe<ProjectFile>>>,
  projectID: Maybe<Scalars['Int']>,
  sensorModelStocks: Maybe<Array<Maybe<SensorModelStock>>>,
  status: Maybe<Scalars['Int']>,
  viewerViewpoint: Maybe<ViewerViewpoint>,
  viewerViewpoints: Maybe<Array<Maybe<ViewerViewpoint>>>,
};


export type ProjectProjectExternalsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type ProjectProjectFilesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type ProjectSensorModelStocksArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type ProjectViewerViewpointsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type ProjectExternal = {
  additionalData: Maybe<Array<Maybe<Scalars['String']>>>,
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy: Maybe<Scalars['String']>,
  externalID: Maybe<Scalars['String']>,
  externalSystem: Maybe<ExternalSystem>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory: Maybe<Scalars['String']>,
  project: Maybe<Project>,
  projectExternalID: Maybe<Scalars['Int']>,
};

export type ProjectFile = {
  adapter: Maybe<Scalars['String']>,
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy: Maybe<Scalars['String']>,
  description: Maybe<Scalars['String']>,
  fileName: Maybe<Scalars['String']>,
  fileType: Maybe<FileType>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory: Maybe<Scalars['String']>,
  name: Maybe<Scalars['String']>,
  priority: Maybe<Scalars['Int']>,
  project: Maybe<Project>,
  projectFileExternals: Maybe<Array<Maybe<ProjectFileExternal>>>,
  projectFileID: Maybe<Scalars['Int']>,
  projectFileVersions: Maybe<Array<Maybe<ProjectFileVersion>>>,
  projects: Maybe<Array<Maybe<Project>>>,
  viewerViewpoint: Maybe<ViewerViewpoint>,
};


export type ProjectFileProjectFileExternalsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type ProjectFileProjectFileVersionsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type ProjectFileProjectsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type ProjectFileExternal = {
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy: Maybe<Scalars['String']>,
  externalFolder: Maybe<Scalars['String']>,
  externalID: Maybe<Scalars['String']>,
  externalSystem: Maybe<ExternalSystem>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory: Maybe<Scalars['String']>,
  projectFile: Maybe<ProjectFile>,
  projectFileExternalID: Maybe<Scalars['Int']>,
};

export type ProjectFileVersion = {
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy: Maybe<Scalars['String']>,
  description: Maybe<Scalars['String']>,
  isValid: Maybe<Scalars['Boolean']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory: Maybe<Scalars['String']>,
  name: Maybe<Scalars['String']>,
  projectFile: Maybe<ProjectFile>,
  projectFileVersionExternals: Maybe<Array<Maybe<ProjectFileVersionExternal>>>,
  projectFileVersionID: Maybe<Scalars['Int']>,
  projectFileVersionSensors: Maybe<Array<Maybe<ProjectFileVersionSensor>>>,
  sensorAssemblyGeometries: Maybe<Array<Maybe<SensorAssemblyGeometry>>>,
  validFrom: Maybe<Scalars['Date']>,
  validTo: Maybe<Scalars['Date']>,
  version: Maybe<Scalars['Int']>,
};


export type ProjectFileVersionProjectFileVersionExternalsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type ProjectFileVersionProjectFileVersionSensorsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type ProjectFileVersionSensorAssemblyGeometriesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type ProjectFileVersionExternal = {
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy: Maybe<Scalars['String']>,
  externalID: Maybe<Scalars['String']>,
  externalSystem: Maybe<ExternalSystem>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory: Maybe<Scalars['String']>,
  projectFileVersion: Maybe<ProjectFileVersion>,
  projectFileVersionExternalID: Maybe<Scalars['Int']>,
};

export type ProjectFileVersionSensor = {
  displayGraph: Maybe<Scalars['Boolean']>,
  displayName: Maybe<Scalars['Boolean']>,
  projectFileVersion: Maybe<ProjectFileVersion>,
  projectFileVersionSensorID: Maybe<Scalars['Int']>,
  sensorGraphColors: Maybe<SensorGraphColor>,
  sensorLocationExternals: Maybe<Array<Maybe<SensorLocationExternal>>>,
  sensorModelStocks: Maybe<SensorModelStock>,
};


export type ProjectFileVersionSensorSensorLocationExternalsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type ProjectFileVersionSensorCreate = {
  /** ID of project file version (from ProjectFileVersions) */
  projectFileVersionID: Scalars['Int'],
  /** ID of sensor model stock (from SensorModelStocks) */
  sensorModelStockID: Scalars['Int'],
  /** ID of graph color (from SensorGraphColors) */
  sensorGraphColorID: Maybe<Scalars['Int']>,
  /** If true graph will be displayed near sensor placement */
  displayGraph: Scalars['Boolean'],
  /** If true name will be printed near sensor placement */
  displayName: Scalars['Boolean'],
  /** Code of external system */
  externalSystemCode: Scalars['String'],
  /** Position for sensor */
  vector: Maybe<VectorCreate>,
};

export type ProjectFileVersionSensorsWithLastGraphs = {
  lastSensorGraphs: Maybe<Array<Maybe<SensorGraph>>>,
  projectFileVersionSensors: Maybe<Array<Maybe<ProjectFileVersionSensor>>>,
};

export type ProjectFileVersionSensorUpdate = {
  /** ID of project file version (from ProjectFileVersions) */
  projectFileVersionID: Scalars['Int'],
  /** ID of sensor model stock (from SensorModelStocks) */
  sensorModelStockID: Scalars['Int'],
  /** ID of graph color (from SensorGraphColors) */
  sensorGraphColorID: Maybe<Scalars['Int']>,
  /** If true graph will be displayed near sensor placement */
  displayGraph: Scalars['Boolean'],
  /** If true name will be printed near sensor placement */
  displayName: Scalars['Boolean'],
  /** Code of external system */
  externalSystemCode: Scalars['String'],
  /** Position for sensor */
  vector: Maybe<VectorCreate>,
  /** ID of ProjectFileVersionSensor entity to update (from ProjectFileVersionSensors) */
  projectFileVersionSensorID: Scalars['Int'],
};

/** Basic Queries */
export type Query = {
  /** Get list of ExternalSystem */
  externalSystems: Maybe<Array<Maybe<ExternalSystem>>>,
  /** Get list of FileType */
  fileTypes: Maybe<Array<Maybe<FileType>>>,
  /** Get list of ProjectExternal */
  projectExternals: Maybe<Array<Maybe<ProjectExternal>>>,
  /** Get list of ProjectFileExternal */
  projectFileExternals: Maybe<Array<Maybe<ProjectFileExternal>>>,
  /** Get list of ProjectFile */
  projectFiles: Maybe<Array<Maybe<ProjectFile>>>,
  /** Get list of ProjectFileVersionExternal */
  projectFileVersionExternals: Maybe<Array<Maybe<ProjectFileVersionExternal>>>,
  /** Get list of ProjectFileVersion */
  projectFileVersions: Maybe<Array<Maybe<ProjectFileVersion>>>,
  /** Get list of ProjectFileVersionSensor */
  projectFileVersionSensors: Maybe<Array<Maybe<ProjectFileVersionSensor>>>,
  projectFileVersionSensorsWithLastGraphsType: Maybe<ProjectFileVersionSensorsWithLastGraphs>,
  /** Get list of Project */
  projects: Maybe<Array<Maybe<Project>>>,
  /** Get list of SensorAssembly */
  sensorAssemblies: Maybe<Array<Maybe<SensorAssembly>>>,
  /** Get list of SensorAssemblyGeometry */
  sensorAssemblyGeometries: Maybe<Array<Maybe<SensorAssemblyGeometry>>>,
  /** Get list of SensorAssemblyGeometryExternal */
  sensorAssemblyGeometryExternals: Maybe<Array<Maybe<SensorAssemblyGeometryExternal>>>,
  /** Get list of SensorAssemblyItemExternal */
  sensorAssemblyItemExternals: Maybe<Array<Maybe<SensorAssemblyItemExternal>>>,
  /** Get list of SensorAssemblyItem */
  sensorAssemblyItems: Maybe<Array<Maybe<SensorAssemblyItem>>>,
  /** Get list of SensorGraphColor */
  sensorGraphColors: Maybe<Array<Maybe<SensorGraphColor>>>,
  /** Get list of SensorGraph */
  sensorGraphs: Maybe<Array<Maybe<SensorGraph>>>,
  /** Get list of SensorLocationExternal */
  sensorLocationExternals: Maybe<Array<Maybe<SensorLocationExternal>>>,
  /** Get list of SensorModel */
  sensorModels: Maybe<Array<Maybe<SensorModel>>>,
  /** Get list of SensorModelStock */
  sensorModelStocks: Maybe<Array<Maybe<SensorModelStock>>>,
  /** Get list of SensorType */
  sensorTypes: Maybe<Array<Maybe<SensorType>>>,
  /** Get list of TransformationMatrix */
  transformationMatrixes: Maybe<Array<Maybe<TransformationMatrix>>>,
  /** Get list of Vector */
  vectors: Maybe<Array<Maybe<Vector>>>,
  /** Get list of ViewerViewpoint */
  viewerViewpoints: Maybe<Array<Maybe<ViewerViewpoint>>>,
};


/** Basic Queries */
export type QueryExternalSystemsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryFileTypesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryProjectExternalsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryProjectFileExternalsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryProjectFilesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryProjectFileVersionExternalsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryProjectFileVersionsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryProjectFileVersionSensorsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryProjectFileVersionSensorsWithLastGraphsTypeArgs = {
  projectFileVersionID?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryProjectsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QuerySensorAssembliesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QuerySensorAssemblyGeometriesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QuerySensorAssemblyGeometryExternalsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QuerySensorAssemblyItemExternalsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QuerySensorAssemblyItemsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QuerySensorGraphColorsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QuerySensorGraphsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QuerySensorLocationExternalsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QuerySensorModelsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QuerySensorModelStocksArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QuerySensorTypesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryTransformationMatrixesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryVectorsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryViewerViewpointsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type SensorAssembly = {
  description: Maybe<Scalars['String']>,
  manufacturer: Maybe<Scalars['String']>,
  name: Maybe<Scalars['String']>,
  sensorAssemblyGeometries: Maybe<Array<Maybe<SensorAssemblyGeometry>>>,
  sensorAssemblyID: Maybe<Scalars['Int']>,
  sensorAssemblyItems: Maybe<Array<Maybe<SensorAssemblyItem>>>,
  sensorModels: Maybe<Array<Maybe<SensorModel>>>,
};


export type SensorAssemblySensorAssemblyGeometriesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type SensorAssemblySensorAssemblyItemsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type SensorAssemblySensorModelsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type SensorAssemblyCreateType = {
  /** Name of assembly */
  name: Scalars['String'],
  /** Description of assembly */
  description: Scalars['String'],
  /** Manufacturer of assembly (redundant) */
  manufacturer: Scalars['String'],
  /** Sensor models to include into assembly */
  sensorAssemblyItems: Maybe<Array<Maybe<SensorAssemblyItemCreate>>>,
  /** Geometry shapes to include into assembly */
  sensorAssemblyGeometries: Maybe<Array<Maybe<SensorAssemblyGeometryCreate>>>,
};

export type SensorAssemblyGeometry = {
  projectFileVersions: Maybe<ProjectFileVersion>,
  sensorAssemblies: Maybe<SensorAssembly>,
  sensorAssemblyGeometryExternals: Maybe<Array<Maybe<SensorAssemblyGeometryExternal>>>,
  sensorAssemblyGeometryID: Maybe<Scalars['Int']>,
};


export type SensorAssemblyGeometrySensorAssemblyGeometryExternalsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type SensorAssemblyGeometryCreate = {
  /** ID of project file containing geometry (from ProjectFileVersions) */
  projectFileVersionID: Scalars['Int'],
  /** Code of external system */
  externalSystemCode: Scalars['String'],
  /** Transformation matrix for relative position inside assembly */
  transformationMatrix: TransformationMatrixCreate,
};

export type SensorAssemblyGeometryExternal = {
  externalSystem: Maybe<ExternalSystem>,
  sensorAssemblyGeometry: Maybe<SensorAssemblyGeometry>,
  sensorAssemblyGeometryExternalID: Maybe<Scalars['Int']>,
  transformationMatrix: Maybe<TransformationMatrix>,
};

export type SensorAssemblyGeometryFullCreate = {
  /** ID of project file containing geometry (from ProjectFileVersions) */
  projectFileVersionID: Scalars['Int'],
  /** Code of external system */
  externalSystemCode: Scalars['String'],
  /** Transformation matrix for relative position inside assembly */
  transformationMatrix: TransformationMatrixCreate,
  /** ID of sensor assembly (from SensorAssemblies) */
  sensorAssemblyID: Scalars['Int'],
};

export type SensorAssemblyGeometryFullUpdate = {
  /** ID of project file containing geometry (from ProjectFileVersions) */
  projectFileVersionID: Scalars['Int'],
  /** Code of external system */
  externalSystemCode: Scalars['String'],
  /** Transformation matrix for relative position inside assembly */
  transformationMatrix: TransformationMatrixCreate,
  /** ID of sensor assembly (from SensorAssemblies) */
  sensorAssemblyID: Scalars['Int'],
  /** ID of sensor assembly geometry (from SensorAssemblyGeometries) */
  sensorAssemblyGeometryID: Scalars['Int'],
};

export type SensorAssemblyGeometryUpdate = {
  /** ID of sensor assembly geometry (from SensorAssemblyGeometries) */
  sensorAssemblyGeometryID: Scalars['Int'],
  /** ID of project file containing geometry (from ProjectFileVersions) */
  projectFileVersionID: Scalars['Int'],
  /** Code of external system */
  externalSystemCode: Scalars['String'],
  /** Transformation matrix for relative position inside assembly */
  transformationMatrix: TransformationMatrixCreate,
};

export type SensorAssemblyItem = {
  sensorAssembly: Maybe<SensorAssembly>,
  sensorAssemblyItemExternals: Maybe<Array<Maybe<SensorAssemblyItemExternal>>>,
  sensorAssemblyItemID: Maybe<Scalars['Int']>,
  sensorModelStock: Maybe<SensorModelStock>,
};


export type SensorAssemblyItemSensorAssemblyItemExternalsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type SensorAssemblyItemCreate = {
  /** ID of sensor model stock (from SensorModelsStocks) */
  sensorModelStockID: Scalars['Int'],
  /** Code of external system */
  externalSystemCode: Scalars['String'],
  /** Transformation matrix for relative position inside assembly */
  transformationMatrix: TransformationMatrixCreate,
};

export type SensorAssemblyItemExternal = {
  externalSystem: Maybe<ExternalSystem>,
  sensorAssemblyItem: Maybe<SensorAssemblyItem>,
  sensorAssemblyItemExternalID: Maybe<Scalars['Int']>,
  transformationMatrix: Maybe<TransformationMatrix>,
};

export type SensorAssemblyItemFullCreate = {
  /** ID of sensor model stock (from SensorModelsStocks) */
  sensorModelStockID: Scalars['Int'],
  /** Code of external system */
  externalSystemCode: Scalars['String'],
  /** Transformation matrix for relative position inside assembly */
  transformationMatrix: TransformationMatrixCreate,
  /** ID of sensor assembly (from SensorAssemblies) */
  sensorAssemblyID: Scalars['Int'],
};

export type SensorAssemblyItemFullUpdate = {
  /** ID of sensor model stock (from SensorModelsStocks) */
  sensorModelStockID: Scalars['Int'],
  /** Code of external system */
  externalSystemCode: Scalars['String'],
  /** Transformation matrix for relative position inside assembly */
  transformationMatrix: TransformationMatrixCreate,
  /** ID of sensor assembly (from SensorAssemblies) */
  sensorAssemblyID: Scalars['Int'],
  /** ID of sensor assembly item (from SensorAssemblyItems) */
  sensorAssemblyItemID: Scalars['Int'],
};

export type SensorAssemblyItemUpdate = {
  /** ID of sensor assembly item (from SensorAssemblyItems) */
  sensorAssemblyItemID: Scalars['Int'],
  /** ID of sensor model stocks (from SensorModelsStocks) */
  sensorModelStockID: Scalars['Int'],
  /** Code of external system */
  externalSystemCode: Scalars['String'],
  /** Transformation matrix for relative position inside assembly */
  transformationMatrix: TransformationMatrixCreate,
};

export type SensorAssemblyUpdateType = {
  /** ID of assembly */
  sensorAssemblyID: Scalars['Int'],
  /** Name of assembly */
  name: Scalars['String'],
  /** Description of assembly */
  description: Scalars['String'],
  /** Manufacturer of assembly (redundant) */
  manufacturer: Scalars['String'],
  /** Sensor models to update in assembly */
  sensorAssemblyItems: Maybe<Array<Maybe<SensorAssemblyItemUpdate>>>,
  /** Geometry shapes to update in assembly */
  sensorAssemblyGeometries: Maybe<Array<Maybe<SensorAssemblyGeometryUpdate>>>,
};

export type SensorGraph = {
  doubleValue1: Maybe<Scalars['Float']>,
  doubleValue2: Maybe<Scalars['Float']>,
  doubleValue3: Maybe<Scalars['Float']>,
  graphDate: Maybe<Scalars['Date']>,
  graphTime: Maybe<Scalars['Seconds']>,
  mqqtCode: Maybe<Scalars['String']>,
  sensorGraphID: Maybe<Scalars['Int']>,
  sourceValue: Maybe<Scalars['String']>,
};

export type SensorGraphColor = {
  color: Maybe<Scalars['String']>,
  isDefault: Maybe<Scalars['Boolean']>,
  name: Maybe<Scalars['String']>,
  projectFileVersionSensors: Maybe<Array<Maybe<ProjectFileVersionSensor>>>,
  sensorGraphColorID: Maybe<Scalars['Int']>,
};


export type SensorGraphColorProjectFileVersionSensorsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type SensorGraphColorCreate = {
  /** Name of the color */
  name: Scalars['String'],
  /** CSS Hex code for color (including hash char.) */
  color: Scalars['String'],
  /** If true color will be used for all graphs which does not have color specified */
  isDefault: Scalars['Boolean'],
};

export type SensorGraphColorUpdate = {
  /** Name of the color */
  name: Scalars['String'],
  /** CSS Hex code for color (including hash char.) */
  color: Scalars['String'],
  /** If true color will be used for all graphs which does not have color specified */
  isDefault: Scalars['Boolean'],
  /** ID of SensorGraphColor entity to update (from SensorGraphColors) */
  sensorGraphColorID: Scalars['Int'],
};

export type SensorLocationExternal = {
  externalSystem: Maybe<ExternalSystem>,
  projectFileVersionSensor: Maybe<ProjectFileVersionSensor>,
  sensorLocationExternalID: Maybe<Scalars['Int']>,
  vector: Maybe<Vector>,
};

export type SensorModel = {
  description: Maybe<Scalars['String']>,
  isLocalizationSensor: Maybe<Scalars['Boolean']>,
  manufacturer: Maybe<Scalars['String']>,
  name: Maybe<Scalars['String']>,
  price: Maybe<Scalars['Float']>,
  sensorAssembly: Maybe<SensorAssembly>,
  sensorModelID: Maybe<Scalars['Int']>,
  sensorModelStocks: Maybe<Array<Maybe<SensorModelStock>>>,
  sensorType: Maybe<SensorType>,
  thumbnailImage: Maybe<Scalars['String']>,
};


export type SensorModelSensorModelStocksArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type SensorModelCreate = {
  /** Name of the sensor */
  name: Scalars['String'],
  /** Description of the sensor */
  description: Scalars['String'],
  /** Manufacturer of the sensor */
  manufacturer: Scalars['String'],
  /** URN to thumbnail image of the sensor */
  thumbnailImage: Scalars['String'],
  /** Price of the sensor */
  price: Scalars['Float'],
  /** ID of sensor type (from SensorTypes) */
  sensorTypeID: Scalars['Int'],
  /** ID of sensor assembly (from sensorAssemblies) */
  sensorAssemblyID: Maybe<Scalars['Int']>,
};

export type SensorModelStock = {
  inventaryCode: Maybe<Scalars['String']>,
  mqqtCode: Maybe<Scalars['String']>,
  name: Maybe<Scalars['String']>,
  project: Maybe<Project>,
  projectFileVersionSensors: Maybe<Array<Maybe<ProjectFileVersionSensor>>>,
  sensorAssemblyItems: Maybe<Array<Maybe<SensorAssemblyItem>>>,
  sensorModel: Maybe<SensorModel>,
  sensorModelStockID: Maybe<Scalars['Int']>,
};


export type SensorModelStockProjectFileVersionSensorsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type SensorModelStockSensorAssemblyItemsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type SensorModelStockCreate = {
  /** ID of project (from Projects) */
  projectID: Scalars['Int'],
  /** ID of sensor model (from sensorModels) */
  sensorModelID: Scalars['Int'],
  /** Name of sensor in the project stock (eg. Temperature 1) */
  name: Scalars['String'],
  /** Name of MQTT topic to record data from */
  mqqtCode: Scalars['String'],
  /** Inventary code (facility management) or internal identifier of the device */
  inventaryCode: Scalars['String'],
};

export type SensorModelStockUpdate = {
  /** ID of project (from Projects) */
  projectID: Scalars['Int'],
  /** ID of sensor model (from sensorModels) */
  sensorModelID: Scalars['Int'],
  /** Name of sensor in the project stock (eg. Temperature 1) */
  name: Scalars['String'],
  /** Name of MQTT topic to record data from */
  mqqtCode: Scalars['String'],
  /** Inventary code (facility management) or internal identifier of the device */
  inventaryCode: Scalars['String'],
  /** ID of SensorModelStock entity to update (from SensorModelStock) */
  sensorModelStockID: Scalars['Int'],
};

export type SensorModelUpdate = {
  /** Name of the sensor */
  name: Scalars['String'],
  /** Description of the sensor */
  description: Scalars['String'],
  /** Manufacturer of the sensor */
  manufacturer: Scalars['String'],
  /** URN to thumbnail image of the sensor */
  thumbnailImage: Scalars['String'],
  /** Price of the sensor */
  price: Scalars['Float'],
  /** ID of sensor type (from SensorTypes) */
  sensorTypeID: Scalars['Int'],
  /** ID of sensor assembly (from sensorAssemblies) */
  sensorAssemblyID: Maybe<Scalars['Int']>,
  /** ID of sensor model (from sensorModels) to update */
  sensorModelID: Scalars['Int'],
};

export type SensorType = {
  description: Maybe<Scalars['String']>,
  displayFormat: Maybe<Scalars['String']>,
  isAssembly: Maybe<Scalars['Boolean']>,
  measureUnits: Maybe<Scalars['String']>,
  name: Maybe<Scalars['String']>,
  sensorModels: Maybe<Array<Maybe<SensorModel>>>,
  sensorTypeID: Maybe<Scalars['Int']>,
};


export type SensorTypeSensorModelsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export enum StringComparison {
  CURRENT_CULTURE = 'CURRENT_CULTURE',
  CURRENT_CULTURE_IGNORE_CASE = 'CURRENT_CULTURE_IGNORE_CASE',
  INVARIANT_CULTURE = 'INVARIANT_CULTURE',
  INVARIANT_CULTURE_IGNORE_CASE = 'INVARIANT_CULTURE_IGNORE_CASE',
  ORDINAL = 'ORDINAL',
  ORDINAL_IGNORE_CASE = 'ORDINAL_IGNORE_CASE'
}

export type TransformationMatrix = {
  matrix: Maybe<Scalars['String']>,
  sensorAssemblyGeometryExternals: Maybe<Array<Maybe<SensorAssemblyGeometryExternal>>>,
  sensorAssemblyItemExternals: Maybe<Array<Maybe<SensorAssemblyItemExternal>>>,
  transformationMatrixID: Maybe<Scalars['Int']>,
};


export type TransformationMatrixSensorAssemblyGeometryExternalsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type TransformationMatrixSensorAssemblyItemExternalsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type TransformationMatrixCreate = {
  /** Transformation matrix value (json - might need to be escaped) */
  matrix: Scalars['String'],
};

export type TransformationMatrixUpdate = {
  /** Transformation matrix value (json - might need to be escaped) */
  matrix: Scalars['String'],
  /** ID of transformation matrix for update */
  transformationMatrixID: Scalars['Int'],
};

export type Vector = {
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy: Maybe<Scalars['String']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory: Maybe<Scalars['String']>,
  sensorLocationExternals: Maybe<Array<Maybe<SensorLocationExternal>>>,
  vectorID: Maybe<Scalars['Int']>,
  viewerViewpointCameraVectors: Maybe<Array<Maybe<ViewerViewpoint>>>,
  viewerViewpointDirectionVectors: Maybe<Array<Maybe<ViewerViewpoint>>>,
  viewerViewpointPositionVectors: Maybe<Array<Maybe<ViewerViewpoint>>>,
  x: Maybe<Scalars['Float']>,
  y: Maybe<Scalars['Float']>,
  z: Maybe<Scalars['Float']>,
};


export type VectorSensorLocationExternalsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type VectorViewerViewpointCameraVectorsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type VectorViewerViewpointDirectionVectorsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type VectorViewerViewpointPositionVectorsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type VectorCreate = {
  /** X axis */
  x: Scalars['Float'],
  /** Y axis */
  y: Scalars['Float'],
  /** Z axis */
  z: Scalars['Float'],
};

export type ViewerViewpoint = {
  cameraVector: Maybe<Vector>,
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy: Maybe<Scalars['String']>,
  directionVector: Maybe<Vector>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory: Maybe<Scalars['String']>,
  positionVector: Maybe<Vector>,
  project: Maybe<Project>,
  projectFiles: Maybe<Array<Maybe<ProjectFile>>>,
  projects: Maybe<Array<Maybe<Project>>>,
  viewerViewpointID: Maybe<Scalars['Int']>,
};


export type ViewerViewpointProjectFilesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type ViewerViewpointProjectsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type WhereExpressionGraph = {
  path: Maybe<Scalars['String']>,
  comparison: Maybe<ComparisonGraph>,
  negate: Maybe<Scalars['Boolean']>,
  case: Maybe<StringComparison>,
  value: Maybe<Array<Maybe<Scalars['String']>>>,
  connector: Maybe<Connector>,
  groupedExpressions: Maybe<Array<Maybe<WhereExpressionGraph>>>,
};

export type CreateProjectFileVersionSensorMutationVariables = {
  projectFileVersionID: Scalars['Int'],
  sensorModelStockID: Scalars['Int'],
  sensorGraphColorID: Scalars['Int'],
  x: Scalars['Float'],
  y: Scalars['Float'],
  z: Scalars['Float']
};


export type CreateProjectFileVersionSensorMutation = { createProjectFileVersionSensor: Maybe<(
    Pick<ProjectFileVersionSensor, 'projectFileVersionSensorID' | 'displayGraph' | 'displayName'>
    & { projectFileVersion: Maybe<Pick<ProjectFileVersion, 'projectFileVersionID' | 'name' | 'version'>>, sensorLocationExternals: Maybe<Array<Maybe<(
      Pick<SensorLocationExternal, 'sensorLocationExternalID'>
      & { externalSystem: Maybe<Pick<ExternalSystem, 'externalSystemID' | 'code' | 'name' | 'description'>>, vector: Maybe<Pick<Vector, 'vectorID' | 'x' | 'y' | 'z'>> }
    )>>> }
  )> };

export type UpdateProjectFileVersionSensorMutationVariables = {
  projectFileVersionSensorID: Scalars['Int'],
  displayGraph: Scalars['Boolean'],
  displayName: Scalars['Boolean'],
  vector: Maybe<VectorCreate>,
  projectFileVersionID: Scalars['Int'],
  sensorModelStockID: Scalars['Int'],
  sensorGraphColorID: Scalars['Int']
};


export type UpdateProjectFileVersionSensorMutation = { updateProjectFileVersionSensor: Maybe<Pick<ProjectFileVersionSensor, 'projectFileVersionSensorID'>> };

export type DeleteProjectFileVersionSensorMutationVariables = {
  projectFileVersionSensorID: Scalars['ID']
};


export type DeleteProjectFileVersionSensorMutation = Pick<Mutation, 'deleteProjectFileVersionSensor'>;

export type CreateAssemblyMutationVariables = {
  name: Scalars['String'],
  description: Scalars['String'],
  manufacturer: Scalars['String'],
  assemblyItems: Maybe<Array<Maybe<SensorAssemblyItemCreate>>>,
  geometries: Maybe<Array<Maybe<SensorAssemblyGeometryCreate>>>
};


export type CreateAssemblyMutation = { createSensorAssembly: Maybe<(
    Pick<SensorAssembly, 'name' | 'description' | 'manufacturer' | 'sensorAssemblyID'>
    & { sensorAssemblyGeometries: Maybe<Array<Maybe<(
      Pick<SensorAssemblyGeometry, 'sensorAssemblyGeometryID'>
      & { projectFileVersions: Maybe<Pick<ProjectFileVersion, 'projectFileVersionID' | 'name' | 'description' | 'isValid' | 'version'>>, sensorAssemblyGeometryExternals: Maybe<Array<Maybe<(
        Pick<SensorAssemblyGeometryExternal, 'sensorAssemblyGeometryExternalID'>
        & { transformationMatrix: Maybe<Pick<TransformationMatrix, 'matrix' | 'transformationMatrixID'>> }
      )>>> }
    )>>>, sensorAssemblyItems: Maybe<Array<Maybe<(
      Pick<SensorAssemblyItem, 'sensorAssemblyItemID'>
      & { sensorAssemblyItemExternals: Maybe<Array<Maybe<(
        Pick<SensorAssemblyItemExternal, 'sensorAssemblyItemExternalID'>
        & { transformationMatrix: Maybe<Pick<TransformationMatrix, 'matrix' | 'transformationMatrixID'>> }
      )>>> }
    )>>> }
  )> };

export type UpdateAssemblyMutationVariables = {
  sensorAssemblyID: Scalars['Int'],
  name: Scalars['String'],
  description: Scalars['String'],
  manufacturer: Scalars['String'],
  assemblyItems: Maybe<Array<Maybe<SensorAssemblyItemUpdate>>>,
  geometries: Maybe<Array<Maybe<SensorAssemblyGeometryUpdate>>>
};


export type UpdateAssemblyMutation = { updateSensorAssembly: Maybe<(
    Pick<SensorAssembly, 'name' | 'description' | 'manufacturer' | 'sensorAssemblyID'>
    & { sensorAssemblyGeometries: Maybe<Array<Maybe<(
      Pick<SensorAssemblyGeometry, 'sensorAssemblyGeometryID'>
      & { projectFileVersions: Maybe<Pick<ProjectFileVersion, 'projectFileVersionID' | 'name' | 'description' | 'isValid' | 'version'>>, sensorAssemblyGeometryExternals: Maybe<Array<Maybe<(
        Pick<SensorAssemblyGeometryExternal, 'sensorAssemblyGeometryExternalID'>
        & { transformationMatrix: Maybe<Pick<TransformationMatrix, 'matrix' | 'transformationMatrixID'>> }
      )>>> }
    )>>>, sensorAssemblyItems: Maybe<Array<Maybe<(
      Pick<SensorAssemblyItem, 'sensorAssemblyItemID'>
      & { sensorAssemblyItemExternals: Maybe<Array<Maybe<(
        Pick<SensorAssemblyItemExternal, 'sensorAssemblyItemExternalID'>
        & { transformationMatrix: Maybe<Pick<TransformationMatrix, 'matrix' | 'transformationMatrixID'>> }
      )>>> }
    )>>> }
  )> };

export type CreateSensorModelMutationVariables = {
  sensorModel: SensorModelCreate
};


export type CreateSensorModelMutation = { createSensorModel: Maybe<(
    Pick<SensorModel, 'sensorModelID' | 'name' | 'description' | 'manufacturer' | 'price' | 'thumbnailImage' | 'isLocalizationSensor'>
    & { sensorType: Maybe<Pick<SensorType, 'sensorTypeID' | 'name' | 'description' | 'displayFormat' | 'isAssembly' | 'measureUnits'>> }
  )> };

export type UpdateSensorModelMutationVariables = {};


export type UpdateSensorModelMutation = { updateSensorModel: Maybe<(
    Pick<SensorModel, 'sensorModelID' | 'name' | 'description' | 'manufacturer' | 'price' | 'thumbnailImage' | 'isLocalizationSensor'>
    & { sensorType: Maybe<Pick<SensorType, 'sensorTypeID' | 'name' | 'description' | 'displayFormat' | 'isAssembly' | 'measureUnits'>> }
  )> };

export type CreateSensorModelStockMutationVariables = {
  sensorModelStock: SensorModelStockCreate
};


export type CreateSensorModelStockMutation = { createSensorModelStock: Maybe<(
    Pick<SensorModelStock, 'sensorModelStockID' | 'name' | 'inventaryCode' | 'mqqtCode'>
    & { sensorModel: Maybe<Pick<SensorModel, 'sensorModelID' | 'name' | 'description' | 'manufacturer' | 'price' | 'thumbnailImage' | 'isLocalizationSensor'>> }
  )> };

export type DeleteAssemblyMutationVariables = {
  sensorAssemblyID: Scalars['ID']
};


export type DeleteAssemblyMutation = Pick<Mutation, 'deleteSensorAssembly'>;

export type DeleteSensorModelMutationVariables = {
  sensorModelID: Scalars['ID']
};


export type DeleteSensorModelMutation = Pick<Mutation, 'deleteSensorModel'>;

export type GetSensorTypesQueryVariables = {};


export type GetSensorTypesQuery = { sensorTypes: Maybe<Array<Maybe<Pick<SensorType, 'sensorTypeID' | 'name' | 'isAssembly'>>>> };

export type GetProjectFilesQueryVariables = {
  fileTypeID: Maybe<Scalars['String']>
};


export type GetProjectFilesQuery = { projectFiles: Maybe<Array<Maybe<(
    Pick<ProjectFile, 'projectFileID' | 'name' | 'description' | 'fileName'>
    & { fileType: Maybe<Pick<FileType, 'fileTypeID' | 'code' | 'name' | 'isModel'>>, projectFileVersions: Maybe<Array<Maybe<(
      Pick<ProjectFileVersion, 'projectFileVersionID'>
      & { projectFileVersionExternals: Maybe<Array<Maybe<(
        Pick<ProjectFileVersionExternal, 'externalID'>
        & { externalSystem: Maybe<Pick<ExternalSystem, 'code'>> }
      )>>> }
    )>>> }
  )>>> };

export type GetSensorModelStocksByTypeQueryVariables = {
  typeIds: Maybe<Array<Maybe<Scalars['String']>>>
};


export type GetSensorModelStocksByTypeQuery = { sensorModelStocks: Maybe<Array<Maybe<(
    Pick<SensorModelStock, 'sensorModelStockID' | 'inventaryCode' | 'name' | 'mqqtCode'>
    & { projectFileVersionSensors: Maybe<Array<Maybe<{ sensorLocationExternals: Maybe<Array<Maybe<{ externalSystem: Maybe<Pick<ExternalSystem, 'code'>>, vector: Maybe<Pick<Vector, 'x' | 'y' | 'z'>> }>>> }>>>, sensorAssemblyItems: Maybe<Array<Maybe<Pick<SensorAssemblyItem, 'sensorAssemblyItemID'>>>>, sensorModel: Maybe<(
      Pick<SensorModel, 'sensorModelID' | 'name' | 'description' | 'isLocalizationSensor' | 'manufacturer' | 'price' | 'thumbnailImage'>
      & { sensorType: Maybe<Pick<SensorType, 'sensorTypeID' | 'name' | 'description' | 'displayFormat' | 'measureUnits'>>, sensorAssembly: Maybe<Pick<SensorAssembly, 'sensorAssemblyID'>> }
    )> }
  )>>> };

export type GetProjectFileVersionSensorsQueryVariables = {
  projectFileVersionID: Scalars['Int']
};


export type GetProjectFileVersionSensorsQuery = { projectFileVersionSensorsWithLastGraphsType: Maybe<{ projectFileVersionSensors: Maybe<Array<Maybe<(
      Pick<ProjectFileVersionSensor, 'projectFileVersionSensorID' | 'displayGraph' | 'displayName'>
      & { sensorModelStocks: Maybe<(
        Pick<SensorModelStock, 'sensorModelStockID' | 'name' | 'mqqtCode' | 'inventaryCode'>
        & { sensorModel: Maybe<(
          Pick<SensorModel, 'name' | 'description' | 'thumbnailImage' | 'isLocalizationSensor'>
          & { sensorAssembly: Maybe<{ sensorAssemblyGeometries: Maybe<Array<Maybe<(
              Pick<SensorAssemblyGeometry, 'sensorAssemblyGeometryID'>
              & { projectFileVersions: Maybe<{ projectFileVersionExternals: Maybe<Array<Maybe<(
                  Pick<ProjectFileVersionExternal, 'externalID'>
                  & { externalSystem: Maybe<Pick<ExternalSystem, 'code'>> }
                )>>> }>, sensorAssemblyGeometryExternals: Maybe<Array<Maybe<{ externalSystem: Maybe<Pick<ExternalSystem, 'code'>>, transformationMatrix: Maybe<Pick<TransformationMatrix, 'matrix'>> }>>> }
            )>>>, sensorAssemblyItems: Maybe<Array<Maybe<{ sensorModelStock: Maybe<(
                Pick<SensorModelStock, 'sensorModelStockID' | 'mqqtCode' | 'inventaryCode'>
                & { sensorModel: Maybe<Pick<SensorModel, 'isLocalizationSensor'>> }
              )>, sensorAssemblyItemExternals: Maybe<Array<Maybe<{ externalSystem: Maybe<Pick<ExternalSystem, 'code'>>, transformationMatrix: Maybe<Pick<TransformationMatrix, 'matrix'>> }>>> }>>> }>, sensorType: Maybe<Pick<SensorType, 'sensorTypeID' | 'name' | 'description' | 'displayFormat' | 'measureUnits' | 'isAssembly'>> }
        )> }
      )>, projectFileVersion: Maybe<Pick<ProjectFileVersion, 'projectFileVersionID'>>, sensorGraphColors: Maybe<Pick<SensorGraphColor, 'sensorGraphColorID' | 'name' | 'color' | 'isDefault'>>, sensorLocationExternals: Maybe<Array<Maybe<{ externalSystem: Maybe<Pick<ExternalSystem, 'code'>>, vector: Maybe<Pick<Vector, 'x' | 'y' | 'z'>> }>>> }
    )>>>, lastSensorGraphs: Maybe<Array<Maybe<Pick<SensorGraph, 'mqqtCode' | 'graphDate' | 'graphTime' | 'sourceValue' | 'doubleValue1' | 'doubleValue2' | 'doubleValue3'>>>> }> };

export type GetSensorGraphDefaultColorQueryVariables = {};


export type GetSensorGraphDefaultColorQuery = { sensorGraphColors: Maybe<Array<Maybe<Pick<SensorGraphColor, 'sensorGraphColorID' | 'name' | 'color' | 'isDefault'>>>> };

export type GetSensorGraphColorByIdQueryVariables = {
  colorId: Maybe<Scalars['String']>
};


export type GetSensorGraphColorByIdQuery = { sensorGraphColors: Maybe<Array<Maybe<Pick<SensorGraphColor, 'sensorGraphColorID' | 'name' | 'color' | 'isDefault'>>>> };

export type GetSensorGraphColorsQueryVariables = {};


export type GetSensorGraphColorsQuery = { sensorGraphColors: Maybe<Array<Maybe<Pick<SensorGraphColor, 'sensorGraphColorID' | 'name' | 'color' | 'isDefault'>>>> };

export type GetSensorGraphsQueryVariables = {
  mqqtCode: Maybe<Scalars['String']>,
  take: Maybe<Scalars['Int']>,
  descending: Maybe<Scalars['Boolean']>
};


export type GetSensorGraphsQuery = { sensorGraphs: Maybe<Array<Maybe<Pick<SensorGraph, 'sensorGraphID' | 'graphDate' | 'graphTime' | 'mqqtCode' | 'sourceValue' | 'doubleValue1' | 'doubleValue2' | 'doubleValue3'>>>> };

export type GetLastGraphsQueryVariables = {
  mqqtCode: Maybe<Array<Maybe<Scalars['String']>>>
};


export type GetLastGraphsQuery = { sensorGraphs: Maybe<Array<Maybe<Pick<SensorGraph, 'sensorGraphID' | 'graphDate' | 'graphTime' | 'mqqtCode' | 'sourceValue' | 'doubleValue1' | 'doubleValue2' | 'doubleValue3'>>>> };

export type GetAssemblyDetailsQueryVariables = {
  assemblyID: Scalars['String']
};


export type GetAssemblyDetailsQuery = { sensorAssemblies: Maybe<Array<Maybe<(
    Pick<SensorAssembly, 'sensorAssemblyID' | 'name' | 'description' | 'manufacturer'>
    & { sensorAssemblyItems: Maybe<Array<Maybe<(
      Pick<SensorAssemblyItem, 'sensorAssemblyItemID'>
      & { sensorModelStock: Maybe<(
        Pick<SensorModelStock, 'sensorModelStockID' | 'mqqtCode'>
        & { sensorModel: Maybe<(
          Pick<SensorModel, 'sensorModelID' | 'name' | 'description' | 'manufacturer' | 'isLocalizationSensor'>
          & { sensorType: Maybe<Pick<SensorType, 'name'>> }
        )> }
      )>, sensorAssemblyItemExternals: Maybe<Array<Maybe<(
        Pick<SensorAssemblyItemExternal, 'sensorAssemblyItemExternalID'>
        & { externalSystem: Maybe<Pick<ExternalSystem, 'code'>>, transformationMatrix: Maybe<Pick<TransformationMatrix, 'transformationMatrixID' | 'matrix'>> }
      )>>> }
    )>>>, sensorAssemblyGeometries: Maybe<Array<Maybe<(
      Pick<SensorAssemblyGeometry, 'sensorAssemblyGeometryID'>
      & { projectFileVersions: Maybe<(
        Pick<ProjectFileVersion, 'projectFileVersionID' | 'name' | 'description' | 'version'>
        & { projectFile: Maybe<Pick<ProjectFile, 'projectFileID' | 'name' | 'fileName' | 'description' | 'priority'>>, projectFileVersionExternals: Maybe<Array<Maybe<(
          Pick<ProjectFileVersionExternal, 'externalID'>
          & { externalSystem: Maybe<Pick<ExternalSystem, 'code'>> }
        )>>> }
      )>, sensorAssemblyGeometryExternals: Maybe<Array<Maybe<(
        Pick<SensorAssemblyGeometryExternal, 'sensorAssemblyGeometryExternalID'>
        & { externalSystem: Maybe<Pick<ExternalSystem, 'code'>>, transformationMatrix: Maybe<Pick<TransformationMatrix, 'transformationMatrixID' | 'matrix'>> }
      )>>> }
    )>>> }
  )>>> };


export const CreateProjectFileVersionSensorDocument = gql`
    mutation CreateProjectFileVersionSensor($projectFileVersionID: Int!, $sensorModelStockID: Int!, $sensorGraphColorID: Int!, $x: Float!, $y: Float!, $z: Float!) {
  createProjectFileVersionSensor(projectFileVersionSensor: {projectFileVersionID: $projectFileVersionID, sensorModelStockID: $sensorModelStockID, displayGraph: true, displayName: true, sensorGraphColorID: $sensorGraphColorID, externalSystemCode: "forge", vector: {x: $x, y: $y, z: $z}}) {
    projectFileVersionSensorID
    displayGraph
    displayName
    projectFileVersion {
      projectFileVersionID
      name
      version
    }
    sensorLocationExternals {
      sensorLocationExternalID
      externalSystem {
        externalSystemID
        code
        name
        description
      }
      vector {
        vectorID
        x
        y
        z
      }
    }
  }
}
    `;
export type CreateProjectFileVersionSensorMutationFn = ApolloReactCommon.MutationFunction<CreateProjectFileVersionSensorMutation, CreateProjectFileVersionSensorMutationVariables>;
export type CreateProjectFileVersionSensorComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateProjectFileVersionSensorMutation, CreateProjectFileVersionSensorMutationVariables>, 'mutation'>;

    export const CreateProjectFileVersionSensorComponent = (props: CreateProjectFileVersionSensorComponentProps) => (
      <ApolloReactComponents.Mutation<CreateProjectFileVersionSensorMutation, CreateProjectFileVersionSensorMutationVariables> mutation={CreateProjectFileVersionSensorDocument} {...props} />
    );
    

/**
 * __useCreateProjectFileVersionSensorMutation__
 *
 * To run a mutation, you first call `useCreateProjectFileVersionSensorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectFileVersionSensorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectFileVersionSensorMutation, { data, loading, error }] = useCreateProjectFileVersionSensorMutation({
 *   variables: {
 *      projectFileVersionID: // value for 'projectFileVersionID'
 *      sensorModelStockID: // value for 'sensorModelStockID'
 *      sensorGraphColorID: // value for 'sensorGraphColorID'
 *      x: // value for 'x'
 *      y: // value for 'y'
 *      z: // value for 'z'
 *   },
 * });
 */
export function useCreateProjectFileVersionSensorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProjectFileVersionSensorMutation, CreateProjectFileVersionSensorMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProjectFileVersionSensorMutation, CreateProjectFileVersionSensorMutationVariables>(CreateProjectFileVersionSensorDocument, baseOptions);
      }
export type CreateProjectFileVersionSensorMutationHookResult = ReturnType<typeof useCreateProjectFileVersionSensorMutation>;
export type CreateProjectFileVersionSensorMutationResult = ApolloReactCommon.MutationResult<CreateProjectFileVersionSensorMutation>;
export type CreateProjectFileVersionSensorMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProjectFileVersionSensorMutation, CreateProjectFileVersionSensorMutationVariables>;
export const UpdateProjectFileVersionSensorDocument = gql`
    mutation UpdateProjectFileVersionSensor($projectFileVersionSensorID: Int!, $displayGraph: Boolean!, $displayName: Boolean!, $vector: vectorCreate, $projectFileVersionID: Int!, $sensorModelStockID: Int!, $sensorGraphColorID: Int!) {
  updateProjectFileVersionSensor(projectFileVersionSensor: {projectFileVersionSensorID: $projectFileVersionSensorID, projectFileVersionID: $projectFileVersionID, sensorModelStockID: $sensorModelStockID, displayGraph: $displayGraph, displayName: $displayName, sensorGraphColorID: $sensorGraphColorID, externalSystemCode: "forge", vector: $vector}) {
    projectFileVersionSensorID
  }
}
    `;
export type UpdateProjectFileVersionSensorMutationFn = ApolloReactCommon.MutationFunction<UpdateProjectFileVersionSensorMutation, UpdateProjectFileVersionSensorMutationVariables>;
export type UpdateProjectFileVersionSensorComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateProjectFileVersionSensorMutation, UpdateProjectFileVersionSensorMutationVariables>, 'mutation'>;

    export const UpdateProjectFileVersionSensorComponent = (props: UpdateProjectFileVersionSensorComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateProjectFileVersionSensorMutation, UpdateProjectFileVersionSensorMutationVariables> mutation={UpdateProjectFileVersionSensorDocument} {...props} />
    );
    

/**
 * __useUpdateProjectFileVersionSensorMutation__
 *
 * To run a mutation, you first call `useUpdateProjectFileVersionSensorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectFileVersionSensorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectFileVersionSensorMutation, { data, loading, error }] = useUpdateProjectFileVersionSensorMutation({
 *   variables: {
 *      projectFileVersionSensorID: // value for 'projectFileVersionSensorID'
 *      displayGraph: // value for 'displayGraph'
 *      displayName: // value for 'displayName'
 *      vector: // value for 'vector'
 *      projectFileVersionID: // value for 'projectFileVersionID'
 *      sensorModelStockID: // value for 'sensorModelStockID'
 *      sensorGraphColorID: // value for 'sensorGraphColorID'
 *   },
 * });
 */
export function useUpdateProjectFileVersionSensorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProjectFileVersionSensorMutation, UpdateProjectFileVersionSensorMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProjectFileVersionSensorMutation, UpdateProjectFileVersionSensorMutationVariables>(UpdateProjectFileVersionSensorDocument, baseOptions);
      }
export type UpdateProjectFileVersionSensorMutationHookResult = ReturnType<typeof useUpdateProjectFileVersionSensorMutation>;
export type UpdateProjectFileVersionSensorMutationResult = ApolloReactCommon.MutationResult<UpdateProjectFileVersionSensorMutation>;
export type UpdateProjectFileVersionSensorMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProjectFileVersionSensorMutation, UpdateProjectFileVersionSensorMutationVariables>;
export const DeleteProjectFileVersionSensorDocument = gql`
    mutation DeleteProjectFileVersionSensor($projectFileVersionSensorID: ID!) {
  deleteProjectFileVersionSensor(projectFileVersionSensor: $projectFileVersionSensorID)
}
    `;
export type DeleteProjectFileVersionSensorMutationFn = ApolloReactCommon.MutationFunction<DeleteProjectFileVersionSensorMutation, DeleteProjectFileVersionSensorMutationVariables>;
export type DeleteProjectFileVersionSensorComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteProjectFileVersionSensorMutation, DeleteProjectFileVersionSensorMutationVariables>, 'mutation'>;

    export const DeleteProjectFileVersionSensorComponent = (props: DeleteProjectFileVersionSensorComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteProjectFileVersionSensorMutation, DeleteProjectFileVersionSensorMutationVariables> mutation={DeleteProjectFileVersionSensorDocument} {...props} />
    );
    

/**
 * __useDeleteProjectFileVersionSensorMutation__
 *
 * To run a mutation, you first call `useDeleteProjectFileVersionSensorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectFileVersionSensorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectFileVersionSensorMutation, { data, loading, error }] = useDeleteProjectFileVersionSensorMutation({
 *   variables: {
 *      projectFileVersionSensorID: // value for 'projectFileVersionSensorID'
 *   },
 * });
 */
export function useDeleteProjectFileVersionSensorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProjectFileVersionSensorMutation, DeleteProjectFileVersionSensorMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProjectFileVersionSensorMutation, DeleteProjectFileVersionSensorMutationVariables>(DeleteProjectFileVersionSensorDocument, baseOptions);
      }
export type DeleteProjectFileVersionSensorMutationHookResult = ReturnType<typeof useDeleteProjectFileVersionSensorMutation>;
export type DeleteProjectFileVersionSensorMutationResult = ApolloReactCommon.MutationResult<DeleteProjectFileVersionSensorMutation>;
export type DeleteProjectFileVersionSensorMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProjectFileVersionSensorMutation, DeleteProjectFileVersionSensorMutationVariables>;
export const CreateAssemblyDocument = gql`
    mutation CreateAssembly($name: String!, $description: String!, $manufacturer: String!, $assemblyItems: [sensorAssemblyItemCreate], $geometries: [sensorAssemblyGeometryCreate]) {
  createSensorAssembly(sensorAssembly: {name: $name, description: $description, manufacturer: $manufacturer, sensorAssemblyItems: $assemblyItems, sensorAssemblyGeometries: $geometries}) {
    name
    description
    manufacturer
    sensorAssemblyID
    sensorAssemblyGeometries {
      sensorAssemblyGeometryID
      projectFileVersions {
        projectFileVersionID
        name
        description
        isValid
        version
      }
      sensorAssemblyGeometryExternals {
        sensorAssemblyGeometryExternalID
        transformationMatrix {
          matrix
          transformationMatrixID
        }
      }
    }
    sensorAssemblyItems {
      sensorAssemblyItemID
      sensorAssemblyItemExternals {
        sensorAssemblyItemExternalID
        transformationMatrix {
          matrix
          transformationMatrixID
        }
      }
    }
  }
}
    `;
export type CreateAssemblyMutationFn = ApolloReactCommon.MutationFunction<CreateAssemblyMutation, CreateAssemblyMutationVariables>;
export type CreateAssemblyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateAssemblyMutation, CreateAssemblyMutationVariables>, 'mutation'>;

    export const CreateAssemblyComponent = (props: CreateAssemblyComponentProps) => (
      <ApolloReactComponents.Mutation<CreateAssemblyMutation, CreateAssemblyMutationVariables> mutation={CreateAssemblyDocument} {...props} />
    );
    

/**
 * __useCreateAssemblyMutation__
 *
 * To run a mutation, you first call `useCreateAssemblyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssemblyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssemblyMutation, { data, loading, error }] = useCreateAssemblyMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      manufacturer: // value for 'manufacturer'
 *      assemblyItems: // value for 'assemblyItems'
 *      geometries: // value for 'geometries'
 *   },
 * });
 */
export function useCreateAssemblyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAssemblyMutation, CreateAssemblyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAssemblyMutation, CreateAssemblyMutationVariables>(CreateAssemblyDocument, baseOptions);
      }
export type CreateAssemblyMutationHookResult = ReturnType<typeof useCreateAssemblyMutation>;
export type CreateAssemblyMutationResult = ApolloReactCommon.MutationResult<CreateAssemblyMutation>;
export type CreateAssemblyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAssemblyMutation, CreateAssemblyMutationVariables>;
export const UpdateAssemblyDocument = gql`
    mutation UpdateAssembly($sensorAssemblyID: Int!, $name: String!, $description: String!, $manufacturer: String!, $assemblyItems: [sensorAssemblyItemUpdate], $geometries: [sensorAssemblyGeometryUpdate]) {
  updateSensorAssembly(sensorAssembly: {sensorAssemblyID: $sensorAssemblyID, name: $name, description: $description, manufacturer: $manufacturer, sensorAssemblyItems: $assemblyItems, sensorAssemblyGeometries: $geometries}) {
    name
    description
    manufacturer
    sensorAssemblyID
    sensorAssemblyGeometries {
      sensorAssemblyGeometryID
      projectFileVersions {
        projectFileVersionID
        name
        description
        isValid
        version
      }
      sensorAssemblyGeometryExternals {
        sensorAssemblyGeometryExternalID
        transformationMatrix {
          matrix
          transformationMatrixID
        }
      }
    }
    sensorAssemblyItems {
      sensorAssemblyItemID
      sensorAssemblyItemExternals {
        sensorAssemblyItemExternalID
        transformationMatrix {
          matrix
          transformationMatrixID
        }
      }
    }
  }
}
    `;
export type UpdateAssemblyMutationFn = ApolloReactCommon.MutationFunction<UpdateAssemblyMutation, UpdateAssemblyMutationVariables>;
export type UpdateAssemblyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateAssemblyMutation, UpdateAssemblyMutationVariables>, 'mutation'>;

    export const UpdateAssemblyComponent = (props: UpdateAssemblyComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateAssemblyMutation, UpdateAssemblyMutationVariables> mutation={UpdateAssemblyDocument} {...props} />
    );
    

/**
 * __useUpdateAssemblyMutation__
 *
 * To run a mutation, you first call `useUpdateAssemblyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssemblyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssemblyMutation, { data, loading, error }] = useUpdateAssemblyMutation({
 *   variables: {
 *      sensorAssemblyID: // value for 'sensorAssemblyID'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      manufacturer: // value for 'manufacturer'
 *      assemblyItems: // value for 'assemblyItems'
 *      geometries: // value for 'geometries'
 *   },
 * });
 */
export function useUpdateAssemblyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAssemblyMutation, UpdateAssemblyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAssemblyMutation, UpdateAssemblyMutationVariables>(UpdateAssemblyDocument, baseOptions);
      }
export type UpdateAssemblyMutationHookResult = ReturnType<typeof useUpdateAssemblyMutation>;
export type UpdateAssemblyMutationResult = ApolloReactCommon.MutationResult<UpdateAssemblyMutation>;
export type UpdateAssemblyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAssemblyMutation, UpdateAssemblyMutationVariables>;
export const CreateSensorModelDocument = gql`
    mutation CreateSensorModel($sensorModel: sensorModelCreate!) {
  createSensorModel(sensorModel: $sensorModel) {
    sensorModelID
    name
    description
    manufacturer
    price
    thumbnailImage
    isLocalizationSensor
    sensorType {
      sensorTypeID
      name
      description
      displayFormat
      isAssembly
      measureUnits
    }
  }
}
    `;
export type CreateSensorModelMutationFn = ApolloReactCommon.MutationFunction<CreateSensorModelMutation, CreateSensorModelMutationVariables>;
export type CreateSensorModelComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateSensorModelMutation, CreateSensorModelMutationVariables>, 'mutation'>;

    export const CreateSensorModelComponent = (props: CreateSensorModelComponentProps) => (
      <ApolloReactComponents.Mutation<CreateSensorModelMutation, CreateSensorModelMutationVariables> mutation={CreateSensorModelDocument} {...props} />
    );
    

/**
 * __useCreateSensorModelMutation__
 *
 * To run a mutation, you first call `useCreateSensorModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSensorModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSensorModelMutation, { data, loading, error }] = useCreateSensorModelMutation({
 *   variables: {
 *      sensorModel: // value for 'sensorModel'
 *   },
 * });
 */
export function useCreateSensorModelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSensorModelMutation, CreateSensorModelMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSensorModelMutation, CreateSensorModelMutationVariables>(CreateSensorModelDocument, baseOptions);
      }
export type CreateSensorModelMutationHookResult = ReturnType<typeof useCreateSensorModelMutation>;
export type CreateSensorModelMutationResult = ApolloReactCommon.MutationResult<CreateSensorModelMutation>;
export type CreateSensorModelMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSensorModelMutation, CreateSensorModelMutationVariables>;
export const UpdateSensorModelDocument = gql`
    mutation UpdateSensorModel {
  updateSensorModel(sensorModel: {sensorModelID: 5, name: "My temperature sensor model - modified", description: "Some description for sensor model - modified", manufacturer: "Sensor industries - modified", thumbnailImage: "urn:newfakeurntoimagefile", price: 120, sensorTypeID: 1}) {
    sensorModelID
    name
    description
    manufacturer
    price
    thumbnailImage
    isLocalizationSensor
    sensorType {
      sensorTypeID
      name
      description
      displayFormat
      isAssembly
      measureUnits
    }
  }
}
    `;
export type UpdateSensorModelMutationFn = ApolloReactCommon.MutationFunction<UpdateSensorModelMutation, UpdateSensorModelMutationVariables>;
export type UpdateSensorModelComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateSensorModelMutation, UpdateSensorModelMutationVariables>, 'mutation'>;

    export const UpdateSensorModelComponent = (props: UpdateSensorModelComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateSensorModelMutation, UpdateSensorModelMutationVariables> mutation={UpdateSensorModelDocument} {...props} />
    );
    

/**
 * __useUpdateSensorModelMutation__
 *
 * To run a mutation, you first call `useUpdateSensorModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSensorModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSensorModelMutation, { data, loading, error }] = useUpdateSensorModelMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdateSensorModelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSensorModelMutation, UpdateSensorModelMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSensorModelMutation, UpdateSensorModelMutationVariables>(UpdateSensorModelDocument, baseOptions);
      }
export type UpdateSensorModelMutationHookResult = ReturnType<typeof useUpdateSensorModelMutation>;
export type UpdateSensorModelMutationResult = ApolloReactCommon.MutationResult<UpdateSensorModelMutation>;
export type UpdateSensorModelMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSensorModelMutation, UpdateSensorModelMutationVariables>;
export const CreateSensorModelStockDocument = gql`
    mutation CreateSensorModelStock($sensorModelStock: sensorModelStockCreate!) {
  createSensorModelStock(sensorModelStock: $sensorModelStock) {
    sensorModelStockID
    name
    inventaryCode
    mqqtCode
    sensorModel {
      sensorModelID
      name
      description
      manufacturer
      price
      thumbnailImage
      isLocalizationSensor
    }
  }
}
    `;
export type CreateSensorModelStockMutationFn = ApolloReactCommon.MutationFunction<CreateSensorModelStockMutation, CreateSensorModelStockMutationVariables>;
export type CreateSensorModelStockComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateSensorModelStockMutation, CreateSensorModelStockMutationVariables>, 'mutation'>;

    export const CreateSensorModelStockComponent = (props: CreateSensorModelStockComponentProps) => (
      <ApolloReactComponents.Mutation<CreateSensorModelStockMutation, CreateSensorModelStockMutationVariables> mutation={CreateSensorModelStockDocument} {...props} />
    );
    

/**
 * __useCreateSensorModelStockMutation__
 *
 * To run a mutation, you first call `useCreateSensorModelStockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSensorModelStockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSensorModelStockMutation, { data, loading, error }] = useCreateSensorModelStockMutation({
 *   variables: {
 *      sensorModelStock: // value for 'sensorModelStock'
 *   },
 * });
 */
export function useCreateSensorModelStockMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSensorModelStockMutation, CreateSensorModelStockMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSensorModelStockMutation, CreateSensorModelStockMutationVariables>(CreateSensorModelStockDocument, baseOptions);
      }
export type CreateSensorModelStockMutationHookResult = ReturnType<typeof useCreateSensorModelStockMutation>;
export type CreateSensorModelStockMutationResult = ApolloReactCommon.MutationResult<CreateSensorModelStockMutation>;
export type CreateSensorModelStockMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSensorModelStockMutation, CreateSensorModelStockMutationVariables>;
export const DeleteAssemblyDocument = gql`
    mutation DeleteAssembly($sensorAssemblyID: ID!) {
  deleteSensorAssembly(sensorAssembly: $sensorAssemblyID)
}
    `;
export type DeleteAssemblyMutationFn = ApolloReactCommon.MutationFunction<DeleteAssemblyMutation, DeleteAssemblyMutationVariables>;
export type DeleteAssemblyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteAssemblyMutation, DeleteAssemblyMutationVariables>, 'mutation'>;

    export const DeleteAssemblyComponent = (props: DeleteAssemblyComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteAssemblyMutation, DeleteAssemblyMutationVariables> mutation={DeleteAssemblyDocument} {...props} />
    );
    

/**
 * __useDeleteAssemblyMutation__
 *
 * To run a mutation, you first call `useDeleteAssemblyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssemblyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssemblyMutation, { data, loading, error }] = useDeleteAssemblyMutation({
 *   variables: {
 *      sensorAssemblyID: // value for 'sensorAssemblyID'
 *   },
 * });
 */
export function useDeleteAssemblyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAssemblyMutation, DeleteAssemblyMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAssemblyMutation, DeleteAssemblyMutationVariables>(DeleteAssemblyDocument, baseOptions);
      }
export type DeleteAssemblyMutationHookResult = ReturnType<typeof useDeleteAssemblyMutation>;
export type DeleteAssemblyMutationResult = ApolloReactCommon.MutationResult<DeleteAssemblyMutation>;
export type DeleteAssemblyMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAssemblyMutation, DeleteAssemblyMutationVariables>;
export const DeleteSensorModelDocument = gql`
    mutation DeleteSensorModel($sensorModelID: ID!) {
  deleteSensorModel(sensorModel: $sensorModelID)
}
    `;
export type DeleteSensorModelMutationFn = ApolloReactCommon.MutationFunction<DeleteSensorModelMutation, DeleteSensorModelMutationVariables>;
export type DeleteSensorModelComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteSensorModelMutation, DeleteSensorModelMutationVariables>, 'mutation'>;

    export const DeleteSensorModelComponent = (props: DeleteSensorModelComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteSensorModelMutation, DeleteSensorModelMutationVariables> mutation={DeleteSensorModelDocument} {...props} />
    );
    

/**
 * __useDeleteSensorModelMutation__
 *
 * To run a mutation, you first call `useDeleteSensorModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSensorModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSensorModelMutation, { data, loading, error }] = useDeleteSensorModelMutation({
 *   variables: {
 *      sensorModelID: // value for 'sensorModelID'
 *   },
 * });
 */
export function useDeleteSensorModelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSensorModelMutation, DeleteSensorModelMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSensorModelMutation, DeleteSensorModelMutationVariables>(DeleteSensorModelDocument, baseOptions);
      }
export type DeleteSensorModelMutationHookResult = ReturnType<typeof useDeleteSensorModelMutation>;
export type DeleteSensorModelMutationResult = ApolloReactCommon.MutationResult<DeleteSensorModelMutation>;
export type DeleteSensorModelMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSensorModelMutation, DeleteSensorModelMutationVariables>;
export const GetSensorTypesDocument = gql`
    query getSensorTypes {
  sensorTypes {
    sensorTypeID
    name
    isAssembly
  }
}
    `;
export type GetSensorTypesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetSensorTypesQuery, GetSensorTypesQueryVariables>, 'query'>;

    export const GetSensorTypesComponent = (props: GetSensorTypesComponentProps) => (
      <ApolloReactComponents.Query<GetSensorTypesQuery, GetSensorTypesQueryVariables> query={GetSensorTypesDocument} {...props} />
    );
    

/**
 * __useGetSensorTypesQuery__
 *
 * To run a query within a React component, call `useGetSensorTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSensorTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSensorTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSensorTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSensorTypesQuery, GetSensorTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSensorTypesQuery, GetSensorTypesQueryVariables>(GetSensorTypesDocument, baseOptions);
      }
export function useGetSensorTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSensorTypesQuery, GetSensorTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSensorTypesQuery, GetSensorTypesQueryVariables>(GetSensorTypesDocument, baseOptions);
        }
export type GetSensorTypesQueryHookResult = ReturnType<typeof useGetSensorTypesQuery>;
export type GetSensorTypesLazyQueryHookResult = ReturnType<typeof useGetSensorTypesLazyQuery>;
export type GetSensorTypesQueryResult = ApolloReactCommon.QueryResult<GetSensorTypesQuery, GetSensorTypesQueryVariables>;
export const GetProjectFilesDocument = gql`
    query getProjectFiles($fileTypeID: String) {
  projectFiles(where: {path: "fileTypeID", comparison: equal, value: [$fileTypeID]}) {
    projectFileID
    name
    description
    fileName
    fileType {
      fileTypeID
      code
      name
      isModel
    }
    projectFileVersions(take: 1, orderBy: [{path: "version", descending: true}]) {
      projectFileVersionID
      projectFileVersionExternals(where: [{path: "externalSystem.code", value: "forge"}]) {
        externalID
        externalSystem {
          code
        }
      }
    }
  }
}
    `;
export type GetProjectFilesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProjectFilesQuery, GetProjectFilesQueryVariables>, 'query'>;

    export const GetProjectFilesComponent = (props: GetProjectFilesComponentProps) => (
      <ApolloReactComponents.Query<GetProjectFilesQuery, GetProjectFilesQueryVariables> query={GetProjectFilesDocument} {...props} />
    );
    

/**
 * __useGetProjectFilesQuery__
 *
 * To run a query within a React component, call `useGetProjectFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectFilesQuery({
 *   variables: {
 *      fileTypeID: // value for 'fileTypeID'
 *   },
 * });
 */
export function useGetProjectFilesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectFilesQuery, GetProjectFilesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectFilesQuery, GetProjectFilesQueryVariables>(GetProjectFilesDocument, baseOptions);
      }
export function useGetProjectFilesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectFilesQuery, GetProjectFilesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectFilesQuery, GetProjectFilesQueryVariables>(GetProjectFilesDocument, baseOptions);
        }
export type GetProjectFilesQueryHookResult = ReturnType<typeof useGetProjectFilesQuery>;
export type GetProjectFilesLazyQueryHookResult = ReturnType<typeof useGetProjectFilesLazyQuery>;
export type GetProjectFilesQueryResult = ApolloReactCommon.QueryResult<GetProjectFilesQuery, GetProjectFilesQueryVariables>;
export const GetSensorModelStocksByTypeDocument = gql`
    query getSensorModelStocksByType($typeIds: [String]) {
  sensorModelStocks(where: {path: "sensorModel.sensorTypeID", comparison: in, value: $typeIds}) {
    sensorModelStockID
    inventaryCode
    name
    mqqtCode
    projectFileVersionSensors {
      sensorLocationExternals(where: {path: "externalSystem.code", value: "forge"}) {
        externalSystem {
          code
        }
        vector {
          x
          y
          z
        }
      }
    }
    sensorAssemblyItems {
      sensorAssemblyItemID
    }
    sensorModel {
      sensorModelID
      name
      description
      isLocalizationSensor
      manufacturer
      price
      thumbnailImage
      sensorType {
        sensorTypeID
        name
        description
        displayFormat
        measureUnits
      }
      sensorAssembly {
        sensorAssemblyID
      }
    }
  }
}
    `;
export type GetSensorModelStocksByTypeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetSensorModelStocksByTypeQuery, GetSensorModelStocksByTypeQueryVariables>, 'query'>;

    export const GetSensorModelStocksByTypeComponent = (props: GetSensorModelStocksByTypeComponentProps) => (
      <ApolloReactComponents.Query<GetSensorModelStocksByTypeQuery, GetSensorModelStocksByTypeQueryVariables> query={GetSensorModelStocksByTypeDocument} {...props} />
    );
    

/**
 * __useGetSensorModelStocksByTypeQuery__
 *
 * To run a query within a React component, call `useGetSensorModelStocksByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSensorModelStocksByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSensorModelStocksByTypeQuery({
 *   variables: {
 *      typeIds: // value for 'typeIds'
 *   },
 * });
 */
export function useGetSensorModelStocksByTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSensorModelStocksByTypeQuery, GetSensorModelStocksByTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSensorModelStocksByTypeQuery, GetSensorModelStocksByTypeQueryVariables>(GetSensorModelStocksByTypeDocument, baseOptions);
      }
export function useGetSensorModelStocksByTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSensorModelStocksByTypeQuery, GetSensorModelStocksByTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSensorModelStocksByTypeQuery, GetSensorModelStocksByTypeQueryVariables>(GetSensorModelStocksByTypeDocument, baseOptions);
        }
export type GetSensorModelStocksByTypeQueryHookResult = ReturnType<typeof useGetSensorModelStocksByTypeQuery>;
export type GetSensorModelStocksByTypeLazyQueryHookResult = ReturnType<typeof useGetSensorModelStocksByTypeLazyQuery>;
export type GetSensorModelStocksByTypeQueryResult = ApolloReactCommon.QueryResult<GetSensorModelStocksByTypeQuery, GetSensorModelStocksByTypeQueryVariables>;
export const GetProjectFileVersionSensorsDocument = gql`
    query getProjectFileVersionSensors($projectFileVersionID: Int!) {
  projectFileVersionSensorsWithLastGraphsType(projectFileVersionID: $projectFileVersionID) {
    projectFileVersionSensors {
      projectFileVersionSensorID
      displayGraph
      displayName
      sensorModelStocks {
        sensorModelStockID
        name
        mqqtCode
        inventaryCode
        sensorModel {
          name
          description
          thumbnailImage
          isLocalizationSensor
          sensorAssembly {
            sensorAssemblyGeometries {
              sensorAssemblyGeometryID
              projectFileVersions {
                projectFileVersionExternals {
                  externalID
                  externalSystem {
                    code
                  }
                }
              }
              sensorAssemblyGeometryExternals {
                externalSystem {
                  code
                }
                transformationMatrix {
                  matrix
                }
              }
            }
            sensorAssemblyItems {
              sensorModelStock {
                sensorModelStockID
                mqqtCode
                inventaryCode
                sensorModel {
                  isLocalizationSensor
                }
              }
              sensorAssemblyItemExternals {
                externalSystem {
                  code
                }
                transformationMatrix {
                  matrix
                }
              }
            }
          }
          sensorType {
            sensorTypeID
            name
            description
            displayFormat
            measureUnits
            isAssembly
          }
        }
      }
      projectFileVersion {
        projectFileVersionID
      }
      sensorGraphColors {
        sensorGraphColorID
        name
        color
        isDefault
      }
      sensorLocationExternals(where: {path: "externalSystem.code", value: "forge"}) {
        externalSystem {
          code
        }
        vector {
          x
          y
          z
        }
      }
    }
    lastSensorGraphs {
      mqqtCode
      graphDate
      graphTime
      sourceValue
      doubleValue1
      doubleValue2
      doubleValue3
    }
  }
}
    `;
export type GetProjectFileVersionSensorsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProjectFileVersionSensorsQuery, GetProjectFileVersionSensorsQueryVariables>, 'query'> & ({ variables: GetProjectFileVersionSensorsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetProjectFileVersionSensorsComponent = (props: GetProjectFileVersionSensorsComponentProps) => (
      <ApolloReactComponents.Query<GetProjectFileVersionSensorsQuery, GetProjectFileVersionSensorsQueryVariables> query={GetProjectFileVersionSensorsDocument} {...props} />
    );
    

/**
 * __useGetProjectFileVersionSensorsQuery__
 *
 * To run a query within a React component, call `useGetProjectFileVersionSensorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectFileVersionSensorsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectFileVersionSensorsQuery({
 *   variables: {
 *      projectFileVersionID: // value for 'projectFileVersionID'
 *   },
 * });
 */
export function useGetProjectFileVersionSensorsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectFileVersionSensorsQuery, GetProjectFileVersionSensorsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectFileVersionSensorsQuery, GetProjectFileVersionSensorsQueryVariables>(GetProjectFileVersionSensorsDocument, baseOptions);
      }
export function useGetProjectFileVersionSensorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectFileVersionSensorsQuery, GetProjectFileVersionSensorsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectFileVersionSensorsQuery, GetProjectFileVersionSensorsQueryVariables>(GetProjectFileVersionSensorsDocument, baseOptions);
        }
export type GetProjectFileVersionSensorsQueryHookResult = ReturnType<typeof useGetProjectFileVersionSensorsQuery>;
export type GetProjectFileVersionSensorsLazyQueryHookResult = ReturnType<typeof useGetProjectFileVersionSensorsLazyQuery>;
export type GetProjectFileVersionSensorsQueryResult = ApolloReactCommon.QueryResult<GetProjectFileVersionSensorsQuery, GetProjectFileVersionSensorsQueryVariables>;
export const GetSensorGraphDefaultColorDocument = gql`
    query getSensorGraphDefaultColor {
  sensorGraphColors(where: {path: "isDefault", comparison: equal, value: ["true"]}) {
    sensorGraphColorID
    name
    color
    isDefault
  }
}
    `;
export type GetSensorGraphDefaultColorComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetSensorGraphDefaultColorQuery, GetSensorGraphDefaultColorQueryVariables>, 'query'>;

    export const GetSensorGraphDefaultColorComponent = (props: GetSensorGraphDefaultColorComponentProps) => (
      <ApolloReactComponents.Query<GetSensorGraphDefaultColorQuery, GetSensorGraphDefaultColorQueryVariables> query={GetSensorGraphDefaultColorDocument} {...props} />
    );
    

/**
 * __useGetSensorGraphDefaultColorQuery__
 *
 * To run a query within a React component, call `useGetSensorGraphDefaultColorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSensorGraphDefaultColorQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSensorGraphDefaultColorQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSensorGraphDefaultColorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSensorGraphDefaultColorQuery, GetSensorGraphDefaultColorQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSensorGraphDefaultColorQuery, GetSensorGraphDefaultColorQueryVariables>(GetSensorGraphDefaultColorDocument, baseOptions);
      }
export function useGetSensorGraphDefaultColorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSensorGraphDefaultColorQuery, GetSensorGraphDefaultColorQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSensorGraphDefaultColorQuery, GetSensorGraphDefaultColorQueryVariables>(GetSensorGraphDefaultColorDocument, baseOptions);
        }
export type GetSensorGraphDefaultColorQueryHookResult = ReturnType<typeof useGetSensorGraphDefaultColorQuery>;
export type GetSensorGraphDefaultColorLazyQueryHookResult = ReturnType<typeof useGetSensorGraphDefaultColorLazyQuery>;
export type GetSensorGraphDefaultColorQueryResult = ApolloReactCommon.QueryResult<GetSensorGraphDefaultColorQuery, GetSensorGraphDefaultColorQueryVariables>;
export const GetSensorGraphColorByIdDocument = gql`
    query getSensorGraphColorById($colorId: String) {
  sensorGraphColors(where: {path: "sensorGraphColorId", comparison: equal, value: [$colorId]}) {
    sensorGraphColorID
    name
    color
    isDefault
  }
}
    `;
export type GetSensorGraphColorByIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetSensorGraphColorByIdQuery, GetSensorGraphColorByIdQueryVariables>, 'query'>;

    export const GetSensorGraphColorByIdComponent = (props: GetSensorGraphColorByIdComponentProps) => (
      <ApolloReactComponents.Query<GetSensorGraphColorByIdQuery, GetSensorGraphColorByIdQueryVariables> query={GetSensorGraphColorByIdDocument} {...props} />
    );
    

/**
 * __useGetSensorGraphColorByIdQuery__
 *
 * To run a query within a React component, call `useGetSensorGraphColorByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSensorGraphColorByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSensorGraphColorByIdQuery({
 *   variables: {
 *      colorId: // value for 'colorId'
 *   },
 * });
 */
export function useGetSensorGraphColorByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSensorGraphColorByIdQuery, GetSensorGraphColorByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSensorGraphColorByIdQuery, GetSensorGraphColorByIdQueryVariables>(GetSensorGraphColorByIdDocument, baseOptions);
      }
export function useGetSensorGraphColorByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSensorGraphColorByIdQuery, GetSensorGraphColorByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSensorGraphColorByIdQuery, GetSensorGraphColorByIdQueryVariables>(GetSensorGraphColorByIdDocument, baseOptions);
        }
export type GetSensorGraphColorByIdQueryHookResult = ReturnType<typeof useGetSensorGraphColorByIdQuery>;
export type GetSensorGraphColorByIdLazyQueryHookResult = ReturnType<typeof useGetSensorGraphColorByIdLazyQuery>;
export type GetSensorGraphColorByIdQueryResult = ApolloReactCommon.QueryResult<GetSensorGraphColorByIdQuery, GetSensorGraphColorByIdQueryVariables>;
export const GetSensorGraphColorsDocument = gql`
    query getSensorGraphColors {
  sensorGraphColors {
    sensorGraphColorID
    name
    color
    isDefault
  }
}
    `;
export type GetSensorGraphColorsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetSensorGraphColorsQuery, GetSensorGraphColorsQueryVariables>, 'query'>;

    export const GetSensorGraphColorsComponent = (props: GetSensorGraphColorsComponentProps) => (
      <ApolloReactComponents.Query<GetSensorGraphColorsQuery, GetSensorGraphColorsQueryVariables> query={GetSensorGraphColorsDocument} {...props} />
    );
    

/**
 * __useGetSensorGraphColorsQuery__
 *
 * To run a query within a React component, call `useGetSensorGraphColorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSensorGraphColorsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSensorGraphColorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSensorGraphColorsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSensorGraphColorsQuery, GetSensorGraphColorsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSensorGraphColorsQuery, GetSensorGraphColorsQueryVariables>(GetSensorGraphColorsDocument, baseOptions);
      }
export function useGetSensorGraphColorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSensorGraphColorsQuery, GetSensorGraphColorsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSensorGraphColorsQuery, GetSensorGraphColorsQueryVariables>(GetSensorGraphColorsDocument, baseOptions);
        }
export type GetSensorGraphColorsQueryHookResult = ReturnType<typeof useGetSensorGraphColorsQuery>;
export type GetSensorGraphColorsLazyQueryHookResult = ReturnType<typeof useGetSensorGraphColorsLazyQuery>;
export type GetSensorGraphColorsQueryResult = ApolloReactCommon.QueryResult<GetSensorGraphColorsQuery, GetSensorGraphColorsQueryVariables>;
export const GetSensorGraphsDocument = gql`
    query getSensorGraphs($mqqtCode: String, $take: Int, $descending: Boolean) {
  sensorGraphs(take: $take, where: [{path: "isValid", comparison: equal, value: "true"}, {path: "mqqtCode", comparison: equal, value: [$mqqtCode]}], orderBy: [{path: "graphDate", descending: $descending}, {path: "graphTime", descending: $descending}]) {
    sensorGraphID
    graphDate
    graphTime
    mqqtCode
    sourceValue
    doubleValue1
    doubleValue2
    doubleValue3
  }
}
    `;
export type GetSensorGraphsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetSensorGraphsQuery, GetSensorGraphsQueryVariables>, 'query'>;

    export const GetSensorGraphsComponent = (props: GetSensorGraphsComponentProps) => (
      <ApolloReactComponents.Query<GetSensorGraphsQuery, GetSensorGraphsQueryVariables> query={GetSensorGraphsDocument} {...props} />
    );
    

/**
 * __useGetSensorGraphsQuery__
 *
 * To run a query within a React component, call `useGetSensorGraphsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSensorGraphsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSensorGraphsQuery({
 *   variables: {
 *      mqqtCode: // value for 'mqqtCode'
 *      take: // value for 'take'
 *      descending: // value for 'descending'
 *   },
 * });
 */
export function useGetSensorGraphsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSensorGraphsQuery, GetSensorGraphsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSensorGraphsQuery, GetSensorGraphsQueryVariables>(GetSensorGraphsDocument, baseOptions);
      }
export function useGetSensorGraphsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSensorGraphsQuery, GetSensorGraphsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSensorGraphsQuery, GetSensorGraphsQueryVariables>(GetSensorGraphsDocument, baseOptions);
        }
export type GetSensorGraphsQueryHookResult = ReturnType<typeof useGetSensorGraphsQuery>;
export type GetSensorGraphsLazyQueryHookResult = ReturnType<typeof useGetSensorGraphsLazyQuery>;
export type GetSensorGraphsQueryResult = ApolloReactCommon.QueryResult<GetSensorGraphsQuery, GetSensorGraphsQueryVariables>;
export const GetLastGraphsDocument = gql`
    query getLastGraphs($mqqtCode: [String]) {
  sensorGraphs(take: 10, where: [{path: "isValid", comparison: equal, value: "true"}, {path: "mqqtCode", comparison: in, value: $mqqtCode}], orderBy: {path: "sensorGraphID", descending: true}) {
    sensorGraphID
    graphDate
    graphTime
    mqqtCode
    sourceValue
    doubleValue1
    doubleValue2
    doubleValue3
  }
}
    `;
export type GetLastGraphsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLastGraphsQuery, GetLastGraphsQueryVariables>, 'query'>;

    export const GetLastGraphsComponent = (props: GetLastGraphsComponentProps) => (
      <ApolloReactComponents.Query<GetLastGraphsQuery, GetLastGraphsQueryVariables> query={GetLastGraphsDocument} {...props} />
    );
    

/**
 * __useGetLastGraphsQuery__
 *
 * To run a query within a React component, call `useGetLastGraphsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastGraphsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastGraphsQuery({
 *   variables: {
 *      mqqtCode: // value for 'mqqtCode'
 *   },
 * });
 */
export function useGetLastGraphsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLastGraphsQuery, GetLastGraphsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLastGraphsQuery, GetLastGraphsQueryVariables>(GetLastGraphsDocument, baseOptions);
      }
export function useGetLastGraphsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLastGraphsQuery, GetLastGraphsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLastGraphsQuery, GetLastGraphsQueryVariables>(GetLastGraphsDocument, baseOptions);
        }
export type GetLastGraphsQueryHookResult = ReturnType<typeof useGetLastGraphsQuery>;
export type GetLastGraphsLazyQueryHookResult = ReturnType<typeof useGetLastGraphsLazyQuery>;
export type GetLastGraphsQueryResult = ApolloReactCommon.QueryResult<GetLastGraphsQuery, GetLastGraphsQueryVariables>;
export const GetAssemblyDetailsDocument = gql`
    query getAssemblyDetails($assemblyID: String!) {
  sensorAssemblies(where: {path: "sensorAssemblyID", value: [$assemblyID], comparison: equal}) {
    sensorAssemblyID
    name
    description
    manufacturer
    sensorAssemblyItems {
      sensorAssemblyItemID
      sensorModelStock {
        sensorModelStockID
        mqqtCode
        sensorModel {
          sensorModelID
          name
          description
          manufacturer
          isLocalizationSensor
          sensorType {
            name
          }
        }
      }
      sensorAssemblyItemExternals {
        sensorAssemblyItemExternalID
        externalSystem {
          code
        }
        transformationMatrix {
          transformationMatrixID
          matrix
        }
      }
    }
    sensorAssemblyGeometries {
      sensorAssemblyGeometryID
      projectFileVersions {
        projectFileVersionID
        name
        description
        version
        projectFile {
          projectFileID
          name
          fileName
          description
          priority
        }
        projectFileVersionExternals {
          externalSystem {
            code
          }
          externalID
        }
      }
      sensorAssemblyGeometryExternals {
        sensorAssemblyGeometryExternalID
        externalSystem {
          code
        }
        transformationMatrix {
          transformationMatrixID
          matrix
        }
      }
    }
  }
}
    `;
export type GetAssemblyDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAssemblyDetailsQuery, GetAssemblyDetailsQueryVariables>, 'query'> & ({ variables: GetAssemblyDetailsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAssemblyDetailsComponent = (props: GetAssemblyDetailsComponentProps) => (
      <ApolloReactComponents.Query<GetAssemblyDetailsQuery, GetAssemblyDetailsQueryVariables> query={GetAssemblyDetailsDocument} {...props} />
    );
    

/**
 * __useGetAssemblyDetailsQuery__
 *
 * To run a query within a React component, call `useGetAssemblyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssemblyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssemblyDetailsQuery({
 *   variables: {
 *      assemblyID: // value for 'assemblyID'
 *   },
 * });
 */
export function useGetAssemblyDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssemblyDetailsQuery, GetAssemblyDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAssemblyDetailsQuery, GetAssemblyDetailsQueryVariables>(GetAssemblyDetailsDocument, baseOptions);
      }
export function useGetAssemblyDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssemblyDetailsQuery, GetAssemblyDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAssemblyDetailsQuery, GetAssemblyDetailsQueryVariables>(GetAssemblyDetailsDocument, baseOptions);
        }
export type GetAssemblyDetailsQueryHookResult = ReturnType<typeof useGetAssemblyDetailsQuery>;
export type GetAssemblyDetailsLazyQueryHookResult = ReturnType<typeof useGetAssemblyDetailsLazyQuery>;
export type GetAssemblyDetailsQueryResult = ApolloReactCommon.QueryResult<GetAssemblyDetailsQuery, GetAssemblyDetailsQueryVariables>;