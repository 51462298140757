import { Box, createStyles, Paper, Theme, useTheme } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import { ResponsiveLine } from "@nivo/line";
import * as time from "d3-time";
import React, { useEffect } from "react";
import {
    ProjectFileVersionSensor,
    SensorGraphColor,
    SensorModel,
    useGetSensorGraphsLazyQuery,
} from "../../graphql/generated/graphql";
import { SensorType } from "../Sensor/SensorWrapper";
import { getFormattedSensorValue } from "../Sensor/utils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "absolute",
            marginTop: "-168px",
            marginLeft: "-100px",
        },
        paper: {
            height: "140px",
            width: "200px",
            borderRadius: theme.shape.borderRadius * 4,
            overflow: "hidden",
        },
        triangle: {
            marginTop: "-1px",
            marginLeft: `calc(100px - 12px)`,
            width: 0,
            height: 0,
            borderLeft: "12px solid transparent",
            borderRight: "12px solid transparent",
            borderTop: `12px solid ${theme.palette.common.white}`,
        },
        unit: {
            fontWeight: "bold",
            fontSize: "1rem",
            marginLeft: "0.6rem",
        },
        description: {
            fontSize: "0.7rem",
            marginLeft: "0.6rem",
        },
    })
);

type GraphItem = {
    x: Date;
    y: number;
};

type Graph = {
    graph: GraphItem[] | [];
};

const defaultGraphItems: number = 20;
export const defaultGraphRefreshTime: number = 30000; // ms

interface IPushpinGraphProps {
    sensor: ProjectFileVersionSensor;
    pushpinColor: SensorGraphColor;
}

const PushpinGraph: React.FC<IPushpinGraphProps> = (props: IPushpinGraphProps) => {
    const { sensor, pushpinColor } = props;
    const classes = useStyles();
    const theme = useTheme();
    let timer;

    const [
        getSensorGraphsQuery,
        { data: sensorGraphsData, loading: sensorGraphsLoading, error: sensorGraphsError },
    ] = useGetSensorGraphsLazyQuery();

    const [state, setState] = React.useState<Graph>({
        graph: [],
    });
    const [lastValue, setLastValue] = React.useState<any>();

    useEffect(() => {
        if (sensor?.sensorModelStocks?.sensorModel?.sensorType?.sensorTypeID !== parseInt(SensorType.LOCALIZATION)) {
            getSensorGraphsQuery({
                variables: {
                    mqqtCode: sensor?.sensorModelStocks?.mqqtCode as string,
                    take: defaultGraphItems,
                    descending: true,
                },
            });

            let graphState = {
                graph: sensorGraphsData?.sensorGraphs?.reverse().map(sensorGraph => {
                    const datetime = new Date(sensorGraph?.graphDate);
                    datetime?.setSeconds(sensorGraph?.graphTime);
                    return {
                        x: datetime,
                        y: sensorGraph?.doubleValue1 as number,
                    };
                }),
            };

            setState({ graph: graphState?.graph as any });
            setLastValue(sensorGraphsData?.sensorGraphs?.[0]);
        }
    }, [sensor, sensorGraphsData, sensorGraphsLoading]);

    useEffect(() => {
        timer = setInterval(next, defaultGraphRefreshTime);
        return () => {
            clearInterval(timer);
        };
    });

    const next = () => {
        if (sensor?.sensorModelStocks?.sensorModel?.sensorType?.sensorTypeID !== parseInt(SensorType.LOCALIZATION)) {
            getSensorGraphsQuery({
                variables: {
                    mqqtCode: sensor?.sensorModelStocks?.mqqtCode as string,
                    take: defaultGraphItems,
                    descending: true,
                },
            });

            let graphState = {
                graph: sensorGraphsData?.sensorGraphs?.reverse().map(sensorGraph => {
                    const datetime = new Date(sensorGraph?.graphDate);
                    datetime?.setSeconds(sensorGraph?.graphTime);
                    return {
                        x: datetime,
                        y: sensorGraph?.doubleValue1 as number,
                    };
                }),
            };
            setState({ graph: graphState?.graph as any });
            setLastValue(graphState?.graph?.[defaultGraphItems - 1]?.y);
        }
    };

    return (
        <Box className={classes.root}>
            <Paper className={classes.paper}>
                <Box>
                    {lastValue !== undefined ? (
                        <p className={classes.unit}>
                            {getFormattedSensorValue(lastValue, sensor?.sensorModelStocks?.sensorModel as SensorModel)}
                        </p>
                    ) : (
                        <Skeleton
                            width="60px"
                            height="17px"
                            variant="rect"
                            style={{
                                marginLeft: "8px",
                                marginTop: "8px",
                                marginBottom: "-4px",
                                borderRadius: "5px",
                            }}
                        />
                    )}
                    <p className={classes.description}>
                        {sensor?.sensorModelStocks?.sensorModel?.sensorType?.description}
                    </p>
                </Box>

                {state && state?.graph && state?.graph?.length !== 0 ? (
                    <ResponsiveLine
                        colors={[pushpinColor?.color as string]}
                        lineWidth={4}
                        margin={{ top: 5, right: 0, bottom: 70, left: 38 }}
                        data={[{ id: "Graph", data: state?.graph }]}
                        xScale={{ type: "time", format: "native" }}
                        yScale={{ type: "linear", min: "auto", max: "auto" }}
                        axisBottom={{
                            format: "%H:%M",
                            tickSize: 4,
                            tickPadding: 4,
                            tickValues: "every 1 hour",
                        }}
                        axisLeft={{
                            format: ".1f",
                            tickSize: 4,
                            tickPadding: 4,
                        }}
                        enableArea={true}
                        enablePoints={false}
                        enableGridX={true}
                        enableGridY={true}
                        curve="monotoneX"
                        animate={false}
                        motionStiffness={120}
                        motionDamping={50}
                        isInteractive={false}
                        enableSlices={false}
                        useMesh={true}
                        theme={{
                            axis: { ticks: { text: { fontSize: 10 } }, legend: { text: { fontSize: 10 } } },
                            grid: { line: { stroke: theme.palette.grey["300"], strokeDasharray: "1, 1" } },
                        }}
                    />
                ) : (
                    <Skeleton width="100%" height="100%" variant="rect" />
                )}
            </Paper>
            <Box className={classes.triangle} />
        </Box>
    );
};

export default PushpinGraph;
